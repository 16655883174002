<!-- <div #container class="popup interaction-info-container" ngDraggable>
    <i  #closer class="close-icon interaction-info-close"></i>
    <div class="interaction-info-body-container">
      <select (change)="onChange($event.target.value)">
        <option [value]="NoLable">Select The Lable</option>
        <option *ngFor="let column of columnsList; let i = index" [value]="column">{{column}}</option>
      </select>
    </div>
  </div> -->
  <div #container class="popup interaction-info-container" ngDraggable>
    <i  #closer class="close-icon interaction-info-close"></i>
    <h3 class="interaction-info-title">Select The Lable</h3>
    <div class="interaction-info-body-container">
      <select (change)="onChange($event.target.value)">
        <option value="NoLable">No Lable</option>
        <option *ngFor="let column of columnsList; let i = index" [value]="column">{{column}}</option>
      </select>
      <button name="heatmap" (click)="heatmapclicked()">HeatMap</button>
      <!-- <button name="heatmap" (click)="generatefrf()">Genarate FRF</button> -->
      <form>
        <label for="radius">radius size</label>
        <input (change)="onChangeRaidus($event.target.value)" id="radius" type="range" min="1" max="50" step="1" value="5"/>
        <label for="blur">blur size</label>
        <input (change)="onChangeBlur($event.target.value)" id="blur" type="range" min="1" max="50" step="1" value="15"/>
      </form>
    </div>
  </div>