import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClientService } from "./http-client.service";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import {formatDate } from '@angular/common';

@Injectable()
export class FeAdminDashboardService {
serverUrlV2 = environment.serverUrlV2;
  constructor(private http: HttpClientService) { }

  // getting features list along with modules
  getFeaturesList(): any {
    const url = this.serverUrlV2 + '/api/v2/features';
    return this.http.get(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // get features list for package
  getPackageFeaturesList(packageId): any {
    const url = this.serverUrlV2 + '/api/v2/subscription/'+ packageId +'/features';
    return this.http.get(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // save feature // featureName // moduleName
  saveFeature(featureName, moduleName): any {
    const url = this.serverUrlV2 + '/api/v2/features?featureName='+ featureName + '&moduleName='+ moduleName;
    return this.http.post(url, '')
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // get package list
  getPackageList(): any {
    const url = this.serverUrlV2 + '/api/v2/subscriptions';
    return this.http.get(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // get package list with features of that package
  getAllPackagesWithFeaturesList(): any {
    const url = this.serverUrlV2 + '/api/guest/v2/subscriptions/features/relations';
    return this.http.get(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // save package with selected module/feature
  savePackageList(packageInfo): any {
    const url = this.serverUrlV2 + '/api/v2/subscriptions';
    return this.http.post(url, packageInfo)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // get organization Package List
  getOrgPackagesDetails(isIndividualUser): any {
    const url = this.serverUrlV2 + '/api/v2/fe/organization/subscriptions?isIndividualUser=' + isIndividualUser;
    return this.http.get(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
  // update subscription information for Organization
  updateOrgSubscriptionDetails(requestInfo): any {
    const url = `${this.serverUrlV2}/api/v2/organization/${requestInfo.orgId}/subscription/${requestInfo.subscriptionId}/update`;
    return this.http.post(url, requestInfo)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }

  //sending gmail emails signup 
  sendEmailForGmailSignupUsers(requestInfo): any {
    const url = `${this.serverUrlV2}/api/v2/sendemail/gmail/signup`;
    return this.http.post(url, requestInfo)
      .pipe(map((response: any) => {
        return response;
      }), catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
      );
  }
}