<!-- <div class="topnav" id="imaginary_container"> -->
<div class="search-container">
  <!-- <div class="input-btn-container" ngbDropdown #dropDown="ngbDropdown"> -->
  <div class="input-btn-container">

    <!-- INPUT SEARCH BAR -->
    <input [disabled]="!showgeobarsearch" [ngStyle]="{ 'cursor' : showgeobarsearch ? '' : 'not-allowed' }"
      ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)" autocomplete="off" id="geo-address-bar" #inputValue
      [(ngModel)]="searchValue" type="text" placeholder="Search locations, addresses or add data..."
      matTooltip="Geobar™! Add or import datasets" matTooltipClass="mat-custom-tooltip"
      [ngStyle]="{ 'width': activeSearchOptionASB ? 'calc( 100% - 60px )' : 'calc( 100% - 30px )'  }"
      (keyup)="textEntered($event)" (keyup.enter)="onSearch($event, inputValue.value)" name="search" ngbDropdownAnchor
      (click)="inputValue.select(); isGuest ? textEntered($event) : ''" />



    <!-- EXCEL TABLE -->
    <div class="ExcelData-modal-container" [hidden]="!showExcelData">
      <div class="ExcelData-modal-area" >
          <div class=" ExcelData-page-container ">
              <div class="ExcelData-header-container">

                  <button class="close-btn" (click)="showExcelData=false;">
                      &#10006;
                  </button>
              </div>



<div class="d-flex justify-content-center align-items-center">

<div class="col-2 pdf-pages d-flex justify-content-center align-items-center" >
  <div>

    <div *ngFor="let data of ExcelSheets; let i = index" >
      <div class="excel-sheets">
  <img (click)="showSheet($event)" id={{i}} class="excel-img" src="../../assets/svgs/geobar/excel.svg">

      <img  (click)="removeSheet($event)" id={{i}} class="cancel-btn" src="../../assets/svgs/geobar/cancel.svg">
      </div>
      <div>
        <p  class="page-num">Sheet {{i+1}}</p>
</div>
  </div>
  </div>
</div>
<div>

 <div  class="col-10 table" >
 <table class="table  p-5">
   <thead>
     <tr >
     <th *ngFor="let data of ExcelHeading2">
       {{data}}
     </th>

     </tr>
   </thead>
   <tbody>
     <tr *ngFor="let data of ExcelFinal2" >

     <td>{{data[0]}}</td>
     <td>{{data[1]}}</td>
     <td>{{data[2]}}</td>
     <td>{{data[3]}}</td>
     <td>{{data[4]}}</td>
     <td>{{data[5]}}</td>
     <td>{{data[6]}}</td>
     <td>{{data[7]}}</td>
     <td>{{data[8]}}</td>
     <td>{{data[9]}}</td>
     <td>{{data[10]}}</td>
     <td>{{data[11]}}</td>
     <td>{{data[12]}}</td>
     <td>{{data[13]}}</td>
     <td>{{data[14]}}</td>
     <td>{{data[15]}}</td>
     <td>{{data[16]}}</td>
     <td>{{data[17]}}</td>
     <td>{{data[18]}}</td>
     <td>{{data[19]}}</td>
     <td>{{data[20]}}</td>
     <td>{{data[21]}}</td>
     <td>{{data[22]}}</td>
     <td>{{data[23]}}</td>
     <td>{{data[24]}}</td>
     <td>{{data[25]}}</td>
     <td>{{data[26]}}</td>
     <td>{{data[27]}}</td>
     <td>{{data[28]}}</td>
     <td>{{data[29]}}</td>
     <td>{{data[30]}}</td>
     <td>{{data[31]}}</td>
     <td>{{data[32]}}</td>
     <td>{{data[33]}}</td>
     <td>{{data[34]}}</td>
     <td>{{data[35]}}</td>
     <td>{{data[36]}}</td>
     <td>{{data[37]}}</td>
     <td>{{data[38]}}</td>
     </tr>

   </tbody>
 </table>


</div>
<div class="ExcelData-footer-container d-flex justify-content-between" >
 <div>
   <!-- id="mySelect" onchange="SelectLatLong($event)" -->
       <form class="lat-select d-flex justify-content-center align-items-center">
           <label>Latitude:</label>

           <select [(ngModel)]="selectedLatitude" [ngModelOptions]="{standalone: true}" class="form-control select2" #uploadexcel>
            <option selected disabled value=-1>Select Latitude</option>
              <option *ngFor="let data of ExcelHeading2; let i = index" value={{i}} >{{data}}</option>
           </select>
       </form>

 </div>
 <div>

   <form class="long-select d-flex justify-content-center align-items-center">
       <label>Longtitute</label>
       <select  [(ngModel)]="selectedLongtitude" [ngModelOptions]="{standalone: true}" class="form-control select2" #uploadexcel1>
          <option selected disabled value=-1>Select Longtitude</option>
         <option *ngFor="let data of ExcelHeading2; let i = index" value={{i}}>{{data}}</option>
      </select>
   </form>

</div>
 <div>
 <button (click)="UploadExcel()" class="download-btn">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="black" class="bi bi-download" viewBox="0 0 16 16">
     <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
     <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
   </svg>
</button>
</div>
</div>
</div>
</div>



      </div>
    </div>
    </div>

    <!--PDFMANAGER-->
    <div class="PDF-tool-modal-container" [hidden]="!showPDF">
      <div class="PDF-tool-modal-area">
        <div class="PDF-tool-page-container">
          <div class="ExcelData-header-container">

            <button class="close-btn" (click)="showPDF=false;">
              &#10006;
            </button>
          </div>

          <div class="PDF-tool-body-container">

            <div class="col-2 pdf-pages justify-content-center align-items-center">
              <div *ngFor="let data of PdfFinal3; let i = index">
                <div class="pdf-left">
                  <img (click)="showPage($event)" id={{i}} class="pdf-img" [src]="data.data">
                  <img (click)="RemovePage($event)" id={{i}} class="cancel-btn"
                    src="../../assets/svgs/geobar/cancel.svg">
                </div>
                <div>
                  <p class="page-num">Page{{i+1}}</p>
                </div>
              </div>
            </div>
            <div class="col-10 " style="background: #f9f9f9;">
              <div class="content" role="main"></div>

              <!-- <img class="pdf-img-preview" *ngFor="let data of PdfFinal2" [src]="data"> -->
              <img class="pdf-img-preview" [src]="page">

              <div class="PDF-tool-footer-container">
                <button class="download-btn" (click)="uploadpdf($event)">
                  <svg     width="20px" 
                  height="26px"  xmlns="http://www.w3.org/2000/svg" fill="black" class="bi bi-download" viewBox="0 0 16 16">
                    <path
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>


    <!-- HIDEEN FILE SELECTOR-->
    <input style="display:none" type="file" id="selectedFile" (change)="onShapefileUpload($event)" multiple />

    <!-- BUTTON COMES UP WHEN USER USES WML URL -->
    <button type="submit" *ngIf="activeSearchOptionASB" [ngStyle]="{ 'max-width': '30px'}">
      <img *ngIf="activeSearchOptionASB" src='/assets/search-colored-svg/map-service.svg'
        (click)="clickASBWMS($event, inputValue.value)" class="searchASB" matTooltipPosition="below"
        matTooltip="Add Services Button" matTooltipClass="mat-custom-tooltip">
    </button>

    <!-- OPERATION BUTTONS -->
    <div class="go-and-dropdown-trigger-container">
      <div class="go-and-dropdown-trigger-div">
        <div [ngClass]="{'go-container': true, 'only-go': globalObject.pageType === 'COVID19'}" matTooltip='Go!'
          matTooltipPosition="below" matTooltipClass="mat-custom-tooltip" (click)="onSearch($event,inputValue.value);">
          <img *ngIf="geobarValidationStatus ===validStates.UNKNOWN" src="assets/svgs/geobar/go_icon.svg">
          <img *ngIf="geobarValidationStatus ===validStates.VALID" src="assets/svgs/geobar/success_icon.svg">
          <img *ngIf="geobarValidationStatus ===validStates.INVALID" src="assets/svgs/geobar/fail_icon.svg">
        </div>
        <div class="dropdown-container" *ngIf="globalObject.pageType !== 'COVID19'"
          matTooltip='Explore multilple options to add data' matTooltipClass="mat-custom-tooltip"
          (click)="showGeobarOperations()">
          <img src="assets/svgs/geobar/dropdwon_arrow_icon.svg">
        </div>
        <span class="lat-lon-combo-trigger-container"
          (click)="showLatLongCombinations = !showLatLongCombinations; showDropDownWithOptions=false; showGeobaseArea=false;"
          [ngStyle]="{'display': activeSearchOptionLLC ? 'block' : 'none'}">
          <span class="lat-lon-combo-trigger" matTooltip="Latitude Longitude Combinations"
            matTooltipClass="mat-custom-tooltip"></span>
        </span>
      </div>
    <!-- </div> -->

      <!-- Geobar Dropdown -->
      <div class="geobar-options-container">
        <div class="bnb-item">
          Coordinate System :
          <span class="value">{{ currentSystemname }} {{ currentCoordinateSystem }}</span>
          <img (click)="openFrameworkForm()" style="
                transform: rotate(180deg);
                width: 18px;
                height: 10px;
                margin-top: -1px;
              " src="assets/svgs/geosol/geosole-framework/drop-up-icon.svg" />
        </div>
      </div>

      <div class="geobar-sub-options-container" *ngIf="showFrameworkForm">
        <div class="frameworks-container">
          <form [formGroup]="frameworkForm">
            <div [ngClass]="{
                  'framework-option': true,
                  'selected-option':
                    frameworkForm.get('framework').value == 'Rectangular'
                }">
              <input [disabled]="frameworkForm.get('framework').value === 'angular'" type="radio" id="Rectangular"
                formControlName="framework" name="framework" value="Rectangular"
                (change)="radioButtonState = !radioButtonState" />
              <label for="Rectangular">
                <!-- Rectangular -->
                <div class="framework-option-data-container">
                  <div class="framework-option-name">
                    <p class="primary-name">Rectangular</p>
                    <p class="secondary-name">(Easting & Northing)</p>
                  </div>

                  <div class="recimgdropdown" style="
                        border: 1px solid;
                        position: relative;
                        width: 96px;
                        height: 17px;
                        top: 7px;
                      ">
                    <div class="epsgname">
                      {{ rectangularepsg }}
                    </div>

                    <div style="
                          top: 1px;
                          left: 79px;
                          width: 12px;
                          height: 12px;
                          position: absolute;
                        " class="rectangulardropdown" (click)="openrectangulardropdown()" [ngClass]="{
                          rectangulardropdownactive: radioButtonState,
                          rectangulardropdownnotActive: !radioButtonState,
                          'rectangulardropdown-checked':
                            frameworkForm.get('framework').value == 'angular',
                          'rectangulardropdown-unchecked':
                            frameworkForm.get('framework').value != 'angular'
                        }">
                      <div>
                        <select id="rectangular" size="6" class="rectdropdown" *ngIf="showrectangulardropdown" (change)="
                              epsgChangeEvent(
                                $event.target.value,
                                $event.target.id
                              )
                            ">
                          <option value="3857">EPSG:3857</option>
                          <option value="2100">EPSG:2100</option>
                          <!-- <option value="2163">EPSG:2163</option>
                          <option value="27700">EPSG:27700</option> -->
                          <option value="23032">EPSG:23032</option>
                          <option value="21781">EPSG:21781</option>
                          <option value="3413">EPSG:3413</option>
                          <option value="5479">EPSG:5479</option>
                          <option value="54009">ESRI:54009</option>
                          <option value="2225">EPSG:2225 CA SPZ1</option>
                          <option value="2226">EPSG:2226 CA SPZ2</option>
                          <option value="2227">EPSG:2227 CA SPZ3</option>
                          <option value="2228">EPSG:2228 CA SPZ4</option>
                          <option value="2229">EPSG:2229 CA SPZ5</option>
                          <option value="2230">EPSG:2230 CA SPZ6</option>
                          <option value="24378">EPSG:24378 Kalian Z1</option>
                          <option value="24379">EPSG:24379 Kalian Z2a</option>
                          <option value="24380">EPSG:24380 Kalian Z2b</option>
                          <option value="24381">EPSG:24381 Kalian Z3a</option>
                          <option value="24382">EPSG:24382 Kalian Z3b</option>
                          <option value="24383">EPSG:24383 Kalian Z4</option>
                          <option value="32601">EPSG:32601 UTM Z1N</option>
                          <option value="32602">EPSG:32602 UTM Z2N</option>
                          <option value="32603">EPSG:32603 UTM Z3N</option>
                          <option value="32604">EPSG:32604 UTM Z4N</option>
                          <option value="32605">EPSG:32605 UTM Z5N</option>
                          <option value="32606">EPSG:32606 UTM Z6N</option>
                          <option value="32607">EPSG:32607 UTM Z7N</option>
                          <option value="32608">EPSG:32608 UTM Z8N</option>
                          <option value="32609">EPSG:32609 UTM Z9N</option>
                          <option value="32610">EPSG:32610 UTM Z10N</option>
                          <option value="32611">EPSG:32610: UTM Z11N</option>
                          <option value="32612">EPSG:32612 UTM Z12N</option>
                          <option value="32613">EPSG:32613 UTM Z13N</option>
                          <option value="32614">EPSG:32614 UTM Z14N</option>
                          <option value="32615">EPSG:32615 UTM Z15N</option>
                          <option value="32616">EPSG:32616 UTM Z16N</option>
                          <option value="32617">EPSG:32617 UTM Z17N</option>
                          <option value="32618">EPSG:32618 UTM Z18N</option>
                          <option value="32619">EPSG:32619 UTM Z19N</option>
                          <option value="32620">EPSG:32620 UTM Z20N</option>
                          <option value="32621">EPSG:32621 UTM Z21N</option>
                          <option value="32622">EPSG:32622 UTM Z22N</option>
                          <option value="32623">EPSG:32623 UTM Z23N</option>
                          <option value="32624">EPSG:32624 UTM Z24N</option>
                          <option value="32625">EPSG:32625 UTM Z25N</option>
                          <option value="32626">EPSG:32626 UTM Z26N</option>
                          <option value="32627">EPSG:32627 UTM Z27N</option>
                          <option value="32628">EPSG:32628 UTM Z28N</option>
                          <option value="32629">EPSG:32629 UTM Z29N</option>
                          <option value="32630">EPSG:32630 UTM Z30N</option>
                          <option value="32631">EPSG:32631 UTM Z31N</option>
                          <option value="32632">EPSG:32632 UTM Z32N</option>
                          <option value="32633">EPSG:32633 UTM Z33N</option>
                          <option value="32634">EPSG:32634 UTM Z34N</option>
                          <option value="32635">EPSG:32635 UTM Z35N</option>
                          <option value="32636">EPSG:32636 UTM Z36N</option>
                          <option value="32637">EPSG:32637 UTM Z37N</option>
                          <option value="32638">EPSG:32638 UTM Z38N</option>
                          <option value="32639">EPSG:32639 UTM Z39N</option>
                          <option value="32640">EPSG:32640 UTM Z40N</option>
                          <option value="32641">EPSG:32641 UTM Z41N</option>
                          <option value="32642">EPSG:32642 UTM Z42N</option>
                          <option value="32643">EPSG:32643 UTM Z43N</option>
                          <option value="32644">EPSG:32644 UTM Z44N</option>
                          <option value="32645">EPSG:32645 UTM Z45N</option>
                          <option value="32646">EPSG:32646 UTM Z46N</option>
                          <option value="32647">EPSG:32647 UTM Z47N</option>
                          <option value="32648">EPSG:32648 UTM Z48N</option>
                          <option value="32649">EPSG:32649 UTM Z49N</option>
                          <option value="32650">EPSG:32650 UTM Z50N</option>
                          <option value="32651">EPSG:32651 UTM Z51N</option>
                          <option value="32652">EPSG:32652 UTM Z52N</option>
                          <option value="32653">EPSG:32653 UTM Z53N</option>
                          <option value="32654">EPSG:32654 UTM Z54N</option>
                          <option value="32655">EPSG:32655 UTM Z55N</option>
                          <option value="32656">EPSG:32656 UTM Z56N</option>
                          <option value="32657">EPSG:32657 UTM Z57N</option>
                          <option value="32658">EPSG:32658 UTM Z58N</option>
                          <option value="32659">EPSG:32659 UTM Z59N</option>
                          <option value="32660">EPSG:32660 UTM Z60N</option>
                          <option value="32701">EPSG:32701 UTM Z1S</option>
                          <option value="32702">EPSG:32702 UTM Z2S</option>
                          <option value="32703">EPSG:32703 UTM Z3S</option>
                          <option value="32704">EPSG:32704 UTM Z4S</option>
                          <option value="32705">EPSG:32705 UTM Z5S</option>
                          <option value="32706">EPSG:32706 UTM Z6S</option>
                          <option value="32707">EPSG:32707 UTM Z7S</option>
                          <option value="32708">EPSG:32708 UTM Z8S</option>
                          <option value="32709">EPSG:32709 UTM Z9S</option>
                          <option value="32710">EPSG:32709 UTM Z10S</option>
                          <option value="32711">EPSG:32711 UTM Z11S</option>
                          <option value="32712">EPSG:32712 UTM Z12S</option>
                          <option value="32713">EPSG:32713 UTM Z13S</option>
                          <option value="32714">EPSG:32714 UTM Z14S</option>
                          <option value="32715">EPSG:32715 UTM Z15S</option>
                          <option value="32716">EPSG:32716 UTM Z16S</option>
                          <option value="32717">EPSG:32717 UTM Z17S</option>
                          <option value="32718">EPSG:32718 UTM Z18S</option>
                          <option value="32719">EPSG:32719 UTM Z19S</option>
                          <option value="32720">EPSG:32720 UTM Z20S</option>
                          <option value="32721">EPSG:32721 UTM Z21S</option>
                          <option value="32722">EPSG:32722 UTM Z22S</option>
                          <option value="32723">EPSG:32723 UTM Z23S</option>
                          <option value="32724">EPSG:32724 UTM Z24S</option>
                          <option value="32725">EPSG:32725 UTM Z25S</option>
                          <option value="32726">EPSG:32726 UTM Z26S</option>
                          <option value="32727">EPSG:32727 UTM Z27S</option>
                          <option value="32728">EPSG:32728 UTM Z28S</option>
                          <option value="32729">EPSG:32729 UTM Z29S</option>
                          <option value="32730">EPSG:32730 UTM Z30S</option>
                          <option value="32731">EPSG:32731 UTM Z31S</option>
                          <option value="32732">EPSG:32732 UTM Z32S</option>
                          <option value="32732">EPSG:32732 UTM Z33S</option>
                          <option value="32734">EPSG:32734 UTM Z34S</option>
                          <option value="32735">EPSG:32735 UTM Z35S</option>
                          <option value="32736">EPSG:32736 UTM Z36S</option>
                          <option value="32737">EPSG:32737 UTM Z37S</option>
                          <option value="32738">EPSG:32738 UTM Z38S</option>
                          <option value="32739">EPSG:32739 UTM Z39S</option>
                          <option value="32740">EPSG:32740 UTM Z40S</option>
                          <option value="32741">EPSG:32741 UTM Z41S</option>
                          <option value="32742">EPSG:32742 UTM Z42S</option>
                          <option value="32743">EPSG:32743 UTM Z43S</option>
                          <option value="32744">EPSG:32744 UTM Z44S</option>
                          <option value="32745">EPSG:32745 UTM Z45S</option>
                          <option value="32746">EPSG:32746 UTM Z46S</option>
                          <option value="32747">EPSG:32747 UTM Z47S</option>
                          <option value="32748">EPSG:32748 UTM Z48S</option>
                          <option value="32749">EPSG:32749 UTM Z49S</option>
                          <option value="32750">EPSG:32750 UTM Z50S</option>
                          <option value="32751">EPSG:32751 UTM Z51S</option>
                          <option value="32752">EPSG:32752 UTM Z52S</option>
                          <option value="32753">EPSG:32753 UTM Z53S</option>
                          <option value="32754">EPSG:32754 UTM Z54S</option>
                          <option value="32755">EPSG:32755 UTM Z55S</option>
                          <option value="32756">EPSG:32756 UTM Z56S</option>
                          <option value="32757">EPSG:32757 UTM Z57S</option>
                          <option value="32758">EPSG:32758 UTM Z58S</option>
                          <option value="32759">EPSG:32759 UTM Z59S</option>
                          <option value="32760">EPSG:32760 UTM Z60S</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div [ngClass]="{
                  'framework-option': true,
                  'selected-option':
                    frameworkForm.get('framework').value == 'Angular'
                }">
              <input [disabled]="
                    frameworkForm.get('framework').value === 'rectangular'
                  " type="radio" id="Angular" formControlName="framework" name="framework" value="Angular"
                (change)="radioButtonState = !radioButtonState" />
              <label for="Angular">
                <div class="framework-option-data-container">
                  <div class="framework-option-name">
                    <p class="primary-name">Angular</p>
                    <p class="secondary-name">(Longitude & Latitude)</p>
                  </div>
                  <div class="angimgdropdown" style="
                        border: 1px solid;
                        width: 96px;
                        height: 17px;
                        top: 7px;
                        position: relative;
                      ">
                    <div class="epsgname">
                      {{ angularepsg }}
                    </div>
                    <div style="
                          top: 1px;
                          left: 79px;
                          width: 12px;
                          height: 12px;
                          position: absolute;
                        " class="angulardropdown" [disabled]="
                          frameworkForm.get('framework').value != 'Angular'
                        " (click)="openangulardropdown()" [ngClass]="{
                          angulardropdownactive: !radioButtonState,
                          angulardropdownnotActive: radioButtonState,
                          'angulardropdown-checked':
                            frameworkForm.get('framework').value == 'angular',
                          'angulardropdown-unchecked':
                            frameworkForm.get('framework').value != 'angular'
                        }">
                      <div>
                        <select id="angular" size="2" class="angdropdown" *ngIf="showangulardropdown" (change)="
                              epsgChangeEvent(
                                $event.target.value,
                                $event.target.id
                              )
                            ">
                          <option value="4326">EPSG:4326</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- DROP DOWN OPTIONS AREA -->
  <div *ngIf="showDropDownWithOptions" class="drop-down-options-container">
    <div class="option-container" (click)="selectFilePickOptionCloud()"
      [ngStyle]="{ 'cursor' : showgeobarfmcloud ? '' : 'not-allowed' }">
      <div class="option-logo-container" (mouseenter)="cfmImg.src='assets/svgs/geobar/cloud_icon.svg'"
        (mouseleave)="cfmImg.src='assets/svgs/geobar/cloud_icon2.svg'" matTooltipPosition="left"
        matTooltip="File Manager (Cloud)" matTooltipClass="mat-custom-tooltip">
        <div class="option-logo-div">
          <img #cfmImg src="assets/svgs/geobar/cloud_icon2.svg">
        </div>
      </div>
      <!-- <p class="option-name">File Manager (Cloud)</p> -->
    </div>

    <!-- Local File Manager -->
    <div class="option-container" (click)="showDropDownWithOptions=false; selectFilePickOption('local');"
      [ngStyle]="{ 'cursor' : showgeobarfmlocal ? '' : 'not-allowed' }">
      <div class="option-logo-container" (mouseenter)="lfmImg.src='assets/svgs/geobar/local_white_icon.svg'"
        (mouseleave)="lfmImg.src='assets/svgs/geobar/local_icon.svg'" matTooltipPosition="left"
        matTooltip="File Manager (Local)" matTooltipClass="mat-custom-tooltip">
        <div class="option-logo-div">
          <img #lfmImg src="assets/svgs/geobar/local_icon.svg">
        </div>
      </div>
    </div>

    <!-- Geobase -->
    <div class="option-container" (click)='triggerEventToShowGeobase(); showDropDownWithOptions=false;'
      [ngStyle]="{ 'cursor' : showgeobarbase ? '' : 'not-allowed' }">
      <div class="option-logo-container" matTooltipPosition="left" matTooltip="Geobase"
        matTooltipClass="mat-custom-tooltip">
        <div class="option-logo-div">
          <img src="assets/svgs/geobar/geobase.svg">
        </div>
      </div>
      <!-- <p class="option-name">Geobase</p> -->
    </div>
    <!-- UCR STAR -->
    <div class="option-container"
      (click)="showUcrWindow=true; minimizedUcrWindow=false; showDropDownWithOptions=false;">
      <div class="option-logo-container" (mouseenter)="ucrImg.src='assets/svgs/geobar/external_source_white_icon.svg'"
        (mouseleave)="ucrImg.src='assets/svgs/geobar/external_source_icon.svg'" matTooltipPosition="left"
        matTooltip="External Source (UCR STAR)" matTooltipClass="mat-custom-tooltip">
        <div class="option-logo-div">
          <img #ucrImg src="assets/svgs/geobar/external_source_icon.svg">
        </div>
      </div>
      <p class="option-name">External Source (UCR STAR)</p>
    </div>
  </div>

  <!-- LAT LONG COMBINATIONS AREA -->
  <div *ngIf="showLatLongCombinations" class="lat-long-combo-container">
    <!-- <table ngbDropdownMenu class="table table-striped" > -->
    <table class="table table-striped">
      <tr>
        <td>LAT</td>
        <td></td>
        <td></td>
        <td></td>
        <td>LONG</td>
      </tr>
      <tr *ngFor="let latlng of latLngsList;" (click)="dropDownClickEvent($event,latlng)">
        <td>{{latlng.lat}}</td>
        <td>{{latlng.latDegree}}</td>
        <td>
          <!-- : -->
        </td>
        <td>{{latlng.lng}}</td>
        <td>{{latlng.lngDegree}}</td>
      </tr>
    </table>
  </div>

  <!-- GEOBASE CONTAINER-->
  <div *ngIf="showGeobaseArea" class="geobase-container">
    <div class="geo-base-header">
      <div class="geo-base-file-manage-container">
        <select placeholder="File Manager" (change)="getGeobaseListByFilter($event.target.value)">
          <option value="myFiles">My Sessions</option>
          <option value="shareWithMe">Shared Sessions with Me</option>
          <option value="shareByMe">My Shared Sessions</option>
          <option *ngIf="userInfo.type=='INDEPENDENT'" disabled value="public">Public Sessions</option>
        </select>
      </div>
      <div class="geo-base-sort-container">
        <select class="project" placeholder="Project">
          <option>Sort by Oldest</option>
          <option>Sort by Latest</option>
          <option>Sort A-Z</option>
          <option>Sort Z-A</option>
        </select>
      </div>
    </div>
    <div class="geo-base-body">
      <p class="data-fetching-status" *ngIf="!geoSessionDataColleced">Fetching data...</p>
      <div *ngIf="geoSessionDataColleced" style="display: flex; flex-wrap: wrap;">
        <p class="no-session-found" *ngIf="geoSessionsList.length<=0">No sessions found...</p>        
        <div class="geo-session-container" *ngFor="let session of geoSessionsList">          
          <span class='geo-session-label' *ngIf="session.label==='New'">New</span>
          <div class="geo-session-div" (click)="showGeoSession(session)"><span class="name">{{session.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- COVID PAGE SUGGESTION TO ADD SITE -->
  <div class="covid-lookup-msg-and-error-container" *ngIf="globalObject.pageType === 'COVID19'">
    <div class="covid-lookup-msg-container">
      <p class="covid-lookup-msg">My Location</p>
      <button class="my-location" matTooltip="Go to current location" matTooltipClass="mat-custom-tooltip"
        [ngClass]="{'disabled': currentLocationFetchStatus === 'inprogress'}"
        [disabled]="currentLocationFetchStatus === 'inprogress'" (click)="markCurrentLocation()">
        <span *ngIf="currentLocationFetchStatus !== 'inprogress'" class="material-icons"> my_location </span>
        <span *ngIf="currentLocationFetchStatus === 'inprogress'" class="material-icons"> location_searching </span>
      </button>
    </div>
    <div *ngIf="currentLocationFetchStatus === 'inprogress' || currentLocationFetchStatus === 'error'"
      class="covid-lookup-error-container">
      <p class="error" *ngIf="currentLocationFetchStatus === 'error'">Not able find location</p>
      <p class="find" *ngIf="currentLocationFetchStatus === 'inprogress'">Finding your current location...</p>
    </div>
  </div>


</div>
<!-- </div> -->

<!-- WMS IMAGE PREVIEW -->
<div #wmsimgEl *ngIf='activewmsImg' id="div-container-DPP" class="div-container-DPP">
  <i class="close-icon" (click)="clearASB($event, inputValue.value);"></i>
  <img class="wmsimg" [src]="_wmsimg" />
</div>

<!-- ALERT MSGS -->
<app-geobar-alert></app-geobar-alert>
<ng-template #geobarSearchContent>
  <!-- Click to search or add local data -->
  Go!
</ng-template>
<ng-template #geobarLLCContent>Latitude Longitude Combinations</ng-template>
<ng-template #geobarASBContent>Add Services Button</ng-template>


<!-- CLOUD FILE MANAGER -->
<div [ngStyle]="{ 'left': minimizedWindow ? '-300px' : '0px' }" class="cfm-data-container" *ngIf="showCfmWindow"
  #cfmWindow>
  <div class="cfm-data-body">
    <button class="cfm-data-close" matTooltip="Close" matTooltipClass="mat-custom-tooltip" (click)="closeCfmWindow()">
      <i class="material-icons">close</i>
    </button>
    <app-cloud-file-selector [reset]="resetCfmFileSelection" (fileSelected)="selectedAwsFile($event)"
      (closeCfm)="closeFilePickerOptions()">
    </app-cloud-file-selector>
  </div>
</div>
<!-- External Source (UCR STAR) -->
<div [ngStyle]="{ 'left': minimizedUcrWindow ? '-300px' : '0px' }" class="ucr-data-container" *ngIf="showUcrWindow"
  #ucrWindow>
  <div class="ucr-inner-data-container">
    <div class="cfm-data-header">
      <div class="cfm-data-title-container">
        <img class="cfm-data-logo" src="assets/svgs/geobar/external_source_icon.svg">
        <span class="cfm-data-title" style="font-size: 14px;">
          External Source (UCR STAR)
        </span>
        <button class="cfm-data-close" matTooltip="Close" matTooltipClass="mat-custom-tooltip" (click)="closeUcr()">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
    <div class="cfm-data-body">
      <p style="color: #fff;">Coming soon...</p>
    </div>
  </div>
</div>
<span #markerTail class="marker-tail" (click)="showAddSiteScreen()">
  <span class="material-icons arrow">play_arrow </span>
  <span class="text">Enter Details</span>
</span>
<div class="covid-add-site-container" *ngIf="globalObject.pageType === 'COVID19' &&
                                              (showAddSiteWindow || showAddSiteSuccessMsg)">
  <div class="covid-add-site-area">
    <div class="covid-form-data-container" *ngIf="showAddSiteWindow">
      <div class="project-and-topics-container">
        <div class="project-type-container" *ngFor="let proj of projects">
          <label>
            <input type="checkbox" [(ngModel)]="proj.selected" (change)="onProjectChange($event, proj)"> {{proj.name}}
          </label>
        </div>
        <div class="input-select-container">
          <label for="siteTopic" class="select-title">Resources</label>
          <select id="siteTopic" class="select" placeholder="Resources" [formControl]="topicSelect">
            <option *ngFor="let topic of topics" value="{{topic.topicId}}">{{topic.name}}</option>
          </select>
        </div>
      </div>
      <p class="location-data">Entering details at ({{currSiteLocationDataToView}})</p>
      <div class="site-info-container">
        <div class="site-info-title-container">
          Title:
        </div>
        <div class="site-info-value-continer">
          <input type="text" [formControl]="titleCtrl" placeholder="Enter a short title">
        </div>
      </div>
      <div class="site-info-container">
        <div class="site-info-title-container">
          Contact:
        </div>
        <div class="site-info-value-continer">
          <input type="text" [formControl]="contactCtrl" placeholder="Enter name and phone or email">
        </div>
      </div>
      <div class="site-info-container">
        <div class="site-info-title-container">
          Description:
        </div>
        <div class="site-info-value-continer">
          <textarea [formControl]="descCtrl"
            placeholder="Enter a brief description of your need or availability"></textarea>
        </div>
      </div>
      <div class="site-info-container">
        <div class="site-info-title-container">
          Tags:
        </div>
        <div class="site-info-value-continer">
          <!-- <input type="text" [formControl]="tagsCtrl" placeholder="#tags to enable searching"> -->
          <input style="font-size: smaller" type="text" [formControl]="tagsCtrl"
            placeholder="#tags to enable searching (use ; to enter multiple tags)">
        </div>
      </div>
      <div class="err-msg-container" *ngIf="siteErrMsg !== ''">
        <p>{{siteErrMsg}}</p>
      </div>
      <div class="add-site-close-container">
        <p class="status" *ngIf="siteSavingStatus == 'inprogress'"> Saving site...</p>
        <p class="status" style="color: #8a5454;" *ngIf="siteSavingStatus == 'error'"> Site saving failed.</p>
        <div class="submit-btn-container" *ngIf="siteSavingStatus === '' || siteSavingStatus == 'error'">
          <button (click)="createSite()">Submit</button>
        </div>
        <div class="cancel-btn-container" *ngIf="siteSavingStatus === '' || siteSavingStatus == 'error'">
          <button (click)="closeAddSite()">Cancel</button>
        </div>

      </div>
    </div>
    <div class="covid-add-site-success-container" *ngIf="showAddSiteSuccessMsg">
      <div class="add-site-success-msg-container">
        <p class="header">Success!!!</p>
        <p class="msg">Site added successfully...</p>
      </div>
      <div class="add-site-success-close-container">
        <div class="submit-btn-container">
          <button (click)="gotoGeopad()">View Added site</button>
        </div>
        <div class="cancel-btn-container">
          <button (click)="showAddSiteSuccessMsg = false">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>