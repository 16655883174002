export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBbvquZijiYsa7FKg7nVKpInV0Zd3f5wdA',
    authDomain: 'fuse-earth.firebaseapp.com',
    databaseURL: 'https://fuse-earth.firebaseio.com',
    projectId: 'fuse-earth',
    storageBucket: 'fuse-earth.appspot.com',
    messagingSenderId: '135677540992',
    appId: '1:135677540992:web:8ec46f7f51624fc25d4465',
    measurementId: 'G-JJVL8LVM4B'
  },
  disableConsoleLogs: true,
  sendGoogleAnalytics: true,
  feUserGuideTooltipAutoCloseDuration: 10000,
  serverUrl: 'https://fuse.earth:8443/fusedotearth',
  serverUrlV2: 'https://fuse.earth:8443/fusedotearth-v2'
};
