import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input,
    SimpleChange, OnChanges, OnDestroy, Renderer2, PipeTransform, Pipe, HostListener, AfterViewInit } from '@angular/core';
// import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { AuthObservableService } from '../Services/authObservableService';
import { GeoNotePadService } from '../Services/geo-notepad.service';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from '../Services/common.service';
import { FileTypes, FuseEarthSite, SiteParams, SiteType } from './capture-notes/capture-notes.component';
import { GeobaseService } from '../Services/geobase.service';
import { TopicsService } from '../Services/topics.service';
import { FormControl, Validators } from '@angular/forms';
import OlMap from 'ol/Map';
import { BasemapService } from '../basemap/basemap.service';
import { GeoPopupComponent } from '../geopopup/geopopup.component';
import { GeotrayService } from '../geotray/geotray.service';
import Geolocation from 'ol/Geolocation';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { GeotowerService } from '../geotower/geotower.service';
import { LayersService } from '../Services/layers.service';
import * as uuid from 'uuid';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { AnalyticsService } from '../Services/analytics.service';
import { get as getProjection } from 'ol/proj'
import { Fill, Stroke, Style, Text } from "ol/style";
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature';
export enum SessionSharingOption{
  UNKNOWN = '',
  EMAIL = 'share-with-email',
  URL = 'share-url',
  DOWNLOAD = 'download'
}

@Pipe({
  name: 'fileType'
})
export class FileTypesCountFilter  implements PipeTransform {
  transform(filesList: any[], type: string): number {
    const tempFiltered = [];
    if (filesList !== undefined) {
      filesList.forEach(element => {
        if (element.type === type) {
          tempFiltered.push(element);
        }
      });
    }
    return tempFiltered.length;
  }
}

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss']
})
export class CaptureComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  geometryData: any;
  projection: any
  projCode: any
  codeFromGeosol: any
  countForTransform = 0
  checkForValue: any;
  fileTypes = FileTypes;
  savedNotes: any[] = [];
  operation = 'add';
  errorMsg = '';
  selectedNote: any = {};
  @Input() showCapture = false;
  @Input() markLocation = false;
  @Input() data: any = {};
  @Input() sessionId;
  @Input() isGuest = true;
  @Input() currentSession: any = {};
  @Input() showExpandedView = '';
  @Output() closeCapture: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadSession: EventEmitter<any> = new EventEmitter<any>();
  @Input() globalObject;
  @Input() viewMode: any = {};
  @Input() userInfo: any = {};
  @Input() userProfileData: any = {};
  positionMarkObserver: Subject<any> = new Subject<any>();
  goingToMarkPosition = false;
  minimizedWindow = false;
  locationData: any[] = [];
  site_type;  
  showNotesPicker = false;
  showNotesAndFilePicker = false;
  getCoOrdsForNewNote = false;
  showConfirmDelete = false;
  showNotesPickerForPolygon = false;
  showNotesPickerForLine = false;
  showNotesPickerForMultiPoints = false;
  showNotesPickerForMultiLines = false;
  showNotesPickerForMultiPolygons = false;
  currentFeature: any;
  @ViewChild('captureWindow') captureWindow: ElementRef<HTMLDivElement>;
  @ViewChild(GeoPopupComponent) popupComponent: GeoPopupComponent;
  @ViewChild('urlLink') urlLink: ElementRef<HTMLInputElement>;
  showSavingStatus = false;
  showDeletingStatus = false;
  isValidSession = false;
  projects: any[] = [];
  projectSelect: FormControl = new FormControl('');
  places: any[] = [];
  placeSelect: FormControl = new FormControl('');
  topics: any[] = [];
  topicSelect: FormControl = new FormControl('');
  projectId = 0;
  placeId = 0;
  topicId = 0;
  selectedProject: any = {};
  selectedPlace: any = {};
  selectedTopic: any = {};
  private basemap: OlMap;
  captureModes = {
    CAPTURE: 'capture',
    AWARENESS: 'awareness'
  };
  confirmCurrentNotesClosing: any = {};
  tempCreateSiteId: string = String(new Date().getTime());
  mouseDownTimeStamp: number;
  selectedProjectId: number | string;
  selectedPlaceId: number | string;
  selectedTopicId: number | string ;
  sitesDataCollected: boolean;
  currentContextInfo: any = {};
  siteSearchObserver: Subject<any> = new Subject<any>();
  searchName = '';
  searchSiteType: SiteType = SiteType.ALL;
  siteTypeEnum = SiteType;
  @ViewChild('siteSearchInput') siteSearchInput: ElementRef<HTMLInputElement>;
  oldDataRangeSelect: FormControl = new FormControl(10);
  oldDataRanges: any[] = [
    { name: 'Last 10 Days', value: 10 },
    { name: 'Last 20 Days', value: 20 },
    { name: 'Last 30 Days', value: 30 },
    { name: 'Last 60 Days', value: 60 }
  ];
  showSiteMarkerMenu = false;
  showSearchbar = false;
  siteType = this.notePadService.shapeDrawType;
  showSearchOptions = false;
  ownerName: string;
  ownerEmail: string;
  copySelected = false;
  showSessionShareMenu = false;
  towerItemsDataCollected: boolean;
  geoTowerList: any[] = [];
  multipartFiles: any[] = [];
  towerWithLayersList: any[] = [];
  shareSessionToEmail = false;
  copySessionUrl = false;
  userEmail: FormControl = new FormControl('', [Validators.email]);
  sessionNameInput: FormControl = new FormControl('');
  allUserOrOrgEmails: Array<string> = [];
  orgUserEmailsToShow: Array<string> = [];
  link;
  sessionSaveStates = {
    UNKNOWN: 'unknown',
    STARTED: 'started',
    COMPLETED: 'completed',
    FAILED: 'failed'
  };
  sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
  defaultGeobaseInfo: any = {};
  geoSessionsList: any[] = [];
  geoSessionDataColleced: boolean;
  error = '';
  userEmailListString = '';
  selectedUserEmailsToShare: Array<string> = [];
  showSessionOverrideAlert = false;
  showSessionShareAlert = false;
  showSessionSaveAlert = false;
  sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
  sessioSaveProgress: string;
  boundingBoxTypes: any[] = [
    { name: 'Default (Current Extent)', value: 'default' },    
  ];
  boundingBoxItems: any = {
    default: [{name: 'Geopad', value: 'geopad'}, {name: 'Geotower', value: 'geotower'}],
    global: [{name: 'Geopad', value: 'geopad'}, {name: 'Geotower', value: 'geotower'}],
    geotower: [{name: 'Geotower', value: 'geotower'}],
    geopad: [{name: 'Geopad', value: 'geopad'}],
    all: [{name: 'Geopad', value: 'geopad'}, {name: 'Geotower', value: 'geotower'}]
  };
  currentBoundingBoxItems: any[] = this.boundingBoxItems.default;
  copyToClipboard = '';
  urlCopiedToClipboard = false;
  @ViewChild('selectAllSitesCheckbox') selectAllSitesCheckbox: MatCheckbox;
  @ViewChild('selectAllLayersCheckbox') selectAllLayersCheckbox: MatCheckbox;
  selectedSessionCategory = 'mySession';
  selectedSessionId = 'running';
  sessionCategoryCtrl: FormControl = new FormControl('');
  sessionCtrl: FormControl = new FormControl('');
  defaultSession: any = {};
  selectedSiteStateWhileEdit: any = {};
  sessionShareOptionEnum =  SessionSharingOption;
  selectedSessionSharingOption: SessionSharingOption = SessionSharingOption.UNKNOWN;
  tempUserEmailFormControl: FormControl = new FormControl('', [Validators.email]);
  saveSessionOptionSelectedFrom: SessionSharingOption = SessionSharingOption.UNKNOWN;
  projectsDataCollected = false;
  placesDataCollected = false;
  topicsDataCollected = false;
  currentSiteDataToRefresh: any = {};
  sessionCategories = {
    mySession: 'My Sessions',
    shareWithMe: 'Shared Sessions with Me',
    public: 'Public Sessions',
    '': '',
    null: '',
    undefined: ''
  };
  currSessionsObj: any = {};
  showSessionShareSiteEditAlert = false;
  showsharemail: boolean;
  showshareurl: boolean;  
  showsharedownload: boolean;
  packagecontrolgeopad: string[];
  duplicateNotes: any[];
  dataArray = [];
  totalSites: any[]
  lastNote: FuseEarthSite;
  epsgDetection: any;
  epsgGotDetected = false
  changeEpsgCodeDetected: boolean;
  countepsgCode = 0
  checkLayerFoundHit: any;
  checkCountId: any;
  counterUpdate: any;
  clickRecord = 0
  copyNote: FuseEarthSite;
  storeNotesForTrack = []
  checkLocalStorage = 0
  epsgLocal: any;
  utdata: any;  
  storeCoordinates = []
  projectedSiteData =[];
  packageEventsSubscription: Subscription;
  @Input() packageEvent; // Observable<any>;
  packageItems;
  constructor(
    private authObsr: AuthObservableService, private notePadService: GeoNotePadService,
    private nodePadService: GeoNotePadService, private basemapService: BasemapService,
    private commonService: CommonService, private renderer: Renderer2,
    private geotrayService: GeotrayService, private analytics: AnalyticsService,
    private geotowerService: GeotowerService, private layersService: LayersService,
    private geobaseService: GeobaseService, private topicsService: TopicsService) {
    this.positionMarkObserver.subscribe(res => {
      console.log('POSITION MARKED');
      console.log(res);
      let coords;
      const coordsList = [];
      if (res.from === this.notePadService.shapeDrawType.POLYGON /*'polygon'*/){
        res['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(element => {            
            coordsList.push(element[0]);
            coordsList.push(element[1]);
          });
        });
        coords = coordsList;
      } else if ( res.from === this.notePadService.shapeDrawType.LINE_STRING ){
        res['co-ordinates'].forEach(latLngList => {
          coordsList.push(latLngList[0]);
          coordsList.push(latLngList[1]);
        });
        coords = coordsList;
      } else if (res.from === this.notePadService.shapeDrawType.POINT /*'position'*/){
        res['co-ordinates'].forEach(latLngList => {
          coordsList.push(latLngList);
        });
        coords = coordsList;
      } else if (res.from === this.notePadService.shapeDrawType.MULTI_POINT /*'position'*/){
        this.site_type = res.from;
        res['features'].features.forEach(features => {
          const latLngList = features.geometry.coordinates;
          coordsList.push([latLngList[0][0],latLngList[0][1]]);
        });
        coords = coordsList;
      }
      else if (res.from === this.notePadService.shapeDrawType.MULTI_LINE /*'position'*/){
        this.site_type = res.from;
        res['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(elements => {
            let multiLineCoords=[];
            elements.forEach(element => {
              multiLineCoords.push(element[0])              
              multiLineCoords.push(element[1])    
            });
            coordsList.push(multiLineCoords)
          })
        });
        coords = coordsList;
      }
      else if (res.from === this.notePadService.shapeDrawType.MULTI_POLYGON /*'position'*/){
        this.site_type = res.from;
        res['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(elements => {
            let multiPolygonCoords=[];
            elements.forEach(element => {
              element.forEach(e =>{
                multiPolygonCoords.push(e[0])              
                multiPolygonCoords.push(e[1]) 
              })
            });
            coordsList.push(multiPolygonCoords)
          });
        });
        coords = coordsList;
      }

      this.locationData = coords;
      this.tempCreateSiteId = String(new Date().getTime());
      this.currentFeature = res;
      this.goingToMarkPosition = false;
      if (this.getCoOrdsForNewNote) {
        this.maximizeNotePage();
        this.showNotesAndFilePicker = true;
      }
    });
    this.basemap = this.basemapService.getCurrentBasemap();
    if (this.commonService.isValid(localStorage.getItem('token'))) {
      this.isGuest = false;
    } else {
      this.isGuest = true;
    }
    this.authObsr.subscribeForAuthStatus('CaptureComponent', (authRes, msg) => {
      if (authRes.status === 'success') {
        this.isGuest = false;
      } else if (authRes.status === 'failed') {
        this.isGuest = true;
        if (this.globalObject.pageType !== 'COVID19') {
          this.closeNotePage();
        }
        this.clearContextInfo();
      }
    });
    this.monitorChangesInSiteSearch();
  }

  @HostListener('window:keyup.esc', ['$event'])
  keyEvent(event: KeyboardEvent): any {
    this.notePadService.removePolygonMarkTools();
  }

  ngOnInit(): any {
    if (this.globalObject.pageType === 'COVID19') {
      this.authObsr.subscribeForRefreshSites('CaptureComponent', (status, msg) => {
        this.maximizeNotePage();
        this.getCurrentContextSites();
      });
    }
    this.projectSelect.valueChanges.subscribe(res => {
      if (this.commonService.isValid(res) /*&& res !== 'ALL'*/) {
        this.selectedProjectId = res;
        this.resetSearchSiteBox();
        const projectIndex = this.projects.findIndex(val => String(val.topicId) === String(res));
        if (projectIndex !== -1){
          this.selectedProject = this.projects[projectIndex];
        }
        this.currentContextInfo = {
          project: this.selectedProject,
          place: this.selectedPlace,
          topic: this.selectedTopic
        };
        this.checkProjectPlaceTopicAndGetSites();        
      }      
    });

    this.placeSelect.valueChanges.subscribe(res => {
      if (this.selectedPlaceId !== res){
        this.selectedPlaceId = res;
        this.resetSearchSiteBox();
        const placeIndex = this.places.findIndex(val => String(val.topicId) === String(res));
        if (placeIndex !== -1){
          this.selectedPlace = this.places[placeIndex];
        }
        this.currentContextInfo = {
          project: this.selectedProject,
          place: this.selectedPlace,
          topic: this.selectedTopic
        };
        this.checkProjectPlaceTopicAndGetSites();
      }
    });

    this.topicSelect.valueChanges.subscribe(res => {
      if (this.selectedTopicId !== res ){ // && (!this.topicSelect.disabled)) {
        this.selectedTopicId = res;
        this.resetSearchSiteBox();
        const topicIndex = this.topics.findIndex(val => String(val.topicId) === String(res));
        if (topicIndex !== -1){
          this.selectedTopic = this.topics[topicIndex];
        }
        this.currentContextInfo = {
          project: this.selectedProject,
          place: this.selectedPlace,
          topic: this.selectedTopic
        };
        this.checkProjectPlaceTopicAndGetSites();
      }
    });


    // FOR ONLY COVID19 PAGE AS OF NOW
    this.oldDataRangeSelect.valueChanges.subscribe(res => {
      this.getCurrentContextSites();
    });
    this.packagecontrolgeopad=["GeopadSharingmail","GeopadSharingurl","GeopadSharingdownload"]
    this.commonService.epsgChangeGeosol.subscribe((data) => {
      this.getAllNewSites('','')
    });
  }

  showOrCloseSite(operation) {
    let geometryData: any;
    let isPolygon = false;
    const sitesStore = this.notePadService.getStoredNotesObject()
    if (sitesStore !== undefined) {
      this.checkLocalStorage++
      if (this.checkLocalStorage < 2) {
        localStorage.setItem('all sites', JSON.stringify(sitesStore))
      }
    }
    const allSites = JSON.parse(localStorage.getItem('all sites'))
    if (!this.projection) {
      this.projection = getProjection("EPSG:3857")
    }
    this.storeNotesForTrack.map((note) => {
      if (allSites) {
        allSites.map((x) => {
          if (note.observationInstanceId === x.observationInstanceId) {
            note.latitudeLongitude = x.latitudeLongitude
          }
        })
      }
      const siteParams = note.siteParams;
      const currentContextInfo: any = {};
      for (const key in this.currentContextInfo) {
        if (Object.hasOwnProperty.call(this.currentContextInfo, key)) {
          currentContextInfo[key] = this.currentContextInfo[key];
        }
      }
      currentContextInfo.site = note;
      if (note.latitudeLongitude.length === 2) {
        geometryData = {
          type: this.nodePadService.shapeDrawType.POINT, // 'Point',
          coordinates: [Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])]
        };
        const pointArray = [Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])]
        if (this.projection) {
          const transformed_coord = this.basemapService.getTransformedCoordinates([pointArray[0], pointArray[1]], getProjection(this.basemapService.projectionsList[8].name), this.projection)
          geometryData.coordinates = transformed_coord
          currentContextInfo.site.latitudeLongitude = transformed_coord
        }
      } else if (note.latitudeLongitude.length > 2) {
        if (note.latitudeLongitude.length > 4) {
          if (note.latitudeLongitude[0] === note.latitudeLongitude[note.latitudeLongitude.length - 2] &&
            note.latitudeLongitude[1] === note.latitudeLongitude[note.latitudeLongitude.length - 1]) {
            isPolygon = true;
          }
        }
        geometryData = {
          type: isPolygon ? this.nodePadService.shapeDrawType.POLYGON : this.nodePadService.shapeDrawType.LINE_STRING,
          coordinates: []
        };
        let i = 0;
        const coOrds = [];
        while (i < note.latitudeLongitude.length) {
          try {
            const tempArray = [Number(note.latitudeLongitude[i]), Number(note.latitudeLongitude[i + 1])];
            coOrds.push(tempArray);
          } catch (e) {
            console.log(e);
          }
          i = i + 2;
        }
        if (this.projection) {
          var output_coord = []
          var checkObj = []
          for (let i = 0; i < coOrds.length; i++) {
            for (let j = 0; j < 1; j++) {
              const transformed_coord = this.basemapService.getTransformedCoordinates([coOrds[i][j], coOrds[i][j + 1]], getProjection(this.basemapService.projectionsList[8].name), this.projection)
              output_coord.push(transformed_coord)
            }
          }
          for (let i = 0; i < output_coord.length; i++) {
            for (let j = 0; j < 2; j++) {
              checkObj.push(`${output_coord[i][j]}`)
            }
          }
          geometryData.coordinates = isPolygon ? [output_coord] : output_coord;
          currentContextInfo.site.latitudeLongitude = checkObj
        } else {
          geometryData.coordinates = isPolygon ? [coOrds] : coOrds;
        }
      }
      const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
        this.tempCreateSiteId; // note.latitudeLongitude.toString();
      const data = {
        features: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: geometryData,
            properties: null
          }]
        },
        name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
      };
      let layerFound = false;
      let addedLayerObj: any;
      this.basemap.getLayers().forEach(layerObj => {
        if (layerObj !== undefined) {
          if (layerObj.values_.name === data.name) {
            layerFound = true;
            addedLayerObj = layerObj;
          }
        }
      });
      let visible = false;
      if (layerFound) {
        this.notePadService.removeLayerFromMap(this.basemap, data.name);
      }
      if (operation === '' || operation === 'zoom') {
        // ONLY OF OPERATION is '', IT SHOULD WORK IN TOGGLE MODE
        if (note.latitudeLongitude.length === 2) {
          this.notePadService.reDrawPointOrPolygonOnMapForCapture(this.notePadService.shapeDrawType.POINT, data,
            operation === 'zoom', currentContextInfo, siteParams);
          visible = true;

        } else if (note.latitudeLongitude.length > 2) {
          this.notePadService.reDrawPointOrPolygonOnMapForCapture(
            isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING,
            data, operation === 'zoom', currentContextInfo, siteParams);
          visible = true
        }
      }
      const siteIndex = this.savedNotes.findIndex(site => site.observationInstanceId === note.observationInstanceId);
      if (siteIndex !== -1) {
        this.savedNotes[siteIndex].visible = visible;
      }
    })
  }

  checkProjectPlaceTopicAndGetSites(): void{
    if (this.projectsDataCollected && this.placesDataCollected && this.topicsDataCollected){
      try{
        this.getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(this.globalObject.geobase.geopadId,
              this.projectSelect.value, this.placeSelect.value, this.selectedTopicId, this.currentSiteDataToRefresh);
      } catch (e){
        console.log(e);
      }
      this.currentSiteDataToRefresh = {};
    } else {
    }
  }
  ngOnDestroy(): any {
    this.closeNotePage();
    this.notePadService.removePolygonMarkTools();
    this.notePadService.clearPolygonDrawingTools();
  }

  ngAfterViewInit(): void{
    if ( this.globalObject.pageType === 'COVID19'){
      setTimeout(() => {
        this.minimizeNotePage();
      }, 1000);
    }
  }

  activatePackageUIElements(): any {
    if(this.packageItems !== null) {
      if(!this.showsharemail || !this.showshareurl || !this.showsharedownload) {
          this.packageItems.forEach((key, value) => {
            if (key.name == "GeopadSharingmail" && !this.showsharemail) {
              this.showsharemail = key.isAccess;
            }
            if (key.name == "GeopadSharingurl" && !this.showshareurl) {
              this.showshareurl = key.isAccess;
            }
            if (key.name == "GeopadSharingdownload" && !this.showsharedownload) {
              this.showsharedownload = key.isAccess;
            }
          })
      }    
    }
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}): any {
    if(localStorage.getItem('email') !== null) {
      if(this.packageItems === null || this.packageItems === undefined) {
        this.packageItems = this.commonService.packageStringToJsonArray();
        this.activatePackageUIElements();    
      }
    }

    setTimeout(() => {
      // Here adding the Activate Tool for covid only
      if (this.globalObject.pageType === 'COVID19'){
        const toolOptions = {
          title: 'QTB',
          isCtrlClicked: false,
          popupComponent: this.popupComponent,
          isCovidPage: true
        };
        this.geotrayService.activateTool(toolOptions);
        this.basemapService.setMouseIcon('auto');
      }
    }, 5000);
    if (this.commonService.isValid(changes.data)) {
      if (this.commonService.isValid(changes.data.currentValue)) {
        this.showNotesPicker = true;
      }
    }
    if (this.commonService.isValid(changes.showCapture)) {
      if (!changes.showCapture.currentValue) {
        this.resetAll();
      } else {
        this.analytics.sendPageViewData('geopad', 'Geopad');
        this.minimizedWindow = false;
        if (!this.isGuest){
          this.getProjectsList();
        }
        if (this.globalObject.geobase !== null && this.globalObject.geobase !== '') {
          this.defaultGeobaseInfo = this.globalObject.geobase;
          this.currentSession = this.globalObject.geobase;
          this.getGeoTowerItems(this.globalObject.geobase.towerId);
          if (this.globalObject.sessionShare !== null && this.globalObject.sessionShare !== ''){
            if (localStorage.getItem('email') === this.globalObject.sessionShare.recipientUserEmail){
              this.selectedSessionCategory = 'shareWithMe';
            } else if (localStorage.getItem('email') === this.globalObject.sessionShare.senderUserEmail){
              this.selectedSessionCategory = 'mySession';
            }
            this.selectedSessionId = this.globalObject.geobase.sessionId;
          }
        }
        this.sessionCategoryCtrl.setValue(this.selectedSessionCategory);
        this.getGeobaseListByFilterProcess(this.selectedSessionCategory);
        this.getUserEmailsListByOrg();
      }
    }
    if (this.commonService.isValid(changes.showExpandedView)) {
      if (this.commonService.isValid(changes.showExpandedView.currentValue)) {
        this.maximizeNotePage();
      }
    }
    if (this.commonService.isValid(changes.markLocation)) {
      if (changes.markLocation.currentValue && !this.isGuest && this.isValidSession) {
        this.markLocationOnMap();
      }
    }

    if (this.commonService.isValid(changes.currentSession)) {
      if (changes.currentSession.currentValue) {
        this.ownerName = localStorage.getItem('name');
        this.ownerEmail = localStorage.getItem('email');
        this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
        if (this.commonService.isValid(this.currentSession.sessionId)) {
          this.isValidSession = true;
          if (!changes.currentSession.firstChange) {
            this.getProjectsList();
            if (this.globalObject.geobase !== null && this.globalObject.geobase !== '') {
              this.currentSession = this.globalObject.geobase;
            }
            this.sessionCategoryCtrl.setValue(this.selectedSessionCategory);
            this.getGeobaseListByFilterProcess(this.selectedSessionCategory);
            if (this.showCapture && this.commonService.isValid(this.globalObject.geobase)){
              this.getGeoTowerItems(this.globalObject.geobase.towerId);
            }
          }
        } else{
          this.isValidSession = false;
        }
      }
      if (!this.commonService.isValid(this.defaultSession.sessionId)){
        this.defaultSession = this.currentSession;
      }
    }

    if (this.commonService.isValid(changes.viewMode)) {
      if (this.commonService.isValid(changes.viewMode.currentValue)) {
        if (this.viewMode.mode === this.captureModes.AWARENESS){
          this.showOrCloseLocationOnMap({latitudeLongitude: this.locationData}, 'close');
          this.showOrHideAwareness();
        } else if (this.viewMode.mode === this.captureModes.CAPTURE && this.viewMode.from === 'annotate'){
          this.data = 'images';
          this.locationData = this.viewMode.coords;
          this.tempCreateSiteId = String(new Date().getTime());
          this.currentFeature = this.viewMode.features;
          this.goingToMarkPosition = false;
          this.showNotesAndFilePicker = true;
          this.maximizeNotePage();
        }
      }
    }

    if (this.commonService.isValid(changes.isGuest)) {
      if (this.commonService.isValid(changes.isGuest.currentValue)) {
        if (changes.isGuest.currentValue) {
          if ( this.globalObject.pageType === 'COVID19'){
            this.oldDataRangeSelect.setValue(10);
            this.ownerName = localStorage.getItem('name');
            this.ownerEmail = localStorage.getItem('email');
            this.getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(this.globalObject.geobase.geopadId,
                                                                          'ALL', 'ALL', 'ALL', {}, true);
          }
        }
      }
    }

  }

  showOrHideAwareness(): void{
    if (this.viewMode.mode === this.captureModes.AWARENESS && this.viewMode.show) {
      this.closeNotes('');
      this.closeAllMarkersAndPolygons();
      this.showCapture = true;
      this.getProjectsList();
      if (this.globalObject.geobase !== null && this.globalObject.geobase !== '') {
        this.currentSession = this.globalObject.geobase;
      }
    } else if (this.viewMode.mode === this.captureModes.AWARENESS && !this.viewMode.show) {
      this.closeNotePage();
    } else if (this.viewMode.mode === this.captureModes.CAPTURE) {
      this.closeAllMarkersAndPolygons();
    }
  }

  markLocationOnMap(): any {
    this.showNotesPicker = true;
    this.pickLoation('images');
  }

  markPosition(): any {
    this.goingToMarkPosition = true; // !this.goingToMarkPosition;
    if (this.goingToMarkPosition) {
      if (this.showNotesPicker) {
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.POINT,
                                                      this.positionMarkObserver, this.currentContextInfo);
      } else if (this.showNotesPickerForPolygon) {
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.POLYGON,
                                                      this.positionMarkObserver, this.currentContextInfo);
      } else if (this.showNotesPickerForLine) {
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.LINE_STRING,
                                                      this.positionMarkObserver, this.currentContextInfo);
      }
      else if (this.showNotesPickerForMultiPoints) {
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.MULTI_POINT,
                                                      this.positionMarkObserver, this.currentContextInfo);                                            
      }
      else if (this.showNotesPickerForMultiLines) {        
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.MULTI_LINE,
                                                      this.positionMarkObserver, this.currentContextInfo);                                            
      }
      else if (this.showNotesPickerForMultiPolygons) {
        this.notePadService.locatePointOrPolygonOnMap(this.notePadService.shapeDrawType.MULTI_POLYGON,
                                                      this.positionMarkObserver, this.currentContextInfo);                                            
      }
    } else {      
      this.locationData = [];
    }
  }
  clearLocation(): any {
    this.locationData = [];
    this.goingToMarkPosition = false;
    this.removeCurrentFeature();
  }
  reset(): any {
    this.operation = 'add';
    this.selectedNote = {};
    this.clearLocation();
  }
  showError(msg): any {
    this.errorMsg = msg;
    setTimeout(() => {
      this.errorMsg = '';
    }, 5000);
  }
  editNotes(note): any {
    if (this.globalObject.pageType === 'share') {
      this.showSessionShareSiteEditAlert = true;
      return ;
    }
    for (const key in note) {
      if (note.hasOwnProperty(key)) {
        this.selectedSiteStateWhileEdit[key] = note[key];
      }
    }
    this.showOrCloseLocationOnMap(note, 'close');
    try{
      this.currentContextInfo = {
        project: note.project,
        place: note.place,
        topic: note.topic
      };
    } catch (e){
      console.log(e);
    }
    this.selectedNote = note;
    this.operation = 'update';
    this.data = 'images';
    this.showNotesAndFilePicker = true;
  }
  confirmDelete(note): any {
    if (this.globalObject.pageType === 'share') {
      this.showSessionShareSiteEditAlert = true;
      return ;
    }
    this.selectedNote = note;
    this.showConfirmDelete = true;

  }
  deleteNote(): any {
    if (this.globalObject.pageType === 'share') {
      this.showSessionShareSiteEditAlert = true;
      return ;
    }
    this.showConfirmDelete = false;
    const note = this.selectedNote;
    this.showDeletingStatus = true;
    this.showOrCloseLocationOnMap(this.selectedNote, 'close');
    this.nodePadService.deleteRelationGeopadSite(this.globalObject.geobase.geopadId, note.observationInstanceId)
          .subscribe(result => {
            this.showDeletingStatus = false;
            if (!this.commonService.isValid(result)) {
            } else {
            }
            try{
              this.getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(this.globalObject.geobase.geopadId,
                          this.projectSelect.value, this.placeSelect.value, this.selectedTopicId);
            } catch (e){
              console.log(e);
            }
          }, error => {
            if (error.errorCode === 500) {
            }
            this.showDeletingStatus = false;
          });
  }
  responseOfCurrentNoteCloseRequestFun(decesion): void{
    if (decesion === 'yes') {
      this.closeNotePage();
    }
    this.confirmCurrentNotesClosing = {};
  }
  checkAndcloseNotePage(): void {
    if (this.showNotesAndFilePicker) {
      this.confirmCurrentNotesClosing = { type: 'confirm-close', ts: String(new Date().getTime()) };
    } else {
      this.closeNotePage();
    }
  }

  clearContextInfo(): void{
    this.closeAllMarkersAndPolygons();
    this.selectedPlaceId = null;
    this.selectedProjectId = null;
    this.selectedTopicId = null;
    this.selectedProject = {};
    this.selectedPlace = {};
    this.selectedTopic = {};
    this.projectSelect.setValue(null);
    this.placeSelect.setValue(null);
    this.topicSelect.setValue(null);
    this.savedNotes = [];
    this.projects = [];
    this.places = [];
    this.topics = [];
    this.orgUserEmailsToShow = [];
  }
  closeNotePage(): any {
    this.showOrCloseLocationOnMap({latitudeLongitude: this.locationData}, 'close');
    this.closeCapture.emit();
    this.showCapture = false;
    this.resetAll();
    this.closeAllMarkersAndPolygons();
    this.savedNotes = [];
    this.minimizedWindow = false;
    this.getCoOrdsForNewNote = false;
    this.showNotesAndFilePicker = false;
    this.data = '';
    this.showSavingStatus = false;
    this.showDeletingStatus = false;
    this.showConfirmDelete = false;
  }
  closeAllMarkersAndPolygons(): void{
    this.savedNotes.forEach(element => {
      this.showOrCloseLocationOnMap(element, 'close');
    });
  }
  minimizeNotePage(): any {
    this.renderer.listen(this.captureWindow.nativeElement, 'animationend', (e) => {
      this.minimizedWindow = true;
      const clsList1 = this.captureWindow.nativeElement.classList;
      if (clsList1.contains('captureWinSlideRight')){
        clsList1.remove('captureWinSlideRight');
      }
    }).bind(this);
    const clsList = this.captureWindow.nativeElement.classList;
    if (!clsList.contains('captureWinSlideRight')){
      clsList.add('captureWinSlideRight');
    } else {
    }
  }

  mouseDownOnSession(): void{
    this.mouseDownTimeStamp = new Date().getTime();
  }
  mouseUpOnSession(): void{
    if (new Date().getTime() - this.mouseDownTimeStamp < 500) {
      this.maximizeNotePage();
    } else {
    }
  }

  maximizeNotePage(): any {
    this.renderer.listen(this.captureWindow.nativeElement, 'animationend', (e) => {
      this.minimizedWindow = false;
      const clsList1 = this.captureWindow.nativeElement.classList;
      if (clsList1.contains('captureWinSlideLeft')){
        clsList1.remove('captureWinSlideLeft');
      }
    }).bind(this);
    const clsList = this.captureWindow.nativeElement.classList;
    if (!clsList.contains('captureWinSlideLeft')){
      clsList.add('captureWinSlideLeft');
    } else {
    }
  }
  gotoLocation(note): any {
  }
  showHideNotesPickesWithMyLocation(): any {
    this.userLocation();
    }
  userLocation(): any {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.updateLocation, this.handleLocationError, { timeout: 100 });
        const geolocation = new Geolocation({
          tracking: true
        });
        geolocation.on('change', (evt) => {
          this.basemapService.getCurrentBasemap().getView().setCenter(geolocation.getPosition());
          this.basemapService.getCurrentBasemap().getView().setZoom(10);
        });
      } else {
      }
  }
  updateLocation(positionData): any {
      const latitude = positionData.coords.latitude;
      const longitude = positionData.coords.longitude;
      this.basemapService.getCurrentBasemap().getView().setCenter([longitude, latitude]);
      this.basemapService.getCurrentBasemap().getView().setZoom(6);
  }
  handleLocationError(): any {
  }
  showHideNotesPickes(): any {
    this.showNotesPicker = !this.showNotesPicker;
    if (this.showNotesPicker) {
      this.showNotesPickerForPolygon = false;
      this.showNotesPickerForLine = false;
      this.showNotesPickerForMultiPoints = false;
      this.showNotesPickerForMultiLines = false;
      this.showNotesPickerForMultiPolygons = false;
    }
    this.data = '';
    this.pickLoation('images');
  }
  showHideNotesPickesForPolygon(): any {
    this.showNotesPickerForPolygon = !this.showNotesPickerForPolygon;
    if (this.showNotesPickerForPolygon) {
      this.showNotesPicker = false;
      this.showNotesPickerForLine = false;
      this.showNotesPickerForMultiPoints = false;
      this.showNotesPickerForMultiLines = false;
      this.showNotesPickerForMultiPolygons = false;
    }
    this.data = '';
    this.pickLoation('images');
  }
  showHideNotesPickesForLine(): any {
    this.showNotesPickerForLine = !this.showNotesPickerForLine;
    if (this.showNotesPickerForLine) {
      this.showNotesPicker = false;
      this.showNotesPickerForPolygon = false;
      this.showNotesPickerForMultiPoints = false;
      this.showNotesPickerForMultiLines = false;
      this.showNotesPickerForMultiPolygons = false;
    }
    this.data = '';
    this.pickLoation('images');
  }

  showHideNotesPickesForMultiPoints(): any {
    this.showNotesPickerForMultiPoints = !this.showNotesPickerForMultiPoints;
    if (this.showNotesPickerForMultiPoints) {
      this.showNotesPicker = false;
      this.showNotesPickerForPolygon = false;
      this.showNotesPickerForLine = false;      
      this.showNotesPickerForMultiLines = false;
      this.showNotesPickerForMultiPolygons = false;
    }
    this.data = '';
    this.pickLoation('images');
  }
  showHideNotesPickesForMultiLines(): any {    
    this.showNotesPickerForMultiLines = !this.showNotesPickerForMultiLines;
    if (this.showNotesPickerForMultiLines) {
      this.showNotesPicker = false;
      this.showNotesPickerForPolygon = false;
      this.showNotesPickerForLine = false;
      this.showNotesPickerForMultiPoints = false;
      this.showNotesPickerForMultiPolygons = false;
    }
    this.data = '';
    this.pickLoation('images');
  }
  showHideNotesPickesForMultiPolygons(): any {
    this.showNotesPickerForMultiPolygons = !this.showNotesPickerForMultiPolygons;
    if (this.showNotesPickerForMultiPolygons) {
      this.showNotesPicker = false;
      this.showNotesPickerForPolygon = false;
      this.showNotesPickerForLine = false;
      this.showNotesPickerForMultiPoints = false;
      this.showNotesPickerForMultiLines = false;
    }
    this.data = '';
    this.pickLoation('images');
  }
  saveMultiGeometryofPointandpolygons():any{
    // 
    this.notePadService.getMultiFeaturesOfPointOrPolygonOnMap()
  }

  pickLoation(type): any {
    this.data = type;
    this.markPosition();
    this.getCoOrdsForNewNote = true;
    setTimeout(() => {
    }, 500);
  }
  resetAll(): any {
    this.data = '';
    this.showNotesPicker = false;
    this.showNotesPickerForPolygon = false;
    this.showNotesPickerForLine = false;
    this.showNotesPickerForMultiPoints =false;
    this.showNotesPickerForMultiLines =false;
    this.showNotesPickerForMultiPolygons =false;
    this.markLocation = false;
    this.userEmailListString = '';
    this.userEmail = new FormControl('', [Validators.email]);
    this.selectedSessionSharingOption = SessionSharingOption.UNKNOWN;
    this.showSessionShareMenu = false;
    this.copySelected = false;
    this.sessionNameInput = new FormControl('');
    this.reset();
    this.projectsDataCollected = false;
    this.placesDataCollected = false;
    this.topicsDataCollected = false;
  }
  closeNotes(e): any {
    this.showNotesAndFilePicker = false;
    if (e === 'without-save'){
      if (this.selectedSiteStateWhileEdit.visible){
        this.showOrCloseLocationOnMap(this.selectedSiteStateWhileEdit);
      }
      this.selectedSiteStateWhileEdit = {};
    }
    if (this.selectedNote.visible){
      this.getAllNewSites(this.selectedNote);
    }
    this.resetAll();
    this.removeCurrentFeature();
  }

  getAllNewSites(currentSiteData, refreshProjects = ''): any {
    let geopadId = this.currentSession.geopadId;
    if (this.currentSession.geopadId === null) {
      geopadId = 0;
    }
    this.currentSiteDataToRefresh = {};
    if (refreshProjects === 'refresh') {
      this.projectsDataCollected = false;
      this.placesDataCollected = false;
      this.topicsDataCollected = false;
      this.currentSiteDataToRefresh = currentSiteData;
      this.getProjectsList();
    } else{
      this.getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(this.globalObject.geobase.geopadId,
                                  this.projectSelect.value, this.placeSelect.value, this.topicSelect.value, currentSiteData);
    }
    this.searchSiteType = SiteType.ALL;
    setTimeout(() => {
      // ADDING DELAY TO SUPPORT REMOVING ANNOTATION BOUNDARY AND OPERATION BUTTONS
      this.viewMode.mode = this.captureModes.CAPTURE;
      this.viewMode.from = 'geopad';
    }, 1000);    
    this.showNotesAndFilePicker = false;
    this.resetAll();
  }
  removeCurrentFeature(): any {
    this.notePadService.removeFeatureOnMap(this.currentFeature);
  }

  showSavingStatusFun(event): any {
    this.showSavingStatus = event;
  }
  getDefaultGeobase(): void {
    this.savedNotes = [];
    let sessionId = 0;
    let isDefault = true;
    if (this.sessionId > 0) {
      sessionId = this.sessionId;
      isDefault = false;
    }
    this.geobaseService.getGeobase(sessionId, isDefault)
          .subscribe(geobaseInfo => {
            if (!this.commonService.isValid(geobaseInfo)) {
              console.log('No geobaseInfo present');
            } else {
              this.currentSession = geobaseInfo;
              this.getProjectsList();
              let geopadId = this.currentSession.geopadId;
              if (this.currentSession.geopadId === null) {
                geopadId = 0;
              }
            }
          }, error => {
            console.log(error);
            if (error.errorCode === 500) {
            }
          });
  }  

  getProjectsList(): any {
    this.projectSelect.disable();
    this.placeSelect.disable();
    this.topicSelect.disable();
    this.placesDataCollected = false;
    this.topicsDataCollected = false;
    this.projectsDataCollected = false;
    this.getPlacesListByProjectId(1);
    this.getTopicsListByPlaceId(1);
    this.topicsService.getProjectsList(this.globalObject.pageType === 'COVID19' ? 'COVID19' : this.userInfo.type,
                                        this.globalObject.geobase.organizationId)
    .subscribe(projectInfo => {
      this.projectSelect.enable();
      if (!this.commonService.isValid(projectInfo)) {
        this.projects = [{name: 'All Projects', topicId: 0}];
        this.selectedProject = this.projects[0];
        this.projectsDataCollected = true;
        setTimeout(() => {
          this.setDataToFormControl(this.projectSelect, this.selectedProject, this.projects);
        }, 500);
      } else {
        this.projects = [{name: 'All Projects', topicId: 0}].concat(projectInfo);
        // this.projects.push({name: 'All', topicId: 'ALL'});
        if (this.commonService.isValid(this.selectedProjectId) && this.selectedProjectId > 0) {
          const index = this.projects.findIndex(val => Number(val.topicId) === Number(this.selectedProjectId));
          if (index !== -1){
            this.selectedProject = this.projects[index];
          }
        }
        this.projectsDataCollected = true;
        setTimeout(() => {
          this.setDataToFormControl(this.projectSelect, this.selectedProject, this.projects);
        }, 500);        
      }
    }, error => {
      console.log(error);
      if (error.errorCode === 500) {
      }
      this.projectSelect.enable();
    });
  }

  getPlacesListByProjectId(projectId): any {
    this.placeSelect.disable();
    this.topicSelect.disable();
    if (this.globalObject.pageType !== 'COVID19'){
       // this.topicsService.getPlacesListByProjectId(projectId)
       this.topicsService.getTopicsListByTopicType('place')
       .subscribe(placesInfo => {
         this.placeSelect.enable();
         console.log('Got placesInfo info', placesInfo);
         if (!this.commonService.isValid(placesInfo)) {
          this.places = [{name: 'All Places', topicId: 0}];
          this.selectedPlace = this.places[0];
          this.placesDataCollected = true;
          setTimeout(() => {
            this.setDataToFormControl(this.placeSelect, this.selectedPlace, this.places);
          }, 500);
         } else {
          this.places = placesInfo;
          this.places = [{name: 'All Places', topicId: 0}].concat(placesInfo);

          if (this.commonService.isValid(this.selectedPlaceId) && this.selectedPlaceId > 0) {
            const placeIndex = this.places.findIndex(val => String(val.topicId) === String(this.selectedPlaceId));
            console.log(`Place index : ${placeIndex}`);
            if (placeIndex !== -1){
              this.selectedPlace = this.places[placeIndex];
            }
          }
          this.placesDataCollected = true;
          setTimeout(() => {
            this.setDataToFormControl(this.placeSelect, this.selectedPlace, this.places);
          }, 500);
         }

       }, error => {
         console.log('Error while getting placesInfo');
         console.log(error);
         if (error.errorCode === 500) {
         }
         this.placeSelect.enable();
       });
    } else {
      this.places = [{
        createdDate: null,
        description: 'Place is world wide',
        name: 'world-wide',
        organizationId: 5,
        parentTopicId: 994,
        status: 'Active',
        topicId: 1010,
        topicUsage: 1,
        updatedDate: null,
      }];
      this.selectedPlaceId = null;
      setTimeout(() => {
        this.setDataToFormControl(this.placeSelect, this.selectedPlace, this.places);
      }, 500);
    }
  }

  getTopicsListByPlaceId(placeId): any {
    console.log('getting the topics list', placeId);
    this.topicSelect.disable();
    if (this.globalObject.pageType !== 'COVID19'){
       // this.topicsService.getTopicsListByPlaceId(placeId)
       this.topicsService.getTopicsListByTopicType('topic')
       .subscribe(topicsInfo => {
          this.topicSelect.enable();
          console.log('Got topicsInfo info', topicsInfo);
          if (!this.commonService.isValid(topicsInfo)) {
            console.log('No topicsInfo present');
            this.topics = [{name: 'All Topics', topicId: 0}];
            this.selectedTopic = this.topics[0];
            this.topicsDataCollected = true;
            setTimeout(() => {
              this.setDataToFormControl(this.topicSelect, this.selectedTopic, this.topics);
            }, 500);
          } else {
            console.log('topicsInfo present', topicsInfo);
            this.topics = topicsInfo;
            this.topics = [{name: 'All Topics', topicId: 0}].concat(topicsInfo);

            if (this.commonService.isValid(this.selectedTopicId) && this.selectedTopicId > 0) {
              const topicIndex = this.topics.findIndex(val => String(val.topicId) === String(this.selectedTopicId));
              console.log(`Topic index : ${topicIndex}`);
              if (topicIndex !== -1){
                this.selectedTopic = this.topics[topicIndex];
              }
            }
            this.topicsDataCollected = true;
            setTimeout(() => {
              this.setDataToFormControl(this.topicSelect, this.selectedTopic, this.topics);
            }, 500);
          }
      }, error => {
         console.log('Error while getting topicsInfo');
         console.log(error);
         if (error.errorCode === 500) {
         }
         this.topicSelect.enable();
       });
    } else {
      this.topicsService.getTopicsListByPlaceId(placeId)
            .subscribe(topicsInfo => {
              this.topicSelect.enable();
              console.log('Got topicsInfo info', topicsInfo);
              if (!this.commonService.isValid(topicsInfo)) {
                console.log('No topicsInfo present');
              } else {
                console.log('topicsInfo present', topicsInfo);
                this.topics = [{ name: 'All Topics', topicId: 'ALL' }].concat(topicsInfo);
                this.selectedTopicId = null;
                // }
                setTimeout(() => {
                  this.setDataToFormControl(this.topicSelect, this.selectedTopic, this.topics);
                }, 500);
              }
            }, error => {
              console.log('Error while getting topicsInfo');
              console.log(error);
              if (error.errorCode === 500) {
              }
              this.topicSelect.enable();
            });
    }
  }

  setDataToFormControl(formCtrl: FormControl, selectedObj: any, allListArr: any[]): any {
    let topicId;
    try{
      if (this.commonService.isValid(selectedObj)) {
        if (this.commonService.isValid(selectedObj.topicId)) {
          topicId = selectedObj.topicId;
        } else {
          topicId = allListArr[0].topicId;
        }
      } else{
        topicId = allListArr[0].topicId;
      }
    } catch (e){
      topicId = '';
    }
    formCtrl.setValue(topicId);
  }

  showAllLocationsForAwareness(): void{
    console.log('In showAllLocationsForAwareness');
    this.savedNotes.forEach(element => {
      console.log(element);
      this.showOrCloseLocationOnMap(element, 'awareness');
    });
    if (this.viewMode.mode === this.captureModes.AWARENESS){
      this.basemapService.getCurrentBasemap().getView().setZoom(2);
    }
  }

  showOrCloseLocationOnMap(note: any, operation = '', zoomToSite = true): any {
    let geometryData: any;
    let isPolygon = false;
    let isMultiPolygon = false;
    let featureData: any;
    const siteParams = note.siteParams;
    const currentContextInfo: any = {};
    for (const key in this.currentContextInfo) {
      if (Object.hasOwnProperty.call(this.currentContextInfo, key)) {
        currentContextInfo[key] = this.currentContextInfo[key];
      }
    }
    currentContextInfo.site = note;
    var data: any={};
    if(!note.ismultigeometry){
      if (note.latitudeLongitude.length === 2) {
        let ptCoords = this.basemapService.getTransformedCoordinates([Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
        geometryData = {
          type: this.notePadService.shapeDrawType.POINT, // 'Point',
          coordinates: ptCoords
        };
      } else if (note.latitudeLongitude.length > 2) {
        if (note.latitudeLongitude.length > 4) {
          if (note.latitudeLongitude[0] === note.latitudeLongitude[note.latitudeLongitude.length - 2] &&
              note.latitudeLongitude[1] === note.latitudeLongitude[note.latitudeLongitude.length - 1]) {
              isPolygon = true;
          }
        }
        geometryData = {
          type: isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING,
          coordinates: []
        };
        let i = 0;
        const coOrds = [];
        while ( i < note.latitudeLongitude.length ) {
          try{
            const tempArray = [Number(note.latitudeLongitude[i]), Number(note.latitudeLongitude[i + 1])];
            coOrds.push(tempArray);
          } catch (e) {
            console.log(e);
          }
          i = i + 2;
        }
        let coords_trans = coOrds.map(coord =>this.basemapService.getTransformedCoordinates(coord, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode()))
        console.log('coords_trans:',coords_trans)
        geometryData.coordinates = isPolygon ? [coords_trans] : coords_trans;
  
      }
      const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
                this.tempCreateSiteId; // note.latitudeLongitude.toString();
      data = {
        features: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: geometryData,
            properties: {'' : note.description + '\n' + new Date(note.uiTimestamp).toLocaleString()}
          }]
        },
        name: this.commonService.isValid(note.observationInstanceId) ? `${note.locationName}_${id}` :
                                `observationInstanceId_${id}`
      };
    }else{
      if(Array.isArray(note.multiGeometryLatitudeLongitude[0])){
        if(note.multiGeometryLatitudeLongitude[0].length == 2){          
          const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :this.tempCreateSiteId; // note.latitudeLongitude.toString();
          data = {
              features: {
              type: 'FeatureCollection',
              features: []
            },
            name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
          };
          let i=0;
          while ( i < note.multiGeometryLatitudeLongitude.length ) {
            let ptCoords = this.basemapService.getTransformedCoordinates([Number(note.multiGeometryLatitudeLongitude[i][0]), Number(note.multiGeometryLatitudeLongitude[i][1])], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
            
            featureData = {
              type:'Feature',
              geometry:{
                type: this.notePadService.shapeDrawType.MULTI_POINT, // 'multiPoint',
                coordinates: [ptCoords]
              },
              properties: null
            }
            data.features.features.push(featureData)
            i = i + 1;
          };
        }else{
          if(note.multiGeometryLatitudeLongitude[0].length > 2){
            if (note.multiGeometryLatitudeLongitude[0].length > 4) {
              
              if (note.multiGeometryLatitudeLongitude[0][0] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 2] &&
                  note.multiGeometryLatitudeLongitude[0][1] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 1]) {
                  isMultiPolygon = true;
              }
            }
            const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
            this.tempCreateSiteId; // note.latitudeLongitude.toString();
            data = {
                features: {
                type: 'FeatureCollection',
                features: []
              },
              name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
            };
            let i=0;
            while ( i < note.multiGeometryLatitudeLongitude.length ) {
              const coOrds = [];
              let j = 0;
              while ( j < note.multiGeometryLatitudeLongitude[i].length ) {
                try{
                  const tempArray = [Number(note.multiGeometryLatitudeLongitude[i][j]), Number(note.multiGeometryLatitudeLongitude[i][j + 1])];
                  coOrds.push(tempArray);
                } catch (e) {
                  console.log(e);
                }
                j = j + 2;
              }
              let coords_trans = coOrds.map(coord =>this.basemapService.getTransformedCoordinates(coord, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode()))
              if (isMultiPolygon){
                featureData = {
                  type:'Feature',
                  geometry:{
                    type:this.notePadService.shapeDrawType.MULTI_POLYGON,
                    coordinates: [[coords_trans]]
                  },
                  properties: null
                }
              }else{
                featureData = {
                  type:'Feature',
                  geometry:{
                    type: this.notePadService.shapeDrawType.MULTI_LINE,
                    coordinates: [coords_trans]
                  },
                  properties: null
                }
              }
              data.features.features.push(featureData)
              i = i + 1;
            };
          }
        } 
            
      }
    }    

    let layerFound = false;
    let addedLayerObj: any;
    this.basemap.getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === data.name) {
          layerFound = true;
          addedLayerObj = layerObj;
        }
      }
    });
    let visible = false;
    if (layerFound) {
      this.notePadService.removeLayerFromMap(this.basemap, data.name);
      visible = false;
    } else if (operation === '' || operation === 'awareness') {
      if(note.ismultigeometry == true){
        if(note.multiGeometryLatitudeLongitude[0].length == 2){

        this.notePadService.reDrawPointOrPolygonOnMap(this.notePadService.shapeDrawType.MULTI_POINT,
                                                      data, operation !== 'awareness' && zoomToSite, null, currentContextInfo, siteParams);       
        visible = true;
        }else{
          this.notePadService.reDrawPointOrPolygonOnMap(
                isMultiPolygon ? this.notePadService.shapeDrawType.MULTI_POLYGON : this.notePadService.shapeDrawType.MULTI_LINE,
                    data, operation !== 'awareness' && zoomToSite, null, currentContextInfo, siteParams);       
          
            visible = true;
          }
      }else{
        if (note.latitudeLongitude.length === 2) {
          this.notePadService.reDrawPointOrPolygonOnMap(this.notePadService.shapeDrawType.POINT,
                                                      data, operation !== 'awareness' && zoomToSite, null, currentContextInfo, siteParams);
          visible = true;
        } else if (note.latitudeLongitude.length > 2) {
          this.notePadService.reDrawPointOrPolygonOnMap(
                                isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING,
                                data, operation !== 'awareness' && zoomToSite, null, currentContextInfo, siteParams);
          visible = true;
        }
      }
      note.observationTagsList.forEach(tag => {
        this.basemap.getLayers().forEach(layerObj => {
          if (layerObj !== undefined) {
            if (layerObj.values_.name === data.name) {
              const labelStyle = new Style({
                text: new Text({
                  font: '13px Calibri,sans-serif',
                  scale: 1.5,
                  fill: new Fill({
                    color: 'rgba(0, 0, 0, 1)'
                  }),
                  padding: [2, 2, 2, 2],
                  stroke: new Stroke({
                        color: "#000f",
                        width: 1
                    })
                })
              });              
            labelStyle.getText().setText(tag.tagName)
            let tagCoords = this.basemapService.getTransformedCoordinates([tag.latitude,tag.longitude], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
            const point = new Point(tagCoords);
            const pointFeature = new Feature({
              geometry: point,
              name: tag.tagName,
            });
            pointFeature.setStyle(labelStyle)
            layerObj.getSource().addFeature(pointFeature)
            }
          }
        });
      });
    }
    const siteIndex = this.savedNotes.findIndex(site => site.observationInstanceId === note.observationInstanceId);
    if (siteIndex !== -1) {
      this.savedNotes[siteIndex].visible = visible;
    }
  }

  viewSiteDetails(note): void{
    const tempNote: any = {};
    for (const key in note) {
      if (note.hasOwnProperty(key)) {
        tempNote[key] = note[key];
      }
    }
    this.selectedNote = tempNote;
    this.showOrCloseLocationOnMap(note, 'close');
    this.operation = 'view';
    this.data = 'images';
    this.showNotesAndFilePicker = true;
  }

  getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(geopadId, projectId, placeId, topicId,
                                                          currentSiteData: any = {}, isGuest = false): any {
    if (this.commonService.isValid(projectId) && this.commonService.isValid(placeId) &&
        this.commonService.isValid(topicId)) {          
      this.sitesDataCollected = false;
      this.closeAllMarkersAndPolygons();
      let lastNoOfDays = 0;
      if (this.globalObject.pageType === 'COVID19'){
        lastNoOfDays = this.oldDataRangeSelect.value;
      }
      this.notePadService.getAllSitesMatchedWithString(this.globalObject.geobase.sessionId,
                    geopadId, projectId, placeId, topicId,
                    this.searchName, this.searchSiteType, isGuest, lastNoOfDays)
                .subscribe(result => {                  
                  if(result.body.length > 0 ) {
                    result.body.forEach(observations => {
                      if(observations.observationInstance.latitudeLongitude.length > 0) {
                        const coords = observations.observationInstance.latitudeLongitude;
                        let coords_trans;
                        let temp_coords_trans = [];
                        if(coords.length > 2) {
                          let latlngs = [];
                          coords.forEach(lats => {
                            latlngs.push(lats)
                            if(latlngs.length === 2 ) {                               
                              coords_trans = this.basemapService.getTransformedCoordinates(latlngs, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
                              temp_coords_trans.push(coords_trans[0])
                              temp_coords_trans.push(coords_trans[1])
                              latlngs = [] 
                            }
                          });
                          coords_trans = temp_coords_trans                          
                        } else {
                          coords_trans = this.basemapService.getTransformedCoordinates(coords, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
                        }                        
                      }
                    });
                  }                  
                  this.mapSiteResponseToSites(result.body);
                  if (this.globalObject.pageType === 'share') {
                    this.currentSession.showAllSites = true;
                  }
                  // SHOWING ALL SITES ON SESSION CHANGE.
                  if (this.currentSession.showAllSites){
                    this.savedNotes.forEach(element => {
                      element.selected = true;
                      this.showOrCloseLocationOnMap(element, '', false);
                    });
                    this.selectAllSitesCheckbox._inputElement.nativeElement.click();
                    this.selectedSitesBBox();
                  } else {
                    const index = this.savedNotes.findIndex(val => String(val.observationInstanceId) ===
                                                                String(currentSiteData.observationInstanceId) ||
                        String(currentSiteData.latitudeLongitude).toString() === val.latitudeLongitude.toString() );
                    if (index !== -1) {
                      console.log('TO DRAW RECENTLY ADDED/ UPDATED SITE ON MAP');
                      this.showOrCloseLocationOnMap(this.savedNotes[index]);
                    }
                  }
                  this.sitesDataCollected = true;
                },
                error => {
                  console.log('Error while getting saved notes');
                  console.log(error);
                  const geopadList = [];
                  this.savedNotes = geopadList;
                });
    }
  }

  getCurrentContextSites(): void{
    this.getSitesListWithItemsFilterByProjectIdPlaceIdAndTopicId(this.globalObject.geobase.geopadId,
      this.projectSelect.value, this.placeSelect.value, this.topicSelect.value);
  }
  resetSearchSiteBox(): void{
    try{
      if (this.siteSearchInput.nativeElement.value.length > 0) {
        this.siteSearchInput.nativeElement.value = '';
        this.searchSiteType = SiteType.ALL;
        this.siteSearchObserver.next({});
      }
    } catch (e){
      console.log(e);
    }
  }

  setSelectedSiteType(siteType: SiteType): void{
    this.searchSiteType = siteType;
    this.siteSearchObserver.next({key: this.searchName, type: this.searchSiteType });
    this.sitesDataCollected = false;
    this.notePadService.getAllSitesMatchedWithString(this.globalObject.geobase.sessionId, this.globalObject.geobase.geopadId,
            this.projectSelect.value, this.placeSelect.value, this.topicSelect.value, this.searchName, this.searchSiteType)
          .subscribe((data: any) => {
            this.mapSiteResponseToSites(data.body);
            this.sitesDataCollected = true;
          }, error => {
          });
  }
  onSiteSearchChanged(event): void{
    const searchKey = event.target.value;
    if (this.commonService.isValid(searchKey)) {
      if (searchKey.length > 1){
        this.searchName = searchKey;
        this.closeAllMarkersAndPolygons();
        this.savedNotes = [];
        this.siteSearchObserver.next({key: searchKey, type: this.searchSiteType});
      } else if (searchKey.length <= 1){
        this.searchName = '';
        this.siteSearchObserver.next({});
      }
    } else{
      this.searchName = '';
      this.getCurrentContextSites();
      this.siteSearchObserver.next({});
    }
  }

  monitorChangesInSiteSearch(): void{    
    this.siteSearchObserver.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchObj => {
        const searchKey = searchObj.key;
        const searchSiteType = searchObj.type;
        if (this.commonService.isValid(searchKey)) {
          this.sitesDataCollected = false;
          return this.notePadService.getAllSitesMatchedWithString(this.globalObject.geobase.sessionId,
                                      this.globalObject.geobase.geopadId,
                                      this.projectSelect.value, this.placeSelect.value, this.topicSelect.value,
                                      searchKey, searchSiteType);
        } else {
          return [];
        }
      })
    ).subscribe((data: any) => {
        this.mapSiteResponseToSites(data.body);
        this.sitesDataCollected = true;
    }, error => {
      console.log('ERROR');
      console.log(error);
    });
  }

  mapSiteResponseToSites(result, currentSiteData: any = {}): void{
    if (!this.commonService.isValid(result)) {
      const geopadList = [];
      const fileList = [];
      this.savedNotes = geopadList;
    } else { 
      const geopadList = [];
      const fileList = [];
      result.forEach(geopad => {
        geopad.observationInstance.filesList = geopad.observationItemList;
        geopad.observationInstance.observationTagsList = geopad.observationTagsList;
        geopad.observationInstance.selected = false;
        geopad.observationInstance.showMoreOptions = false;
        geopad.observationInstance.isExpanded = false;
        geopad.observationInstance.visible = false;
        const note = geopad.observationInstance;        
        let type = '';
        if(note.ismultigeometry){
          if (note.multiGeometryLatitudeLongitude[0].length === 2) {
            type = this.notePadService.shapeDrawType.MULTI_POINT;
          } else if (note.multiGeometryLatitudeLongitude[0].length > 2) {
            let isMultiPolygon = false;
            if (note.multiGeometryLatitudeLongitude[0].length > 4) {
              if (note.multiGeometryLatitudeLongitude[0][0] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 2] &&
                  note.multiGeometryLatitudeLongitude[0][1] === note.multiGeometryLatitudeLongitude[0][note.multiGeometryLatitudeLongitude[0].length - 1]) {
                    isMultiPolygon = true;
              }
            }
            type = isMultiPolygon ? this.notePadService.shapeDrawType.MULTI_POLYGON : this.notePadService.shapeDrawType.MULTI_LINE;
          }
        }else{
          if (note.latitudeLongitude.length === 2) {
            type = this.notePadService.shapeDrawType.POINT;
          } else if (note.latitudeLongitude.length > 2) {
            let isPolygon = false;
            if (note.latitudeLongitude.length > 4) {
              if (note.latitudeLongitude[0] === note.latitudeLongitude[note.latitudeLongitude.length - 2] &&
                  note.latitudeLongitude[1] === note.latitudeLongitude[note.latitudeLongitude.length - 1]) {
                  isPolygon = true;
              }
            }
            type = isPolygon ? this.notePadService.shapeDrawType.POLYGON : this.notePadService.shapeDrawType.LINE_STRING;
          }
        }
        geopad.observationInstance.siteType = type;
       const observationInstanceTopics: any[] = geopad.observationInstanceTopics || [];
        observationInstanceTopics.forEach(element => {
          if (element.type === 'project'){
            geopad.observationInstance.project = {
              name: element.name,
              topicId: element.topicId,
              description: element.description
            };
          } else if (element.type === 'place'){
            geopad.observationInstance.place = {
              name: element.name,
              topicId: element.topicId,
              description: element.description
            };
          } else if (element.type === 'topic'){
            geopad.observationInstance.topic = {
              name: element.name,
              topicId: element.topicId,
              description: element.description
            };
          }
        });
        const observationInstanceIcons = geopad.observationInstanceIcons;
        let iconObj = {};
        if (this.commonService.isValid(observationInstanceIcons)) {
          iconObj = { name: observationInstanceIcons.name, imgUrl: observationInstanceIcons.url,
            value: observationInstanceIcons.iconType, id: note.observationInstanceIconsId,
            iconCategory: observationInstanceIcons.iconCategory, iconSubCategory: observationInstanceIcons.iconSubCategory,
            iconType: observationInstanceIcons.iconType};
        }
        const siteParams = new SiteParams( iconObj,
          note.iconColour, note.iconLineSize, note.iconRotation,
          '', note.fillColour, note.siteType);
        geopad.observationInstance.siteParams = siteParams;
        geopadList.push(geopad.observationInstance);
      });
      geopadList.sort((a, b) => {
        const customFieldA = new Date(a.uiTimestamp) ; // a.locationName;
        const customFieldB = new Date(b.uiTimestamp); // b.locationName;
        return (customFieldA > customFieldB) ? -1 : (customFieldA < customFieldB) ? 1 : 0;
      });
      this.savedNotes = geopadList;
      if (this.viewMode.mode === this.captureModes.AWARENESS || this.globalObject.pageType === 'COVID19') {
        this.showAllLocationsForAwareness();
      } else {
        this.closeAllMarkersAndPolygons();
      }
    }
  }
  toggleAllSitesSelection(event: MatCheckboxChange): void{
    const checked = event.checked;
    this.savedNotes.forEach(note => {
      note.selected = checked;
    });
  }
  checkIsAllSitesSelected(note, event: MatCheckboxChange): void{
    note.selected = event.checked;
    let allSelected = true;
    this.savedNotes.forEach(localNote => {
      if (!localNote.selected){
        allSelected = false;
      }
    });
    if (allSelected){
      this.selectAllSitesCheckbox.checked = true;
    } else {
      this.selectAllSitesCheckbox.checked = false;
    }
  }

  isValidProject(): boolean{
    if (this.commonService.isValid(this.projectSelect.value) && this.projectSelect.value !== 'ALL'){
      return true;
    } else {
      return false;
    }
  }

  getGeoTowerItems(towerId): any {
    this.towerItemsDataCollected = false;
    this.geoTowerList = [];
    this.multipartFiles = [];
    this.geotowerService.clientObjList.forEach((clientObj, indexValue) => {
      clientObj.selected = false;
      const index = this.geoTowerList.findIndex(val => val.name === clientObj.name && val.timestamp === clientObj.timestamp);
      if (index === -1) {
        const clientLayerObj = {
          layerObj: clientObj,
        };
        const layerInfo = this.creatingRequestLayerInfo(clientLayerObj);
        if (this.commonService.isValid(clientObj.zipfile)) {
          clientObj.zipfile.fileOrderId = indexValue;
          clientObj.zipfile.fileName = layerInfo.name;
        } else {
          clientObj.zipfile =  new File([new Blob()], layerInfo.name, {lastModified: new Date().getTime()});
        }
        this.geoTowerList.push(layerInfo);
        this.multipartFiles.push(clientObj.zipfile);
      }
    });
    // Here will getAllLayers call API
    if (this.commonService.isValid(towerId)) {
      this.layersService.getTowerIncludeLayers(towerId, true)
        .subscribe(towerIncludeLayersRes => {
          if (!this.commonService.isValid(towerIncludeLayersRes)) {
          } else {
            const layerList = towerIncludeLayersRes[0].listOfLayers;
            this.towerWithLayersList = towerIncludeLayersRes;
            if (layerList.length > 0) {
              layerList.forEach(layerObj => {
                layerObj.active = false;
                layerObj.selected = false;
                layerObj.previewLayer = false;
                layerObj.isServer = true;
                layerObj.selected = false;
                const index = this.geoTowerList.findIndex(val => val.name === layerObj.name && val.timestamp === layerObj.timestamp);
                if (index === -1) {
                  this.geoTowerList.push(layerObj);
                }
              });
            }
          }
          this.towerWithLayersList[0].listOfLayers = this.geoTowerList;
          this.towerItemsDataCollected = true;
        }, error => {
          console.log(error);
          if (error.errorCode === 500) {
          }
          this.towerWithLayersList[0].listOfLayers = this.geoTowerList;
          this.towerItemsDataCollected = true;
        });
    } else{
      this.towerWithLayersList[0].listOfLayers = this.geoTowerList;
      this.towerItemsDataCollected = true;
    }
  }
  toggleAllLayersSelection(event: MatCheckboxChange): void{
    const checked = event.checked;
    this.geoTowerList.forEach(layer => {
      layer.selected = checked;
    });
  }
  checkIsAllLayersSelected(layer, event: MatCheckboxChange): void{
    layer.selected = event.checked;
    let allSelected = true;
    this.geoTowerList.forEach(innerLayer => {
      if (!innerLayer.selected){
        allSelected = false;
      }
    });
    if (allSelected){
      this.selectAllLayersCheckbox.checked = true;
    } else {
      this.selectAllLayersCheckbox.checked = false;
    }
  }

  creatingRequestLayerInfo(layerData): any {
    let fileTypeName = layerData.layerObj.fileType;
    fileTypeName = fileTypeName.substring(fileTypeName.indexOf('.') + 1);
    const layerObj = {
      layerId: 0,
      name: layerData.layerObj.name,
      organizationId: 1,
      type: fileTypeName,
      metadata: '',
      towerId: '',
      status: 'Active',
      owner: 0,
      url: '',
      workspaceName: '',
      datastoreName: '',
      details: '',
    };
    if (layerData.layerObj.fileType === '.zip') {
      if (layerData.layerObj.zipfile instanceof File) {
        // here zip file request json creation
        // Here adding the medatadata i.e. extent of this shap file
        layerObj.details = layerData.layerObj.zipfile.name;
        this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
          if (layerData.layerObj.name === currentLayer.values_.name) {
            const extentValue = currentLayer.values_.source.getExtent();
            layerObj.metadata = JSON.stringify(extentValue);
          }
        });
        return layerObj;
      } else {
        // its unzipped file(single shp,dbf,prj files uploaded)
        this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
          if (layerData.layerObj.name === currentLayer.values_.name) {
            const extentValue = currentLayer.values_.source.getExtent();
            layerObj.metadata = JSON.stringify(extentValue);
          }
        });
        return layerObj;
      }
    } else {
      // here jpeg, kml..files json creation
      layerObj.metadata = JSON.stringify(layerData.layerObj.metadata);
      layerObj.url = layerData.layerObj.firebaseUrl;
      return layerObj;
    }
  }

  saveSession(): void{
    if (this.commonService.isValid(this.sessionNameInput.value)){
      this.geobaseService.isGeobaseExist(this.sessionNameInput.value)
            .subscribe(result => {
              if (result){
                this.showSessionOverrideAlert = true;
                // this.errorMsg = 'Session name already exists. Please choose a different session name.';
              } else{
                this.saveAsNewSession();
                this.copySelected = false;
              }
            },
            error => {
              console.log(error);
              this.copySelected = false;
            });
    }
  }

  closeSessionEmailShare(): void{
    this.selectedSessionSharingOption =  this.sessionShareOptionEnum.UNKNOWN;
  }

  closeSessionUrl(): void{
    this.selectedSessionSharingOption =  this.sessionShareOptionEnum.UNKNOWN;
  }

  close(): void{
    this.copySelected = false;
    this.selectedSessionSharingOption =  this.sessionShareOptionEnum.UNKNOWN;
  }

  selectSharingOption(option: SessionSharingOption): void{
    const sitesCount = this.getCountOfSelectedSites();
    const layersCount = this.getCountOfSelectedLayers();

    if (option === this.sessionShareOptionEnum.DOWNLOAD && sitesCount > 0) {
      let isSelected =  false;
      const selectedNotesIds = [];
      if (this.savedNotes.length !== 0) {
        this.savedNotes.forEach(note => {
          if (note.selected) {
            isSelected = true;
            selectedNotesIds.push(note.observationInstanceId);
          }
        });
        if (isSelected) {
          this.geobaseService.getGeobaseDownload(this.sessionId, this.currentSession.geopadId, selectedNotesIds);
        }
      }
      return;

    } else if (option === this.sessionShareOptionEnum.DOWNLOAD) {
      this.showError('No data to convert and download. Please select layers or sites to export');
      return;
    }
    if (this.selectedSessionId !== 'running' && this.selectedSessionId !== ''){
      this.saveSessionOptionSelectedFrom = option;
      if (sitesCount === 0 && layersCount === 0){
        this.showSessionShareAlert = true;
        return;
      }
      this.selectedSessionSharingOption = option;
      if (this.selectedSessionSharingOption === this.sessionShareOptionEnum.URL){
        if (this.selectedSessionCategory === 'mySession'){
          if (this.currentSession.sessionShareList === null || this.currentSession.sessionShareList.length === 0){
            this.selectedSessionSharingOption = this.sessionShareOptionEnum.EMAIL;
          }
          else {
            this.geobaseService.getGeobasesListByType('shareByMe', this.globalObject.geobase.sessionId)
              .subscribe(geobaseList => {
              if (this.commonService.isValid(geobaseList)) {
                console.log('geobaseList present');
                if (geobaseList.body.length > 0) {
                  geobaseList.body.forEach(geobase => {
                    if (Number(this.selectedSessionId) === geobase.session.sessionId){
                      if (geobase.sessionShare != null) {
                        if (geobase.sessionShare.url.includes(geobase.sessionShare.uuid)){
                          this.urlLink.nativeElement.value = geobase.sessionShare.url.replace('/' + geobase.sessionShare.uuid, '');
                        }
                        else{
                          this.urlLink.nativeElement.value = geobase.sessionShare.url;
                        }
                      }
                    }
                  });
                }
              }
            });
          }
        } else{
          if (this.geoSessionsList != null && this.geoSessionsList.length > 0){
            this.geoSessionsList.forEach(geobase => {
              if (Number(this.selectedSessionId) === Number(geobase.sessionId)){
                if (geobase.sessionShare != null) {
                  setTimeout(() => {
                    if (geobase.sessionShare.url.includes(geobase.sessionShare.uuid)){
                      this.urlLink.nativeElement.value = geobase.sessionShare.url.replace('/' + geobase.sessionShare.uuid, '');
                    }
                    else{
                      this.urlLink.nativeElement.value = geobase.sessionShare.url;
                    }
                  });
                }
              }
            });
          }
        }
      }

      if ((sitesCount > 0 && sitesCount < this.savedNotes.length)
          || (layersCount > 0 && layersCount < this.geoTowerList.length)){
            this.showSessionNameInput();
      }
    }
    else {
      this.saveSessionOptionSelectedFrom = option;
      this.showSessionNameInput();
    }
    this.showSessionShareMenu = false;
  }

  getCountOfSelectedSites(): any{
    let sitesCount = 0;
    if (this.savedNotes.length !== 0){
      this.savedNotes.forEach(note => {
        if (note.selected){
          sitesCount++;
        }
      });
    }
    return sitesCount;
  }

  getCountOfSelectedLayers(): any{
    let layersCount = 0;
    if (this.geoTowerList.length !== 0){
      this.geoTowerList.forEach(layer => {
        if (layer.selected){
          layersCount++;
        }
      });
    }
    return layersCount;
  }

  getUserEmailsListByOrg(): any {
    this.geobaseService.getUserEmailsListByOrg(this.userInfo.type).subscribe(emailsList => {
      if (this.commonService.isValid(emailsList) && this.userInfo.type !== 'INDEPENDENT'){
        this.allUserOrOrgEmails = emailsList;
      } else {
        this.allUserOrOrgEmails = [];
      }
      this.orgUserEmailsToShow = [];
    }, error => {
      console.log(error);
      this.allUserOrOrgEmails = [];
      this.orgUserEmailsToShow = [];
    });
  }

  setSharingUserEmail(email): void{
    if (this.userEmailListString !== ''){
      this.userEmailListString = this.userEmailListString + email + ',';
    } else {
      this.userEmailListString = email + ',';
    }
    this.userEmail.setValue(this.userEmailListString);
    this.orgUserEmailsToShow = [];
  }

  onUserEmailType(event): void{
    const val: string = event.target.value;
    let newVal = [];
    if (val.includes(',')){
      newVal = val.split(',');
    }
    else { newVal.push(val); }
    if (this.commonService.isValid(val)){
      this.orgUserEmailsToShow = this.allUserOrOrgEmails.filter(email => {
        return email.indexOf(newVal[newVal.length - 1].toLowerCase()) !== -1;
      });
    } else {
      this.orgUserEmailsToShow = this.allUserOrOrgEmails;
    }
  }

  onRemoveFocusOfUserEmail(event): void{
    setTimeout(() => {
      this.resetOrgUsers();
    }, 500);
  }

  resetOrgUsers(): void{
    this.orgUserEmailsToShow = [];
  }

  shareSession(): any {
    this.errorMsg = '';
    let errorsFound = false;
    this.sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
    this.resetOrgUsers();
    try{
      if (this.userEmail.value !== ''){
        const userEmails = this.userEmail.value.split(',');
        if (userEmails.length !== 0){
          for (let i = 0; i < userEmails.length; i++){
            this.tempUserEmailFormControl = new FormControl(userEmails[i], Validators.email);
            if (this.commonService.isValid(userEmails[i])){
              if (this.globalObject.pageType === 'share') {
                throw new Error('Shared Session was not allowed to Share session');
              }
              if (!this.tempUserEmailFormControl.valid){
                throw new Error('Please add or select a valid Email ID');
              }
              if (this.userInfo.type === 'ORG'){
                const userIndex = this.allUserOrOrgEmails.findIndex(email => email === userEmails[i]);
                if (userIndex === -1){
                  throw new Error('Cannot share with a user/email not connected with this organization.');
                }
              } else if (this.userInfo.type === 'INDEPENDENT'){
                const userIndex = this.allUserOrOrgEmails.findIndex(email => email === userEmails[i]);
                if (userIndex !== -1){
                  throw new Error('Email belongs to other organization. Please choose another.');
                }
              }
            }
          }
        }
      }
      else { throw new Error('Please add or select a valid Email ID'); }
    } catch (e) {
      errorsFound = true;
      this.showError(e);
    }
    if (!errorsFound) {
      this.shareSessionAPI();
      this.selectedSessionSharingOption = SessionSharingOption.URL;
    }
  }

  shareSessionAPI(): any {
    const emailStr = this.userEmail.value;
    const emailsArray = emailStr.split(',');
    const emailList: any[] = [];
    emailsArray.forEach(email => {
      if (this.commonService.isValid(email)){
        if (this.geoSessionsList != null && this.geoSessionsList.length > 0){
          this.geoSessionsList.forEach(geobase => {
            if (Number(this.selectedSessionId) === Number(geobase.sessionId)){
              if (geobase.sessionShare != null) {
                if (email === geobase.sessionShare.recipientUserEmail){

                }
                else{
                  emailList.push(email);
                }
              }
              else if (geobase.sessionShareList != null && geobase.sessionShareList.length > 0){
                let count = 0;
                for (let j = 0; j < geobase.sessionShareList.length; j++){
                  if (email !== geobase.sessionShareList[j].recipientUserEmail){
                    count++;
                  }
                }
                if (count === geobase.sessionShareList.length){
                  emailList.push(email);
                }
              }
              else{
                emailList.push(email);
              }
            }
          });
        }

      }
    });
    const uuidValue = uuid.v4();
    this.link = 'https://fuse.earth/share/'
                + this.selectedSessionId;
    const requestGoebaseShare = {
      userEmailList : emailList,
      url : this.link,
      uuid : uuidValue,
    };
    this.sessionShareCurrentState = this.sessionSaveStates.STARTED;
    if (emailList.length > 0){
      this.geobaseService.createGeobaseShare(requestGoebaseShare, this.selectedSessionId)
      .subscribe(result => {
        this.sessionShareCurrentState = this.sessionSaveStates.COMPLETED;
        this.urlLink.nativeElement.value = this.link;
        setTimeout(() => {
          this.sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
        }, 5000);
        if (!this.commonService.isValid(result)) {
        } else {
        }
      }, error => {
        console.log(error);
        if (error.errorCode === 500) {
        }
        this.sessionShareCurrentState = this.sessionSaveStates.FAILED;
        setTimeout(() => {
          this.sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
        }, 5000);
      });
    }
    else{
      this.selectedSessionSharingOption = SessionSharingOption.URL;
      this.sessionShareCurrentState = this.sessionSaveStates.COMPLETED;
      setTimeout(() => {
        this.sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
      }, 5000);
      setTimeout(() => {
        this.urlLink.nativeElement.value = this.link;
      });
    }
  }

  getGeobaseListByFilter(selectedFilter: string, autoSelect: boolean = false): any {
    if (!this.isGuest) {
      this.selectedSessionCategory = selectedFilter;
      if (this.saveSessionOptionSelectedFrom === SessionSharingOption.UNKNOWN){
        this.selectedSessionSharingOption = SessionSharingOption.UNKNOWN;
      }
      if (!autoSelect){
        this.selectedSessionId = '';
        this.saveSessionOptionSelectedFrom = SessionSharingOption.UNKNOWN;
      }
      this.getGeobaseListByFilterProcess(selectedFilter, autoSelect);
    }
  }

  getGeobaseListByFilterProcess(selectedFilter, autoSelect: boolean = false): any {
    this.geoSessionsList = [];
    this.geoSessionDataColleced = false;
    this.geobaseService.getGeobasesListByType(selectedFilter, this.globalObject.geobase.sessionId)
    .subscribe(geobaseList => {
        let tempSessionsList: any[] = [];
        if (geobaseList.body.length > 0) {
          geobaseList.body.forEach(geobase => {
            let uuidValue = null;
            if (geobase.sessionShare != null) {
              uuidValue = geobase.sessionShare.uuid;
            }
            const session: any = geobase.session;
            session.id = session.sessionId;
            session.sessionId = String(session.sessionId);
            session.uuid = session.uuidValue;
            session.type = selectedFilter;
            session.showAllSites = false;
            session.sessionShare = geobase.sessionShare;
            session.sessionShareList = geobase.sessionShareList;
            tempSessionsList.push(session);
          });
        }
        if (selectedFilter === 'mySession'){
          this.defaultSession.sessionId = String(this.defaultSession.sessionId);
          this.defaultSession.id = 'running';
          this.defaultSession.name = 'Running';
          const currentSession: any = {};
          for (const key in this.defaultSession) {
            if (this.defaultSession.hasOwnProperty(key)) {
              currentSession[key] = this.defaultSession[key];
            }
          }
          currentSession.id = 'running';
          currentSession.sessionId = String(currentSession.sessionId);
          currentSession.uuid = null;
          currentSession.type = selectedFilter;
          currentSession.name = 'Running';
          currentSession.showAllSites = false;
          tempSessionsList = [currentSession].concat(tempSessionsList);
        } else {
          tempSessionsList = [].concat(tempSessionsList);
        }
        const index = tempSessionsList.findIndex(val => {
          return String(val.sessionId) === String(this.selectedSessionId) || String(val.id) === String(this.selectedSessionId);
        });
        if (index !== -1){
          this.sessionCtrl.setValue(tempSessionsList[index].id === 'running' ? tempSessionsList[index].id :
                                    this.selectedSessionId); // (this.selectedSessionId); // (tempSessionsList[index].sessionId);
        } else if (tempSessionsList.length > 0){
          if (this.commonService.isValid(this.selectedSessionId)){
            this.sessionCtrl.setValue(this.selectedSessionId);
          } else {
            this.sessionCtrl.setValue(tempSessionsList[0].sessionId || tempSessionsList[0].id);
          }
        } else {
        }
        this.currSessionsObj = {};
        tempSessionsList.forEach(element => {
          this.currSessionsObj[element.id] = element.name;
        });
        this.geoSessionsList = tempSessionsList;
        if (this.geoSessionsList.length === 0){
        }
        this.geoSessionDataColleced = true;
        if (this.saveSessionOptionSelectedFrom === this.sessionShareOptionEnum.URL){
          this.selectedSessionSharingOption = this.sessionShareOptionEnum.EMAIL;
        }
        else{
          this.selectedSessionSharingOption = this.saveSessionOptionSelectedFrom;
        }
        if (autoSelect && this.commonService.isValid(this.selectedSessionId)){
          this.sessionCategorySelect(this.selectedSessionId, autoSelect);
        }
    }, error => {
      this.geoSessionDataColleced = true;
      this.geoSessionsList = [];
      if (error.errorCode === 500) {
      }
    });
  }

  showAllGeobases(): any {
    this.geoSessionDataColleced = false;
    const includeDefaultGeobase = false;
    this.geoSessionsList = [];
    this.geobaseService.getGeobasesList(includeDefaultGeobase)
    .subscribe(geobaseList => {
      if (!this.commonService.isValid(geobaseList)) {
      } else {
        if (geobaseList.length > 0) {
          geobaseList.forEach(geobase => {
            this.geoSessionsList.push({ name: geobase.name, id: geobase.sessionId,
              uuid: null, type: 'myFiles', showAllSites: false });
          });
        }
      }
      this.currSessionsObj = {};
      this.geoSessionsList.forEach(element => {
        this.currSessionsObj[element.id] = element.name;
      });
      this.geoSessionDataColleced = true;
    }, error => {
      console.log(error);
      this.geoSessionDataColleced = true;
      this.geoSessionsList = [];
      if (error.errorCode === 500) {
      }
    });
  }

  sessionCategorySelect(sessionId, autoSelect: boolean = false): any{
    this.selectedSessionId = String(sessionId);
    if (this.saveSessionOptionSelectedFrom === SessionSharingOption.UNKNOWN){
      this.selectedSessionSharingOption = SessionSharingOption.UNKNOWN;
    }
    if (!autoSelect){
      this.saveSessionOptionSelectedFrom = SessionSharingOption.UNKNOWN;
    }
    this.selectAllSitesCheckbox.checked = false;
    this.errorMsg = '';
    const index = this.geoSessionsList.findIndex(val => String(val.id) === String(sessionId));
    if (index !== -1){
      if (this.commonService.isValid(this.geoSessionsList[index].sessionId)){
        const dataToEmit: any = {};
        for (const key in this.geoSessionsList[index]) {
          if (this.geoSessionsList[index].hasOwnProperty(key)) {
            dataToEmit[key] = this.geoSessionsList[index][key];
          }
        }
        dataToEmit.showAllSites = true;
        this.projectsDataCollected = false;
        this.placesDataCollected = false;
        this.topicsDataCollected = false;
        this.loadSession.emit(dataToEmit);
        const geobaseInfo = this.geoSessionsList[index];
        if (geobaseInfo.boundingBox !== null) {
          this.basemapService.getCurrentBasemap().getView().fit(geobaseInfo.boundingBox.map(Number));
        }
      }
    }
  }

  overrideSession(): any{
    this.showSessionOverrideAlert = false;
    this.copySelected = false;
    this.saveCurrentSession();
  }

  saveCurrentSession(): any {
    const selectedLayers = [];
    this.geoTowerList.forEach(layer => {
      if (layer.layerId === 0) {
        if (layer.type === 'zip') {
          const layerInfo = layer;
          layerInfo.workspaceName = this.towerWithLayersList[0].tower.name;
          layerInfo.datastoreName = 'datastore_' + this.towerWithLayersList[0].tower.name;
          layerInfo.towerId = this.towerWithLayersList[0].tower.towerId;
          this.multipartFiles.forEach(zipFile => {
            if (zipFile.fileName !== undefined) {
              if (zipFile.fileName === layer.name) {
                selectedLayers.push(layer);
              }
            }
          });
        } else {
          selectedLayers.push(layer);
        }
      }
    });
    let newGeopadId = this.defaultGeobaseInfo.geopadId;
    if (this.defaultGeobaseInfo.geopadId == null) {
      newGeopadId = 0;
    }
    const geobaseRequest = {
      geobaseId: this.selectedSessionId,
      sessionId: this.selectedSessionId,
      organizationId: this.defaultGeobaseInfo.organizationId,
      geopadId: newGeopadId,
      towerId: this.towerWithLayersList[0].tower.towerId,
      boundingBox: this.basemapService.getCurrentBasemap().getView().calculateExtent(this.basemapService.getCurrentBasemap().getSize()),
      status: 'Active',
      isDefault: this.defaultGeobaseInfo.isDefault,
      name: this.sessionNameInput.value,
      projectId: this.projectSelect.value, // this.projectId,
      placeId: this.placeSelect.value, // this.placeId,
      topicId: this.topicSelect.value, // this.topicId,
    };
    const towerLayerInfo = {
      towerInfo: this.towerWithLayersList[0].tower,
      layerInfoList: selectedLayers, // this.towerWithLayersList[0].listOfLayers,
    };
    this.sessionSaveCurrentState = this.sessionSaveStates.STARTED;
    this.layersService.saveMultipleLayers(towerLayerInfo, this.multipartFiles)
      .subscribe((results: HttpEvent<any>) => {
        if (results.type === HttpEventType.UploadProgress) {
          this.sessioSaveProgress = (100 * results.loaded / results.total).toFixed(2);
        }
        if (results.type === HttpEventType.Response) {
          if (!this.commonService.isValid(results)) {
          } else {
            geobaseRequest.towerId = results.body;
            this.geobaseService.updateDefaultGeobase(geobaseRequest, this.selectedSessionId)
                .subscribe(reponse => {
                  if (!this.commonService.isValid(reponse)) {
                  } else {
                    selectedLayers.forEach((selectedLayer) => {
                      this.geotowerService.clientObjList.forEach((obj, index) => {
                        if (obj.name === selectedLayer.name) {
                          this.geotowerService.clientObjList.splice(index, 1);
                        }
                      });
                      this.basemapService.getCurrentBasemap().getLayers().forEach(currentLayer => {
                        if (currentLayer !== undefined) {
                          if (selectedLayer.name === currentLayer.values_.name) {
                            this.basemapService.getCurrentBasemap().removeLayer(currentLayer);
                          }
                        }
                      });
                    });
                    this.geotowerService.deleteEventTowerRefresh();
                  }
                  this.sessionSaveCurrentState = this.sessionSaveStates.COMPLETED;
                  setTimeout(() => {
                    this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
                    this.sessioSaveProgress = '-1';
                  }, 5000);
                }, error => {
                  console.log(error);
                  if (error.errorCode === 500) {
                  }
                  this.sessionSaveCurrentState = this.sessionSaveStates.FAILED;
                  setTimeout(() => {
                    this.sessionShareCurrentState = this.sessionSaveStates.UNKNOWN;
                  }, 5000);
                });
            }
        }
      }, error => {
        console.log(error);
        if (error.errorCode === 500) {
        }
        this.sessionSaveCurrentState = this.sessionSaveStates.FAILED;
        setTimeout(() => {
          this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
        }, 5000);
      });
  }

  saveAsNewSession(): any {
    const newGeopadId = 0;
    const selectedLayers = [];
    const selectedSites = [];
    this.geoTowerList.forEach(layer => {
      if (layer.selected) { selectedLayers.push(layer); }
    });
    this.savedNotes.forEach(geopad => {
      if (geopad.selected) { selectedSites.push(geopad); }
    });

    const geopadObservationInstanceInfoJson = {
      geopadInfo: {
        geopadId: 0,
        name: 'geopad_'
      },
      observationInstanceInfoList: selectedSites
    };
    const geobaseRequest = {
      sessionId: 0,
      organizationId: 1,
      geopadId: newGeopadId,
      towerId: this.towerWithLayersList[0].tower.towerId,
      boundingBox: this.basemapService.getCurrentBasemap().getView().calculateExtent(this.basemapService.getCurrentBasemap().getSize()),
      status: 'Active',
      isDefault: false,
      name: this.sessionNameInput.value,
      projectId: this.projectSelect.value, // this.projectId,
      placeId: this.placeSelect.value, // this.placeId,
      topicId: this.topicSelect.value, // this.topicId,
      geopadObservationInstanceInfo: geopadObservationInstanceInfoJson,
    };
    const towerLayerInfo = {
      towerInfo: {
        towerId: 0,
        name: 'tower_'
      },
      layerInfoList: selectedLayers, // this.towerWithLayersList[0].listOfLayers,
    };
    // here changes are saving the multiple layers first then save the geobase only
    this.sessionSaveCurrentState = this.sessionSaveStates.STARTED;
    this.layersService.saveMultipleLayers(towerLayerInfo, this.multipartFiles)
      .subscribe((results: HttpEvent<any>) => {
          if (results.type === HttpEventType.UploadProgress) {
            this.sessioSaveProgress = (100 * results.loaded / results.total).toFixed(2);
          }
          if (results.type === HttpEventType.Response) {
            if (!this.commonService.isValid(results)) {
            } else {
              geobaseRequest.towerId = results.body;
              this.geobaseService.createNewGeobase(geobaseRequest)
                  .subscribe(response => {
                    if (!this.commonService.isValid(response)) {
                    } else {
                    }
                    this.sessionSaveCurrentState = this.sessionSaveStates.COMPLETED;
                    setTimeout(() => {
                      this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
                      this.sessioSaveProgress = '-1';
                    }, 5000);
                    this.selectedSessionId = String(response.body);
                    this.getGeobaseListByFilter(this.sessionCategoryCtrl.value, true);
                  }, error => {
                    console.log(error);
                    if (error.errorCode === 500) {
                    }
                    this.sessionSaveCurrentState = this.sessionSaveStates.FAILED;
                    setTimeout(() => {
                      this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
                    }, 5000);
                  });
          }
        }
      }, error => {
        console.log(error);
        if (error.errorCode === 500) {
        }
        this.sessionSaveCurrentState = this.sessionSaveStates.FAILED;
        setTimeout(() => {
          this.sessionSaveCurrentState = this.sessionSaveStates.UNKNOWN;
        }, 5000);
      });
  }

  copySessionLinkToClipboard(): void{
    const copyText = this.urlLink.nativeElement;
    if (this.commonService.isValid(copyText.value)) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copyToClipboard = 'Session URL copied to clipboard !';
      this.urlCopiedToClipboard = true;
      setTimeout(() => {
        this.copyToClipboard = '';
        this.urlCopiedToClipboard = false;
      }, 5000);
    } else {
      this.showError('Nothing present to copy.');
      this.urlCopiedToClipboard = false;
      setTimeout(() => {
        this.errorMsg = '';
      }, 5000);
    }
  }

  makeCopyOfSession(): any{
    const sitesCount = this.getCountOfSelectedSites();
    const layersCount = this.getCountOfSelectedLayers();
    if (sitesCount === 0 && layersCount === 0){
        this.showSessionSaveAlert = true;
        return;
      }
      else { this.showSessionNameInput(); }
  }

  showSessionNameInput(): any{
    this.showSessionSaveAlert = false;
    if (this.selectedSessionId === ''){
      this.showError('Please select session to copy.');
    } else {
      this.copySelected = true;
      this.getGeobaseListByFilterProcess('mySession');
      this.geobaseService.getGeobasesListByType('mySession', this.globalObject.geobase.sessionId)
      .subscribe(geobaseList => {
        let count = 0;
        let dateStr = '';
        const date: Date = new Date();
        dateStr = String(date.getFullYear()).substring(2) + '' + (date.getMonth() + 1) + '' + date.getDate();
        if (geobaseList.body.length > 0) {
          geobaseList.body.forEach(geobase => {
          if (geobase.session.name.includes(dateStr)){
            count++;
          }
          });
        }
        const suggestedSesionName = 'FE-' + this.userProfileData.user.userId + '-' + dateStr + '-' + (count + 1);
        this.sessionNameInput = new FormControl(suggestedSesionName);
        this.geoSessionDataColleced = true;
      }, error => {
        console.log(error);
      });
    }
  }

  saveSessionWithBoundingBox(): any {
    this.showSessionNameInput();
    this.showSessionShareAlert = false;
    this.showSessionSaveAlert = false;
  }

  selectedSitesBBox(): any {
  }
  showalert(){
    alert("Susbscribe for a higher package to use this feature")
  }
}
