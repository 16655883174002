import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import OlMap from 'ol/Map';
import { BasemapService } from '../basemap/basemap.service';
import { AuthObservableService } from '../Services/authObservableService';
import { CommonService } from '../Services/common.service';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { get as getProjection } from 'ol/proj.js';
declare var google: any;
const proj4326 = getProjection('EPSG:4326');
import { transform } from 'ol/proj';
import { getBottomLeft } from 'ol/extent';
import { getTopRight } from 'ol/extent';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit, AfterViewInit {

  basemap: OlMap;
  currentUploadLayerName = 'Upload Status';
  currentUploadProgress = 0;


  leftDisabled = false;
  rightDisabled = false;
  leftArrowHide = false;
  rightArrowHide = false;
  scrollDivId = 'scrollDivId';
  // curserPosition: Array<string> = [];
  curserPosition: any;
  elevationVal: Array<string> = [];
  markedLocationCoOrds: Array<string> = [];
  showCoOrdsCopiedMsg: boolean;
  northRotateValue = '0';
  isGuest = true;
  activeBaseLayerName = 'Open Street';
  currentReferenceSystem = '';
  currentViewFactory = '';
  termsShown: boolean;
  tcAndPrivacyActivePage = 'tc';
  orgName = '';
  currentUploadStatus = '';
  @ViewChild('appInfoContent') appInfoContentDiv: HTMLElement;
  sourceproj: any;
  // @Input() exportcordsysname: string;
  // @Input() emitId;
  id: string;
  constructor(private basemapService: BasemapService, private authObsr: AuthObservableService,
    private router: Router,
    private commonService: CommonService, private modalService: NgbModal,) {

    if (this.commonService.isValid(localStorage.getItem('token'))) {
      this.isGuest = false;
    } else {
      this.isGuest = true;
    }
    this.authObsr.subscribeForAuthStatus('NotificationBarComponent', (authRes, msg) => {
      if (authRes.status === 'success') {
        this.isGuest = false;
      } else if (authRes.status === 'failed') {
        this.isGuest = true;
      }
    });
    this.authObsr.subscribeForLayerUploadStatus('NotificationBarComponent', (data) => {
      console.log('RECEIVED Layer STATUS...');
      console.log(data);
      if (this.currentUploadStatus !== 'DUPLICATE') {
        this.currentUploadProgress = Number(data.progress);
        console.log('STATUS : ', this.currentUploadStatus);
        if (String(data.status).toLowerCase().includes('duplicate')) {
          this.currentUploadStatus = 'DUPLICATE';
          this.currentUploadProgress = 0;
          setTimeout(() => {
            this.currentUploadStatus = '';
          }, 10000);
        }
      }

    });
    this.authObsr.subscribeToGetBaseLayerName('NotificationBarComponent', (data) => {
      // console.log('RECEIVED Base layer name...');
      // console.log(data);
      this.activeBaseLayerName = data;
    });
    this.authObsr.subscribeToGetReferenceSystem('NotificationBarComponent', (data) => {
      // console.log('RECEIVED reference system name...');
      // console.log(data);
      this.currentReferenceSystem = data.name;
    });

    this.authObsr.subscribeToGetViewFactory('NotificationBarComponent', (data) => {
      // console.log('RECEIVED View factory name...');
      // console.log(data);
      this.currentViewFactory = data;
    });

    this.authObsr.subscribeForOrgName('NotificationBarComponent', (data) => {
      // console.log('RECEIVED View factory name...');
      // console.log(data);
      // this.orgName = '(' + data + ')';
      this.orgName = data;
    });

    this.authObsr.subscribeForTermsPage('NotificationBarComponent', (data) => {
      // console.log('RECEIVED subscribeForTermsPage...');
      // console.log(data);
      if (data === 'terms') {
        this.tcAndPrivacyActivePage = 'tc';
      } else {
        this.tcAndPrivacyActivePage = 'privacy';
      }
      this.showApplicationTerms('', this.appInfoContentDiv);
    });
  }

  ngOnInit(): void {
    this.basemap = this.basemapService.getCurrentBasemap();
    console.log('Subscribed for pointer move...');

    this.basemap.getView().on('change:rotation', () => {
      // console.log('fired ', this.basemap.getView().getRotation());
      try {
        this.northRotateValue = ((this.basemap.getView().getRotation()) / (Math.PI / 180)).toFixed(2);
      } catch (e) {
        console.log(e);
      }
    });

    this.basemap.on('pointermove', (evt) => {
      var lonlat;
      var coord = [
        evt.coordinate[0].toFixed(2),
        evt.coordinate[1].toFixed(2),
        // evt.coordinate[0].toFixed(4),
        // evt.coordinate[1].toFixed(4),
      ];
      this.curserPosition = coord;
      if (this.basemapService.getCurrentBasemap().getView().getProjection() != 'EPSG:4326') {
        lonlat = this.basemapService.getTransformedCoordinates([evt.coordinate[0], evt.coordinate[1]], this.basemapService.getCurrentBasemap().getView().getProjection(), getProjection('EPSG:4326'));
      }
      else {
        lonlat = [evt.coordinate[0], evt.coordinate[1]]
      }
      this.curserPosition = [lonlat[0].toFixed(2), lonlat[1].toFixed(2)];
      // if (this.basemap.getView().getZoom() > 9.5) {
      //   fetch(`https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lonlat[0]},${lonlat[1]}.json?layers=contour&access_token=${"sk.eyJ1IjoiZ2VvbW9jdXMiLCJhIjoiY2x0ZTM2NmxoMGI4ZTJpcWxzaWVvcDN4bSJ9.HXHysxzwu0W3lMJvz2BLUw"}`)
      //     .then(response => response.json())
      //     .then(data => {
      //       console.log(data)
      //       // Extract elevation (z) from the response
      //       if (data.features !== undefined) {
      //         const elevation = data.features[0].properties.ele;
      //         this.elevationVal = elevation
      //       }
      //     })
      //     .catch(error => {
      //       console.error('Error fetching elevation:', error);
      //     });
      // }
      // else {
      //   this.elevationVal = [];
      // }

      // this.curserPosition = `${coord[0]}, ${coord[1]}`;

      //console.log("check coord",coord);
      //   var stringArray = [];

      //  var source = this.basemapService.getSourceProjection;
      //   var test = this.currentReferenceSystem;
      //   console.log(test, 'testtt');
      //   var sourceCrs = getProjection(test);
      //   console.log(sourceCrs, 'checksourceprojection');
      //   var projection = this.sourceproj

      //   const transformed_Coordinates =
      //     this.basemapService.getTransformedCoordinates(
      //       coord,
      //       sourceCrs,  
      //       proj4326
      //     );
      //         console.log(sourceCrs, 'checksourceproj');

      //   console.log(transformed_Coordinates, 'checktransformedcoord');
      //   console.log(transformed_Coordinates, coord, 'checkdiff');
      //   for (let i = 0; i < transformed_Coordinates.length - 1; i++) {
      //     stringArray.push(
      //       `${transformed_Coordinates[1].toFixed(4)}`,
      //       `${transformed_Coordinates[0].toFixed(4)}`
      //     );
      //   }
      //   console.log(stringArray, coord, 'checkstringarrAY');

      //   // New code of Elevation Service
      //   const elevator = new google.maps.ElevationService();
      //   const location = new google.maps.LatLng(
      //     stringArray[0],
      //     stringArray[1]
      //     // coord[1],
      //     // coord[0]
      //   ); // { "lat": 39.7391536, "lng": -104.9847034 }
      //   console.log('checkeleval', location, coord);
      //   elevator
      //     .getElevationForLocations({
      //       locations: [location],
      //     })

      //     .then(({ results }) => {
      //       console.log('locationrresults. ', results);
      //       // Retrieve the first result
      //       if (results[0]) {
      //         // Open the infowindow indicating the elevation at the clicked position.
      //         console.log(
      //           'The elevation at this point is ' + results[0].elevation
      //         );
      //         this.elevationVal = results[0].elevation.toFixed(2);
      //         return results;
      //       } else {
      //         console.log('No results found');
      //       }
      //     })
      //     .catch((e) => console.log('Elevation service failed due to: ' + e));
    });

    this.basemap.on('contextmenu', (evt) => {
      console.log(evt);
      this.copyCoOrds(evt);
      evt.preventDefault();
    });
  }


  // emittedId(e){
  //   this.id = e;
  //   console.log(this.id,"checkidofangrect")
  // }

  copyCoOrds(evt): void {
    // this.id = this.emitId;
    // console.log(this.emitId, 'id in notification')
    console.log(evt, "check the evt")
    console.log(this.basemapService.getCurrentBasemap().getView().getProjection().code_, 'projectttion')
    if (this.basemapService.getCurrentBasemap().getView().getProjection().code_ === 'EPSG:4326') {

      var coord = [evt.coordinate[1].toFixed(4), evt.coordinate[0].toFixed(4)];
      console.log('checkcopycords', this.id);
      // const coord = [evt.coordinate[1].toFixed(4), evt.coordinate[0].toFixed(4)];
      // console.log('checkcopycords', this.id);
    }
    else {
      const transformed_Coordinates = this.basemapService.getTransformedCoordinates(evt.coordinate, this.basemapService.getCurrentBasemap().getView().getProjection().code_, "EPSG:4326");
      // var coord = [evt.coordinate[0].toFixed(4), evt.coordinate[1].toFixed(4)];
      var coord = [transformed_Coordinates[1].toFixed(4), transformed_Coordinates[0].toFixed(4)];
      console.log('checkcopycords', this.id, transformed_Coordinates);
    }

    if (this.basemap.getView().getZoom() > 9.5) {
      // fetch(`https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${coord[1]},${coord[0]}.json?layers=contour&access_token=${"sk.eyJ1IjoiZ2VvbW9jdXMiLCJhIjoiY2x0ZTM2NmxoMGI4ZTJpcWxzaWVvcDN4bSJ9.HXHysxzwu0W3lMJvz2BLUw"}`)
      // // fetch(`https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${coord[0]},${coord[1]}.json?layers=contour&access_token=${"pk.eyJ1IjoiZ2lza2VybmVsIiwiYSI6ImNqcHduOHV2azBhNDg0OW1nc3FmOGZ0NGgifQ.DJYSG726Ye5Dh9nv42CsEw"}`)
      //   .then(response => response.json())
      //   .then(data => {
      //     if(data.features !== undefined) {
      //       const elevation = data.features[0].properties.ele;
      //       coord.push(elevation)
      //       if (
      //         this.commonService.isValid(document.getElementById('coOrdCopyOnclickMap'))
      //       ) {
      //         document.getElementById('coOrdCopyOnclickMap').remove();
      //       }
      //       const p = document.createElement('input');
      //       p.id = 'coOrdCopyOnclickMap';
      //       p.type = 'text';
      //       // p.setAttribute('style', 'display: none');
      //       p.value = coord.toString();
      //       document.body.appendChild(p);
      //       p.select();
      //       p.setSelectionRange(0, 99999);
      //       document.execCommand('copy');
      //       // p.remove();
      //       this.markedLocationCoOrds = coord;
      //       this.showCoOrdsCopiedMsg = true;
      //       setTimeout(() => {
      //         this.showCoOrdsCopiedMsg = false;
      //       }, 3000);
      //     }          
      // })
    }
    else {
      // if (
      //   this.commonService.isValid(document.getElementById('coOrdCopyOnclickMap'))
      // ) {
      //   document.getElementById('coOrdCopyOnclickMap').remove();
      // }
      // const p = document.createElement('input');
      // p.id = 'coOrdCopyOnclickMap';
      // p.type = 'text';
      // // p.setAttribute('style', 'display: none');
      // p.value = coord.toString();
      // document.body.appendChild(p);
      // p.select();
      // p.setSelectionRange(0, 99999);
      // document.execCommand('copy');
      // // p.remove();
      // this.markedLocationCoOrds = coord;
      // this.showCoOrdsCopiedMsg = true;
      // setTimeout(() => {
      //   this.showCoOrdsCopiedMsg = false;
      // }, 3000);
    }
    if (this.commonService.isValid(document.getElementById('coOrdCopyOnclickMap'))) {
      document.getElementById('coOrdCopyOnclickMap').remove();
    }
    const coords = evt.coordinate;
    const lonLat = transform(coords, this.basemapService.getCurrentBasemap().getView().getProjection().code_, 'EPSG:4326');
    // const lonLat = coord;
    console.log("lat:", lonLat[1], "lon:" , lonLat[0]);
    let lat = lonLat[1];
    let lon = lonLat[0];
    let ext = this.getExtent();
    let xmin, ymin, xmax, ymax;
    xmin =ext.xmin
    ymin= ext.ymin
    xmax= ext.xmax
    ymax = ext.ymax
    const elevationValue = this.getElevation(lon, lat, xmin, ymin, xmax,ymax, coord);
    if(elevationValue === undefined) {
      const p = document.createElement('input');
        p.id = 'coOrdCopyOnclickMap';
        p.type = 'text';
        p.value = coord.toString();
        this.markedLocationCoOrds = coord;
      document.body.appendChild(p);
        p.select();
        p.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.showCoOrdsCopiedMsg = true;
        setTimeout(() => {
          this.showCoOrdsCopiedMsg = false;
        }, 3000);
    }
    console.log(elevationValue, ext)
  }
  getExtent() {
    const extent = this.basemapService.getCurrentBasemap().getView().calculateExtent(this.basemapService.getCurrentBasemap().getSize());
    const bottomLeft = transform(getBottomLeft(extent), this.basemapService.getCurrentBasemap().getView().getProjection().code_, 'EPSG:4326');
    const topRight = transform(getTopRight(extent), this.basemapService.getCurrentBasemap().getView().getProjection().code_, 'EPSG:4326');
    // const bottomLeft = getBottomLeft(extent);
    // const topRight = getTopRight(extent);
    const xmin = bottomLeft[0]
    const ymin = bottomLeft[1]
    const xmax = topRight[0]
    const ymax = topRight[1]
    console.log("xmin:", bottomLeft[0], "ymin:", bottomLeft[1], "xmax:", topRight[0], "ymax:", topRight[1]);
    return { xmin, ymin, xmax, ymax }
  }
  getElevation(lon, lat, xmin, ymin, xmax, ymax, coord) {
    fetch(`https://geoserver.fuse.earth:4433/srtmelv?longitude=${lon}&latitude=${lat}&xmin=${xmin}&ymin=${ymin}&xmax=${xmax}&ymax=${ymax}`)
      .then((response) => response.text())
      .then((result) => {
        console.log(result, JSON.parse(result), JSON.parse(result).elevation);
        // this.markedLocationCoOrds.push(result['elevation'])
        const p = document.createElement('input');
        p.id = 'coOrdCopyOnclickMap';
        p.type = 'text';
        // p.setAttribute('style', 'display: none');
        p.value = coord.toString();
        // document.body.appendChild(p);
        // p.select();
        // p.setSelectionRange(0, 99999);
        // document.execCommand('copy');
        // p.remove();
        this.markedLocationCoOrds = coord;
        if(JSON.parse(result).elevation !==  'No data found') {
          // console.log(localStorage.getItem('unit'))
          if(localStorage.getItem('unit') === 'metric') {
            this.markedLocationCoOrds.push(JSON.parse(result).elevation);
            p.value = coord.toString();
          } else if(localStorage.getItem('unit') === 'us') {
            const eleNumber: number = +(JSON.parse(result).elevation);
            this.markedLocationCoOrds.push(String((eleNumber / 0.3048).toFixed(4)));
            p.value = coord.toString();
          } else {
            const eleNumber: number = +(JSON.parse(result).elevation);
            this.markedLocationCoOrds.push(String((eleNumber / 0.3048).toFixed(4)));
            p.value = coord.toString();
          }
        }
        document.body.appendChild(p);
        p.select();
        p.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.showCoOrdsCopiedMsg = true;
        setTimeout(() => {
          this.showCoOrdsCopiedMsg = false;
        }, 3000);
      })
      .catch((error) => console.error(error));
  }

  ngAfterViewInit(): void {
    this.showOrHideArrows();
  }

  scrollLeft(e): void {
    // console.log("scrollLeft");
    const elmnt = document.getElementById(this.scrollDivId);
    const totalWidth = elmnt.scrollWidth;
    const currentWidth = document.getElementById(this.scrollDivId).clientWidth; // $('#'+this.scrollDivId).width();
    const curr = document.getElementById(this.scrollDivId).scrollLeft; // $('#'+this.scrollDivId).scrollLeft();
    let scrollWidth = 0;
    if (totalWidth - currentWidth >= 100) {
      scrollWidth = 100;
    } else {
      scrollWidth = totalWidth - currentWidth;
    }
    // console.log("WIDTH : ",currentWidth);
    // console.log("SCROLL WIDTH : ",scrollWidth);
    if (curr === 0) {
      /// disable this button
      this.leftDisabled = true;
    } else {
      this.leftDisabled = false;
      // $('#'+this.scrollDivId).scrollLeft(curr-100); //$('#scrollDivId').scrollLeft(curr-100);
      document.getElementById(this.scrollDivId).scrollLeft = curr - 100;
      // $('#scrollDivId').animate({scrollLeft:curr-100},500);
      // curr =  $('#scrollDivId').scrollLeft();
      this.enableDisableLeftArrow();
    }
    this.enableDisableRightArrow();
    // console.log("LEFT DISABLED : ",this.leftDisabled);
    // console.log("RIGHT DISABLED : ",this.rightDisabled);
  }
  enableDisableLeftArrow(): void {
    // if($('#scrollDivId').scrollLeft()<=3)
    // if($('#'+this.scrollDivId).scrollLeft()<=3)
    if (document.getElementById(this.scrollDivId).scrollLeft <= 3) {
      this.leftDisabled = true;
    } else {
      this.leftDisabled = false;
    }
  }
  scrollRight(e): void {
    // console.log("scrollRight");
    let elmnt;
    elmnt = document.getElementById(this.scrollDivId);
    const totalWidth = elmnt.scrollWidth;
    const currentWidth = document.getElementById(this.scrollDivId).clientWidth; // $('#'+this.scrollDivId).width()

    const curr = document.getElementById(this.scrollDivId).scrollLeft; // $('#'+this.scrollDivId).scrollLeft();
    // console.log("WIDTH : ",currentWidth);

    // console.log("CURR : ",curr);
    let scrollWidth = 0;
    if (totalWidth - (currentWidth + curr) >= 100) {
      scrollWidth = 100;
    } else if (totalWidth - (currentWidth + curr) < 100) {
      scrollWidth = totalWidth - (currentWidth + curr);
    }
    if (totalWidth - (currentWidth + curr) <= 5) {
      // console.log("WIDTH VERY SMALL");
      // disable this button
      this.rightDisabled = true;
    } else {
      // console.log("WIDTH LARGE");
      this.rightDisabled = false;
      // $('#'+this.scrollDivId).scrollLeft(curr+100);
      document.getElementById(this.scrollDivId).scrollLeft = curr + 100;
      // $('#scrollDivId').animate({scrollLeft:curr+100},500);
      // curr =  $('#scrollDivId').scrollLeft();
      this.enableDisableRightArrow();
    }
    this.enableDisableLeftArrow();
    // console.log("LEFT DISABLED : ",this.leftDisabled);
    // console.log("RIGHT DISABLED : ",this.rightDisabled);
  }
  functionClick(e) {
    // localStorage.setItem(`projCode`,`EPSG:${e}`)
    var projectioncodee = `${`EPSG:${e}`}`
    console.log(projectioncodee, "fdghhhhhhh")
    this.sourceproj = getProjection(projectioncodee)
    console.log(this.sourceproj, 'checkevalue')
  }

  enableDisableRightArrow(): void {
    let elmnt;
    // elmnt = document.getElementById("scrollDivId");
    elmnt = document.getElementById(this.scrollDivId);
    const totalWidth = elmnt.scrollWidth;
    const currentWidth = document.getElementById(this.scrollDivId).clientWidth; // $('#'+this.scrollDivId).width()

    const curr = document.getElementById(this.scrollDivId).scrollLeft;  // $('#'+this.scrollDivId).scrollLeft();
    // console.log("WIDTH : ",currentWidth);

    // console.log("CURR : ",curr);
    let scrollWidth = 0;
    if (totalWidth - (currentWidth + curr) >= 100) {
      scrollWidth = 100;
    } else if (totalWidth - (currentWidth + curr) < 100) {
      scrollWidth = totalWidth - (currentWidth + curr);
    }
    if (totalWidth - (currentWidth + curr) <= 5) {
      this.rightDisabled = true;
    } else {
      this.rightDisabled = false;
    }
  }
  showOrHideArrows(): void {
    this.enableDisableLeftArrow();
    this.enableDisableRightArrow();
    // console.log("LEFT DISABLED : ",this.leftDisabled);
    // console.log("RIGHT DISABLED : ",this.rightDisabled);
    if (this.leftDisabled && this.rightDisabled) {
      this.leftArrowHide = true;
      this.rightArrowHide = true;
    } else {
      this.leftArrowHide = false;
      this.rightArrowHide = false;
    }
    document.getElementById(this.scrollDivId).scrollLeft = 0;
  }
  @HostListener('window:resize', ['$event'])
  onResize(e): void {
    this.showOrHideArrows();
  }


  showApplicationTerms(event, content): any {
    this.termsShown = !this.termsShown; // true;
    console.log(this.router.url);
    if (this.termsShown) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(reason);
        console.log(this.router.url);
        let url = this.router.url;
        if (url.includes('#')) {
          url = url.substr(0, url.lastIndexOf('#'));
        }
        this.router.navigate([url], { replaceUrl: true });
        this.termsShown = false;
        // this.resetSelectedOption();
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.modalService.dismissAll();
    }
  }


}
