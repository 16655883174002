import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SocialAuthService } from 'src/app/Services/socialAuthService';
import { FeAdminDashboardService } from 'src/app/Services/feAdminDashborad.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dashboardVisible: boolean = true;
  PackageVisible: boolean | undefined;
  UserPackageVisible: boolean | undefined;
  visibleProfileDropdown: boolean | undefined;
  showPackageTable: boolean | undefined;
  AddUserPackageControlVisible: boolean | undefined;
  AddPackageVisible: boolean | undefined;
  showPackageFirstScreen: boolean | undefined;
  AddPackagePopupForm!: FormGroup;
  AddUserForm!: FormGroup;
  AddModuleForm!: FormGroup;
  AddSubModuleForm!: FormGroup;
  PackageForm!: FormGroup;
  customSelectedRightsArray: Array<any> = [];
  packageNameSelected = "Administrator";
  editUserEmail: any[] = [];
  showdeletepackage: boolean | undefined;
  addmodulepopup: boolean | undefined;
  addsubmodulepopup: boolean | undefined;
  GeobarOptions: any[] = [];
  GeotowerOptions: any[] = [];
  idarray: any[] = [];
  geobararray: any[] = [];
  geotowerarray: any[] = [];
  addModulearray: any[] = [];
  packageNames = [];
  PackageName!: string;
  statusSelected = "Active";
  status = [
    { value: 'Active', label: 'Active' },
    { value: 'InActive', label: 'InActive' }

  ];
  Array!: string;
  packages: any[] = [];
  AddUserFormArray: any;
  PackageFormArray: any;
  AddModuleFormArray: any;
  AddSubModuleFormArray: any;
  showdeleteuser: boolean | undefined;
  edituserpacakagecontrolvisible: boolean | undefined;
  showSavePackageIcon: boolean | undefined;
  showDeletePackageIcon: boolean | undefined;
  modulecheck: boolean | undefined;
  selectallcheck: boolean | undefined;
  packagearray: any[] = [];
  allFeaturesArray: any[] = [];
  allGeobarArray: any[] = [];
  allAddedModulearray: any[] = [];
  PackageDetails: any[] = [];
  packagedetail: any[] = [];
  packagegeobar: any[] = [];
  packagegetower: any[] = [];
  packageModuleAll: any[] = [];
  packageGeobarAll: any[] = [];
  packageGeotowerAll: any[] = [];
  selectedPackage = 'Select Package';
  packagedetailarray!: { PackageName: any; PackagePrice: any; PackageFeatures: any[]; };
  PackageDetailsTableVisible: boolean | undefined;
  PackagePrice: any;
  packagepricee: any;
  checkedModules: any[] = [];
  ModuleName: any;
  SubModuleName: any[] = [];
  addedmodulevisible: boolean = true;
  addSubModulein: any;
  modules = [];
  moduleLength;
  emailPopup;
  orgPopup;
  startDatePopup;
  endDatePopup;
  selecteddefaultpackage: any;
  defaultpackagedata: any;
  individulauserbutton: boolean;

  closeAddUserPopup() {
    this.AddUserPackageControlVisible = false;
  }

  closeEditUserPopup() {
    this.edituserpacakagecontrolvisible = false;
  }
  users: any[] = [];
  packageId: any[] = [];
  selectedRecord: any;
  packageResponseData: any[] = []
  constructor(private router: Router, private formBuilder: FormBuilder,
    private AddUserformBuilder: FormBuilder, private PackageFormBuilder: FormBuilder,
    private AddModuleformBuilder: FormBuilder, private AddSubModuleformBuilder: FormBuilder,
    private socialAuth: SocialAuthService, private feAdminService: FeAdminDashboardService, private http: HttpClient) { }

  ngOnInit(): void {

    // rajini ---- API calling
    const apiUrl = 'https://fuse.earth:8443/fusedotearth-v2/api/guest/v2/subscriptions/features/relations'; // Replace with your API endpoint URL
    this.http.get(apiUrl).subscribe((response) => {
      console.log(response);
      this.defaultpackagedata = response // Process the received data here
    }, (error) => {
      console.error(error); // Handle any error that occurs
    });
    // get package dropdown list
    this.feAdminService.getPackageList().subscribe(response => {
      console.log(response);
      this.packages.push("Select package")
      response.forEach(packageData => {
        this.packages.push(packageData.name);
        this.packageId.push(packageData.packageId);
        this.packageResponseData.push(packageData);
      });
    });
    // get Organization data
    this.getOrgSubscriptionListService(false);
    // get feature and modules list
    this.feAdminService.getFeaturesList().subscribe(result => {
      console.log('what is list here? ', result);
      let res = [];
      result.map(function (item, index) {
        var existItem = res.find(x => x.name == item.module);
        const moduleData = {
          value: [item.module],
          data: [{ value: item.name, label: 'backendName', checked: false, id: item.packageItemId }],
          name: item.module,
          id: item.packageItemId
        }
        if (existItem) {
          res.filter(y => y.name == item.module)[0].data.push({ value: item.name, label: 'backendName', checked: false, id: item.packageItemId })
          console.log("item already exist");
        }
        else {
          res.push(moduleData);
        }
      });
      console.log(res);
      this.modules = res;
      this.moduleLength = result.length;
    });
    this.showdeleteuser = false;

    this.AddPackagePopupForm = this.formBuilder.group({
      PackageName: ['', Validators.required]
    });


    console.log(this.AddUserForm, "form")
    this.PackageForm = this.PackageFormBuilder.group({
      PackageName: ['', Validators.required],
      PackagePrice: ['', Validators.required]
    });

    this.AddModuleForm = this.AddModuleformBuilder.group({
      ModuleName: ['', Validators.required],
      SubModuleName: ['', Validators.required]
    })

    this.AddSubModuleForm = this.AddSubModuleformBuilder.group({
      SubModule: ['', Validators.required]
    })
  }
  getOrgSubscriptionListService(isIndividualUser): any {
    this.users = [];
    console.log(isIndividualUser, "isIndividualUser")
    this.feAdminService.getOrgPackagesDetails(isIndividualUser).subscribe(response => {
      console.log(response);
      // parsing the response
      response.forEach(result => {
        let pName, startDate, endDate, status, pId;
        if (result.packageInfo === null) {
          pName = this.packages[0];
          pId = this.packageId[0]
        } else {
          pName = result.packageInfo.name;
          pId = result.packageInfo.packageId;
        }
        if (result.orgPackageRela === null) {
          startDate = '';
          endDate = '';
          status = '';
        } else {
          startDate = result.orgPackageRela.startDate,
            endDate = result.orgPackageRela.endDate,
            status = result.orgPackageRela.status
        }
        const data = {
          Email: result.userInfo.email,
          Organization: result.orgInfo.name,
          Package: pName,
          StartDate: startDate,
          EndDate: endDate,
          Status: status,
          pId: pId,
          orgId: result.orgInfo.organizationId
        }
        this.users.push(data);
      });
    });
  }
  isIndividualUser(event): any {
    console.log(event.target.checked)
    if (event.target.checked) {
      this.individulauserbutton = true;
      // call only individual users list
      this.getOrgSubscriptionListService(true);
    } else {
      this.individulauserbutton = false;
      // only organization user list
      this.getOrgSubscriptionListService(false);
    }
  }

  saveFeatureAndModuleAPI(featureName, moduleName): any {
    this.feAdminService.saveFeature(featureName, moduleName).subscribe(response => {
      console.log('saving feature and module response is ', response);
    });
  }
  savePackageAndFeaturesAPI(packageInfo): any {
    this.feAdminService.savePackageList(packageInfo).subscribe(response => {
      console.log('saving package and feature and module response is ', response);
      this.PackageForm.reset();
      this.modulecheck = false;
      this.selectallcheck = false;
      this.packagedetail = []
      this.AddPackageVisible = false;
      this.showPackageFirstScreen = false;
      this.showSavePackageIcon = false;
      this.showDeletePackageIcon = false;
      this.showPackageTable = false;
    });
  }

  savePackage() {
    if (this.PackageForm.invalid) {
      return;
    }
    this.PackageFormArray = this.PackageForm.value;
    this.packagepricee = +this.PackageFormArray.PackagePrice
    this.packagedetailarray = {
      PackageName: this.PackageFormArray.PackageName,
      PackagePrice: this.PackageFormArray.PackagePrice,
      PackageFeatures: this.packagedetail
    }
    this.PackageDetails.push(this.packagedetailarray)
    this.packages.push(this.packagedetailarray.PackageName)
    console.log(this.PackageDetails, "PACKAGE DETAILS")
    let packageItemRelationList = [];
    this.modules.map((data) => {
      data.data.forEach(item => {
        if (this.PackageDetails[0]['PackageFeatures'][0] !== undefined) {
          var existItem = this.PackageDetails[0]['PackageFeatures'].find(x => x == item.value);
          if (existItem) {
            const pkgItem = {
              packageItemId: item.id,
              isAccess: true
            }
            packageItemRelationList.push(pkgItem);
          }
        }
      });
    });
    const packageRequest = {
      name: this.PackageDetails[0].PackageName,
      amount: this.PackageDetails[0].PackagePrice,
      packageItemRelationList: packageItemRelationList
    }
    this.savePackageAndFeaturesAPI(packageRequest);
  }
  showDeletePackagePopup() {
    this.showdeletepackage = true;
  }
  deletePackage() {
    this.showdeletepackage = false;
    for (let i = 0; i < this.modules.length; i++) {
      for (let j = 0; j < this.modules[i].data.length; j++) {
        this.modules[i].checked = false;
        this.modules[i].data[j].checked = false;
      }
    }
    for (let i = 0; i < this.PackageDetails.length; i++) {
      if (this.selectedPackage == this.PackageDetails[i].PackageName) {
        this.PackageDetails.splice(i, 1);
        this.packages.splice(i, 1)
        this.showPackageTable = false;
        this.PackageDetailsTableVisible = false;
        this.showPackageFirstScreen = false;
        this.showSavePackageIcon = false;
        this.showDeletePackageIcon = false;

      }
    }

    this.modulecheck = false;

    this.selectallcheck = false;
    this.PackageForm.reset();
    console.log(this.PackageDetails, "PACKAGE DETAILS")
  }


  onSubmitAddModule() {
    console.log('add module & feature ', this.AddModuleForm)
    this.addmodulepopup = false;
    if (this.AddModuleForm.invalid) {
      return;
    } else {
      this.saveFeatureAndModuleAPI(this.AddModuleForm.value.SubModuleName, this.AddModuleForm.value.ModuleName)
    }
  }

  onSubmitAddSubModule($event) {
    this.addsubmodulepopup = false;
    console.log('add module & feature ', this.AddSubModuleForm, this.modules[this.addSubModulein].name)
    if (this.AddSubModuleForm.invalid) {
      return;
    } else {
      this.saveFeatureAndModuleAPI(this.AddSubModuleForm.value.SubModule, this.modules[this.addSubModulein].name)
    }

  }

  openPackageDetails(e: any) {
    console.log("i am in package sellect")
    for (let i = 0; i < this.modules.length; i++) {
      for (let b = 0; b < this.modules[i].data.length; b++) {
        this.modules[i].data[b].checked = false
      }
    }

    this.selecteddefaultpackage = this.defaultpackagedata[this.packages.indexOf(this.selectedPackage)].packageItems
    console.log(this.packages.indexOf(this.selectedPackage), this.selecteddefaultpackage, this.modules, "selected package")
    for (let i = 0; i < this.modules.length; i++) {

      for (let a = 0; a < this.selecteddefaultpackage.length; a++) {
        console.log("selected package")
        for (let b = 0; b < this.modules[i].data.length; b++) {
          console.log("selected package")
          if (this.modules[i].data[b].value == this.selecteddefaultpackage[a].name) {
            console.log("selected package")
            this.modules[i].data[b].checked = true

          }

        }
      }

    }
    this.PackageDetailsTableVisible = true;
    this.AddPackageVisible = true;
    this.showPackageFirstScreen = true;
    this.showSavePackageIcon = true;
    this.showDeletePackageIcon = true;
    this.showPackageTable = false;

    this.PackageName = this.selectedPackage;
    for (let i = 0; i < this.PackageDetails.length; i++) {
      if (this.PackageName == this.PackageDetails[i].PackageName) {
        this.PackagePrice = this.PackageDetails[i].PackagePrice;
      }

    }
    for (let i = 0; i < this.modules.length; i++) {
      for (let j = 0; j < this.modules[i].data.length; j++) {
        for (let k = 0; k < this.PackageDetails.length; k++) {
          for (let l = 0; l < this.PackageDetails[k].PackageFeatures.length; l++) {
            if (e.value == this.PackageDetails[k].PackageName) {
              if (this.modules[i].data[j].value == this.PackageDetails[k].PackageFeatures[l]) {
                this.checkedModules.push(this.modules[i].data[j].value);
              }
            }
          }
        }
      }
    }
    for (let i = 0; i < this.modules.length; i++) {
      for (let j = 0; j < this.modules[i].data.length; j++) {
        for (let m = 0; m < this.checkedModules.length; m++) {

          if (this.modules[i].data[j].value == this.checkedModules[m]) {
            this.modules[i].data[j].checked = true;
          }

        }
      }
    }

    this.checkedModules = []
    console.log(this.packages.indexOf(this.selectedPackage), this.selecteddefaultpackage, this.modules, "selected package")
  }
  onSubmitUpdateOrgSubscription(event): any {
    var count = 0
    this.packageResponseData.forEach(data => {
      console.log("hi", data, this.AddUserForm, data.name === this.AddUserForm.value.packageNames)
      if (data.name === this.AddUserForm.value.packageNames) {
        count = 1
        this.selectedRecord.pId = data.packageId;
      }

    })
    console.log(this.selectedRecord.pId, "bye")
    const requestInfo = {
      orgId: this.selectedRecord.orgId,
      subscriptionId: this.selectedRecord.pId,
      startDate: this.AddUserForm.value.StartDateSelected,
      endDate: this.AddUserForm.value.EndDateSelected,
      emailId: this.selectedRecord.Email,
      status: this.AddUserForm.value.status
    }
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].pId == this.selectedRecord.pId) {

        this.users[i].Email = this.AddUserForm.value.email
        this.users[i].Organization = this.AddUserForm.value.AddUserOrganizationName
        if (this.AddUserForm.value.packageNames != 'Administrator') {
          this.users[i].Package = this.AddUserForm.value.packageNames
        }
        this.users[i].Status = this.AddUserForm.value.status
      }
    }
    console.log(requestInfo, "send updated data")
    this.feAdminService.updateOrgSubscriptionDetails(requestInfo).subscribe(response => {
      console.log('updating the org subscriptions response is ', response);

    });
    this.getOrgSubscriptionListService(this.individulauserbutton)
    console.log(this.AddUserForm, "form")
  }

  onSubmitAddUser() {
    this.AddUserPackageControlVisible = false;
    if (this.AddUserForm.invalid) {
      return;
    }

    this.AddUserFormArray = this.AddUserForm.value;
    const userpackageArray = [
      {
        Email: this.AddUserFormArray['email'],
        Organization: this.AddUserFormArray['AddUserOrganizationName'],
        Package: this.AddUserFormArray['packageNames'],
        StartDate: this.AddUserFormArray['StartDateSelected'],
        EndDate: this.AddUserFormArray['EndDateSelected'],
        Status: this.AddUserFormArray['status']
      }
    ]
    this.users.push(userpackageArray[0])
  }
  addModule() {

    this.addmodulepopup = !this.addmodulepopup;
    this.AddModuleForm.reset();
  }
  addSubModule(e: any) {
    this.addSubModulein = e.target.id;
    this.addsubmodulepopup = !this.addsubmodulepopup;
    this.AddSubModuleForm.reset();
  }
  CheckAllOptions(e: any) {
    if (this.users.every(val => val.checked == true))
      this.users.forEach(val => { val.checked = false });
    else
      this.users.forEach(val => { val.checked = true });
    for (let x = 0; x < this.users.length; x++) {
      this.idarray.push(x);
    }
  }
  checkid(e: any, selectedUserData) {
    const editUserEmaildata = this.users[e.target.id].email
    this.editUserEmail.splice(0, this.editUserEmail.length)
    // this.editUserEmail.push(editUserEmaildata);
    this.editUserEmail = [];
    this.editUserEmail.push(editUserEmaildata);
    console.log('what data getting here', selectedUserData)
    this.emailPopup = selectedUserData.Email;
    this.orgPopup = selectedUserData.Organization;
    this.selectedRecord = selectedUserData;
  }

  StartDateSelected: any;
  EndDateSelected: any;



  showDashboard() {
    this.dashboardVisible = !this.dashboardVisible;
    this.PackageVisible = false;
    this.UserPackageVisible = false;
  }


  showPackageControl() {
    this.PackageVisible = !this.PackageVisible;
    this.dashboardVisible = false;
    this.UserPackageVisible = false;
  }
  features = []
  CheckAllFeatures(e: any) {

    this.packagearray = [];
    if (e.target.checked == true) {

      this.modulecheck = true;

      this.selectallcheck = true;
      for (let i = 0; i < this.modules.length; i++) {
        if (this.modules[i].data.every(val => val.checked == true))
          this.modules[i].data.forEach(val => { val.checked = false });
        else
          this.modules[i].data.forEach(val => { val.checked = true });
        for (let x = 0; x < this.modules[i].data.length; x++) {
          this.geobararray.push(x);
        }
      }
      for (let k = 0; k < this.modules.length; k++) {
        this.packagearray.push(this.modules[this.geobararray[k]])
      }
      this.packagearray = Object.values(this.packagearray)
      for (let i = 0; i < this.packagearray.length; i++) {
        for (let j = 0; j < this.packagearray[i].data.length; j++) {
          const Package = this.packagearray[i].data[j].value

          this.packagedetail.push(Package)
        }
      }
    }
    else {
      this.modulecheck = false;
      this.selectallcheck = false;
      for (let i = 0; i < this.modules.length; i++) {
        if (this.modules[i].data.every(val => val.checked == false))
          this.modules[i].data.forEach(val => { val.checked = true });
        else
          this.modules[i].data.forEach(val => { val.checked = false });
        for (let x = 0; x < this.modules[i].data.length; x++) {
          this.geobararray.push(x);
        }
      }
      this.packagedetail = []
      this.allFeaturesArray = [];
    }
  }

  CheckAllModule(e: any) {


    const y = e.target.id;
    if (this.modules[y].data.every(val => val.checked == true)) {
      this.modules[y].checked = false;
      this.modules[y].data.forEach(val => { val.checked = false });

      this.packagearray = [];
    }
    else {
      this.modules[y].data.forEach(val => { val.checked = true });
      this.modules[y].checked = false;
      for (let i = 0; i < this.modules.length; i++) {
        for (let j = 0; j < this.modules[i].data.length; j++) {
          if (e.target.value == this.modules[i].value) {
            this.packagearray.push(this.modules[i].data[j].value)
          }
        }
      }
    }
    this.packagedetail = this.packagearray;
    this.packagearray = [];
  }
  checkSubModule(e: any) {
    this.packagedetail.push(e.target.value);
    this.packagedetail.sort();
    for (let i = 0; i < this.packagedetail.length; i++) {
      for (let j = i + 1; j < this.packagedetail.length; j++) {
        if (this.packagedetail[i] == this.packagedetail[j]) {
          this.packagedetail.splice(i, 2);
        }
      }
    }
    const d1 = Number(e.target.id)
    this.geobararray.push(d1)
  }

  showUserPackageControl() {
    this.UserPackageVisible = !this.UserPackageVisible;
    this.dashboardVisible = false;
    this.PackageVisible = false;
  }

  showAddUserPackageControl() {
    this.AddUserPackageControlVisible = !this.AddUserPackageControlVisible;
    this.edituserpacakagecontrolvisible = false;
    this.showdeleteuser = false;
  }

  showEditUserPackageControl() {
    this.AddUserForm = this.AddUserformBuilder.group({
      email: [this.selectedRecord.Email, [Validators.required, Validators.email]],
      AddUserOrganizationName: [this.selectedRecord.Organization, Validators.required],
      packageNames: ['', Validators.required],
      status: ['', Validators.required],
      StartDateSelected: ['', Validators.required],
      EndDateSelected: ['', Validators.required]
    });
    this.AddUserPackageControlVisible = !this.AddUserPackageControlVisible;
    var edituse = document.getElementById("AddUserEmail")
    console.log(this.selectedRecord.Email, edituse, 'edituse')
  }

  showdeleteUserPackageControl() {
    this.showdeleteuser = !this.showdeleteuser;
    this.edituserpacakagecontrolvisible = false;
    this.AddUserPackageControlVisible = false;
  }

  removeSelectedRows() {
    var length = this.idarray.length
    for (let x = length - 1; x >= 0; x--) {
      this.users.splice(this.idarray[x], 1);
      this.idarray.splice(x, 1)
    }
    this.showdeleteuser = false;
  }
  openPackageTable() {
    this.PackageDetailsTableVisible = false;
    this.AddPackageVisible = true;
    this.showPackageFirstScreen = true;
    this.showSavePackageIcon = true;
    this.showDeletePackageIcon = true;
    this.showPackageTable = true;
    this.selectedPackage = "Select Package";
  }
  showProfileDropdown() {
    this.visibleProfileDropdown = true; //!this.visibleProfileDropdown;
  }
  onLogout() {
    this.socialAuth.logoutFromFirebase();
    localStorage.clear();
    window.open('http://fuse.earth/', '_self');
  }
}
