<div [ngStyle]="{'display': globalObject.pageType !== 'COVID19' ? 'inherit' : 'none'}">
    <app-compass [showCompass]="showCompassCtrl" [hideCompass]="closeCompassCtrl"
        (compassClosed)="compassClosedEventFun()"></app-compass>

    <ng-template #geoSoleRotationContent>
        <!-- Orientation -->
        Orientation! Click and rotate, Dbl-click to reset
    </ng-template>
    <ng-template #geoSoleToggleImgContent>GeoMocus Configuration</ng-template>
    <ng-template #geoSoleSettingsContent>Settings</ng-template>
    <ng-template #geoSoleSourcesContent>Sources</ng-template>
    <ng-template #geoSoleFrameWorksContent>
        <!-- Frameworks -->
        Frameworks! Click to change coordinate systems
    </ng-template>
    <ng-template #geoSoleMeasuresContent>Measures</ng-template>
    <ng-template #geoSoleDefaultLayersContent>Default Layers</ng-template>
    <ng-template #geoSoleReferencesContent>References</ng-template>
    <ng-template #geoSoleProduceContent>Produce</ng-template>
    <ng-template #geoSoleManageContent>Manage</ng-template>
    <ng-template #geoSoleAnalyzeContent>Analyze</ng-template>
    <ng-template #geoSolePresentContent>Present</ng-template>

    <ng-template #geoSoleLeftOptionsToggle>Geosol Left Options</ng-template>
    <ng-template #geoSoleCenterToggle>Geosol Options Toggle</ng-template>
    <ng-template #geoSoleRightOptionsToggle>Geosol Right Options</ng-template>

    <!-- Unit measure change modal -->
    <ng-template #measureChangeContent let-c="close" let-d="dismiss" style="width: 100px;">
        <div class="modal-header modalHeader">
            <h4 class="modal-title modalTitle" id="modal-basic-title">Units change</h4>
            <button type="button" class="close modalCloseButton" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="modalCloseSpan">&times;</span>
            </button>
        </div>
        <div class="modal-body modalBody">
            <div class="units-change-container">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="select-submit-container">
                        <div class="select-container">
                            <label for="orders">Units</label>
                            <select formControlName="unitCtrl" id="orders">
                                <option *ngFor="let unit of unitsList" [value]="unit.value">
                                    {{unit.view}}
                                </option>
                            </select>
                        </div>
                        <div class="submit-container">
                            <button>Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template #appInfoContent let-c="close" let-d="dismiss">
        <div class="modal-header modalHeader">
            <h4 class="modal-title modalTitle" id="modal-basic-title">App Information</h4>
            <button type="button" class="close modalCloseButton" aria-label="Close"
                (click)="d('Cross click') ; tcAndPrivacyActivePage='tc';">
                <span aria-hidden="true" class="modalCloseSpan">&times;</span>
            </button>
        </div>
        <div class="modal-body modalBody" style="height: 400px;">
            <div class="tc-privacy-head-container">
                <div [ngClass]="{'tc-head':true,'active': tcAndPrivacyActivePage=='tc' }"
                    (click)="tcAndPrivacyActivePage='tc'">
                    Terms & Conditions
                </div>
                <div [ngClass]="{'privacy-head':true,'active': tcAndPrivacyActivePage=='privacy' }"
                    (click)="tcAndPrivacyActivePage='privacy'">
                    Privacy Policy
                </div>
            </div>
            <div class="tc-privacy-body-container">
                <div class="tc-data" *ngIf="tcAndPrivacyActivePage=='tc'">
                    <!-- <div slimScroll [options]="opts" [scrollEvents]="scrollEvents"> -->
                    <div>
                        <p>Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdf Terms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdf
                            Terms and conditions.fdsfsdfsdfTerms and conditions.fdsfsdfsdfTerms and
                            conditions.fdsfsdfsdf

                        </p>
                    </div>
                </div>
                <div class="privacy-data" *ngIf="tcAndPrivacyActivePage=='privacy'">
                    <!-- <div slimScroll [options]="opts" [scrollEvents]="scrollEvents"> -->
                    <div>
                        <p>
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                            Privacy policy.dfdsfd Privacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfdPrivacy
                            policy.dfdsfdPrivacy policy.dfdsfdPrivacy policy.dfdsfd
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>

    <!-- SUB OPTIONS CONTAINER-->
    <div class="geosol-left-sub-options-container" *ngIf="selectedOption=='frameworks'||selectedOption=='observations'">
        <div class="observations-container" *ngIf="selectedOption=='observations'">
            <!-- <p>OBSERVATIONS</p> -->
            <app-geosol-option matTooltipPosition='above' matTooltip="LIQ ZONES" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" cssClass="coreicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" (click)="presetData('LIQ',2)" [ng-disabled]="isDisabled2"
                    [ngStyle]="{'cursor': isDisabled2 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\liq.png" />
            </app-geosol-option>

            <app-geosol-option matTooltipPosition='above' matTooltip="FAULT ZONES" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" cssClass="coreicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" (click)="presetData('FAULT ZONES',3)" [ng-disabled]="isDisabled3"
                    [ngStyle]="{'cursor': isDisabled3 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\fault-zones.png " />
            </app-geosol-option>           
            <app-geosol-option matTooltipPosition='above' matTooltip="HISTORIC SEISMICITY-3.5"
                matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="coreicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" (click)="presetData('HISTORIC SEISMICITY-3.5',4)" [ng-disabled]="isDisabled4"
                    [ngStyle]="{'cursor': isDisabled4 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\his-sem-earth-35.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="HISTORIC SEISMICITY-5.5"
                matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="coreicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" (click)="presetData('HISTORIC SEISMICITY-5.5',5)" [ng-disabled]="isDisabled5"
                    [ngStyle]="{'cursor': isDisabled5 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\his-sem-earth-55.png " />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="EV CHARGING STATIONS"
                matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="generalicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" (click)="presetData('EV stations',6)" [ng-disabled]="isDisabled6"
                    [ngStyle]="{'cursor': isDisabled6 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\evs.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="generalicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(#33CC33)' }" [backgroundColor]="'blue'">
                <img class="icon-img" matTooltipPosition='above' matTooltip="Fires"
                    (click)="presetData('Vegetation_Fires',0)" [ng-disabled]="isDisabled"
                    [ngStyle]="{'cursor': isDisabled ? 'not-allowed' : 'pointer'}"
                    src=" assets/svgs/geosol/observations/fire.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="generalicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" matTooltipPosition='above' matTooltip="WATERSHEDS"
                    (click)="presetData('Watersheds',1)" [ng-disabled]="isDisabled1"
                    [ngStyle]="{'cursor': isDisabled1 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\watersheds.png" />
            </app-geosol-option>

            <app-geosol-option matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="generalicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" matTooltipPosition='above' matTooltip="CAGEOL" (click)="presetData('Cageol',7)"
                    [ng-disabled]="isDisabled7" [ngStyle]="{'cursor': isDisabled7 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\cageol.png" />
            </app-geosol-option>

            <app-geosol-option matTooltipClass="mat-custom-tooltip" [width]="'50'" cssClass="generalicon"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }">
                <img class="icon-img" matTooltipPosition='above' matTooltip="USGSWFS" (click)="presetData('USGSWFS',8)"
                    [ng-disabled]="isDisabled8" [ngStyle]="{'cursor': isDisabled8 ? 'not-allowed' : 'pointer'}"
                    src="assets\svgs\geosol\observations\usgs-wfs.png" />
            </app-geosol-option>

        </div>
        <div class="observations-container" *ngIf="selectedOption=='frameworks'" style="position: relative;">
            <app-geosol-option matTooltipPosition='above' matTooltip="USGS Topo" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" (click)="filterMapTypes('USGSTopo', 'USGS Topo')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 15px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/usgstopo.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Satellite (Mapbox)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('satellite', 'Satellite (Mapbox)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/satellite-mapbox.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Satellite (Bing)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('bingsatellite', 'Satellite (Bing)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/satellite-bing.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Street (Bing)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('bingstreet', 'Street (Bing)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/street-bing.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Street (Google)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('googlestreet', 'Street (Google)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/street-google.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Satellite (Google)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('googlesatellite', 'Satellite (Google)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/satellite-google.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Toner (Stamen)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'" (click)="filterMapTypes('toner', 'Toner (Stamen)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/toner-stamen.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Terrain (Stamen)"
                matTooltipClass="mat-custom-tooltip" [width]="'50'"
                (click)="filterMapTypes('terrain', 'Terrain (Stamen)')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 11px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/terrain-stamen.png" />
            </app-geosol-option>
            <app-geosol-option matTooltipPosition='above' matTooltip="Open Street" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" (click)="filterMapTypes('openstreet', 'Open Street')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 15px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/open-street.png" />
            </app-geosol-option>
            <!-- <app-geosol-option matTooltipPosition='above' matTooltip="USGS Topo" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" (click)="filterMapTypes('USGSTopo', 'USGS Topo')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 15px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/usgstopo.png" />
            </app-geosol-option> -->
            <app-geosol-option matTooltipPosition='above' matTooltip="US Topo" matTooltipClass="mat-custom-tooltip"
                [width]="'50'" (click)="filterMapTypes('USTopo', 'US Topo')"
                [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                style="margin-right: 15px;">
                <img class="icon-img" src="assets/svgs/geosol/geosole-framework/map-icons/ustopo.png" />
            </app-geosol-option>
            <div style="position:fixed; transform:translateX(300px)">
                <div
                    style="width: 30px; height: 40px; top: -14px; position: relative; margin: 3px 17px; border-radius: 50%; background: transparent; cursor: pointer;">
                    <div style="border-radius: 50%; height: 90%; padding: 2px;">
                        <div style="border-radius: 50%; height: 100%;">
                            <img (click)="showgeosolframeworkcoordinatesys ? openFrameworkForm(): showalert()"
                                style="width: 100%; height: 40%;"
                                src="assets/svgs/geosol/geosole-framework/drop-up-icon.svg"
                                [ngStyle]="{ 'cursor' : showgeosolframeworkcoordinatesys ? '' : 'not-allowed' }" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="geosol-left-sub-options-container" style="
  max-width: 232px;
  right: 0;
  bottom: 175px;
  left: calc(50% - 300px);
  z-index: 1;
" *ngIf="showFrameworkForm">
        <div class="frameworks-container">
            <form [formGroup]="frameworkForm">
                <div [ngClass]="{
        'framework-option': true,
        'selected-option':
          frameworkForm.get('framework').value == 'rectangular'
      }">
                    <input [disabled]="frameworkForm.get('framework').value === 'angular'" type="radio" id="rectangular"
                        formControlName="framework" name="framework" value="rectangular"
                        (change)="radioButtonState = !radioButtonState" (change)="
        namechangeEvent(
          $event.target.id
        )" />
                    <label for="rectangular">
                        <div class="framework-option-data-container">
                            <div class="framework-option-name">
                                <p class="primary-name">Rectangular</p>
                                <p class="secondary-name">(Easting & Northing)</p>
                            </div>
                            <div class="recimgdropdown" style="
              border: 1px solid;
              position: relative;
              width: 96px;
              height: 17px;
              top: 7px;
            ">
                                <div class="epsgname">
                                    {{ rectangularepsg }}
                                </div>

                                <div style="
                top: 1px;
                left: 79px;
                width: 12px;
                height: 12px;
                position: absolute;
              " class="rectangulardropdown" (click)="openrectangulardropdown()" [ngClass]="{
                rectangulardropdownactive: !radioButtonState,
                rectangulardropdownnotActive: radioButtonState,
                'rectangulardropdown-checked':
                  frameworkForm.get('framework').value == 'angular',
                'rectangulardropdown-unchecked':
                  frameworkForm.get('framework').value != 'angular'
              }">
                                    <div>
                                        <select id="rectangular" size="6" class="rectdropdown"
                                            *ngIf="showrectangulardropdown" (change)="
                    projectionChangeEvent(
                      $event.target.value,
                      $event.target.id
                    )
                  ">
                                            <option value="3857">EPSG:3857</option>
                                            <option value="2100">EPSG:2100</option>
                                            <!-- <option value="2163">EPSG:2163</option> -->
                                            <!-- <option value="27700">EPSG:27700</option> -->
                                            <option value="23032">EPSG:23032</option>
                                            <option value="21781">EPSG:21781</option>
                                            <option value="3413">EPSG:3413</option>
                                            <option value="5479">EPSG:5479</option>
                                            <option value="54009">ESRI:54009</option>
                                            <option value="54009">ESRI:54009</option>
                                            <option value="2225">EPSG:2225 CA SPZ1</option>
                                            <option value="2226">EPSG:2226 CA SPZ2</option>
                                            <option value="2227">EPSG:2227 CA SPZ3</option>
                                            <option value="2228">EPSG:2228 CA SPZ4</option>
                                            <option value="2229">EPSG:2229 CA SPZ5</option>
                                            <option value="2230">EPSG:2230 CA SPZ6</option>
                                            <option value="24378">EPSG:24378 Kalian Z1</option>
                                            <option value="24379">EPSG:24379 Kalian Z2a</option>
                                            <option value="24380">EPSG:24380 Kalian Z2b</option>
                                            <option value="24381">EPSG:24381 Kalian Z3a</option>
                                            <option value="24382">EPSG:24382 Kalian Z3b</option>
                                            <option value="24383">EPSG:24383 Kalian Z4</option>
                                            <option value="32601">EPSG:32601 UTM Z1N</option>
                                            <option value="32602">EPSG:32602 UTM Z2N</option>
                                            <option value="32603">EPSG:32603 UTM Z3N</option>
                                            <option value="32604">EPSG:32604 UTM Z4N</option>
                                            <option value="32605">EPSG:32605 UTM Z5N</option>
                                            <option value="32606">EPSG:32606 UTM Z6N</option>
                                            <option value="32607">EPSG:32607 UTM Z7N</option>
                                            <option value="32608">EPSG:32608 UTM Z8N</option>
                                            <option value="32609">EPSG:32609 UTM Z9N</option>
                                            <option value="32610">EPSG:32610 UTM Z10N</option>
                                            <option value="32611">EPSG:32610: UTM Z11N</option>
                                            <option value="32612">EPSG:32612 UTM Z12N</option>
                                            <option value="32613">EPSG:32613 UTM Z13N</option>
                                            <option value="32614">EPSG:32614 UTM Z14N</option>
                                            <option value="32615">EPSG:32615 UTM Z15N</option>
                                            <option value="32616">EPSG:32616 UTM Z16N</option>
                                            <option value="32617">EPSG:32617 UTM Z17N</option>
                                            <option value="32618">EPSG:32618 UTM Z18N</option>
                                            <option value="32619">EPSG:32619 UTM Z19N</option>
                                            <option value="32620">EPSG:32620 UTM Z20N</option>
                                            <option value="32621">EPSG:32621 UTM Z21N</option>
                                            <option value="32622">EPSG:32622 UTM Z22N</option>
                                            <option value="32623">EPSG:32623 UTM Z23N</option>
                                            <option value="32624">EPSG:32624 UTM Z24N</option>
                                            <option value="32625">EPSG:32625 UTM Z25N</option>
                                            <option value="32626">EPSG:32626 UTM Z26N</option>
                                            <option value="32627">EPSG:32627 UTM Z27N</option>
                                            <option value="32628">EPSG:32628 UTM Z28N</option>
                                            <option value="32629">EPSG:32629 UTM Z29N</option>
                                            <option value="32630">EPSG:32630 UTM Z30N</option>
                                            <option value="32631">EPSG:32631 UTM Z31N</option>
                                            <option value="32632">EPSG:32632 UTM Z32N</option>
                                            <option value="32633">EPSG:32633 UTM Z33N</option>
                                            <option value="32634">EPSG:32634 UTM Z34N</option>
                                            <option value="32635">EPSG:32635 UTM Z35N</option>
                                            <option value="32636">EPSG:32636 UTM Z36N</option>
                                            <option value="32637">EPSG:32637 UTM Z37N</option>
                                            <option value="32638">EPSG:32638 UTM Z38N</option>
                                            <option value="32639">EPSG:32639 UTM Z39N</option>
                                            <option value="32640">EPSG:32640 UTM Z40N</option>
                                            <option value="32641">EPSG:32641 UTM Z41N</option>
                                            <option value="32642">EPSG:32642 UTM Z42N</option>
                                            <option value="32643">EPSG:32643 UTM Z43N</option>
                                            <option value="32644">EPSG:32644 UTM Z44N</option>
                                            <option value="32645">EPSG:32645 UTM Z45N</option>
                                            <option value="32646">EPSG:32646 UTM Z46N</option>
                                            <option value="32647">EPSG:32647 UTM Z47N</option>
                                            <option value="32648">EPSG:32648 UTM Z48N</option>
                                            <option value="32649">EPSG:32649 UTM Z49N</option>
                                            <option value="32650">EPSG:32650 UTM Z50N</option>
                                            <option value="32651">EPSG:32651 UTM Z51N</option>
                                            <option value="32652">EPSG:32652 UTM Z52N</option>
                                            <option value="32653">EPSG:32653 UTM Z53N</option>
                                            <option value="32654">EPSG:32654 UTM Z54N</option>
                                            <option value="32655">EPSG:32655 UTM Z55N</option>
                                            <option value="32656">EPSG:32656 UTM Z56N</option>
                                            <option value="32657">EPSG:32657 UTM Z57N</option>
                                            <option value="32658">EPSG:32658 UTM Z58N</option>
                                            <option value="32659">EPSG:32659 UTM Z59N</option>
                                            <option value="32660">EPSG:32660 UTM Z60N</option>
                                            <option value="32701">EPSG:32701 UTM Z1S</option>
                                            <option value="32702">EPSG:32702 UTM Z2S</option>
                                            <option value="32703">EPSG:32703 UTM Z3S</option>
                                            <option value="32704">EPSG:32704 UTM Z4S</option>
                                            <option value="32705">EPSG:32705 UTM Z5S</option>
                                            <option value="32706">EPSG:32706 UTM Z6S</option>
                                            <option value="32707">EPSG:32707 UTM Z7S</option>
                                            <option value="32708">EPSG:32708 UTM Z8S</option>
                                            <option value="32709">EPSG:32709 UTM Z9S</option>
                                            <option value="32710">EPSG:32709 UTM Z10S</option>
                                            <option value="32711">EPSG:32711 UTM Z11S</option>
                                            <option value="32712">EPSG:32712 UTM Z12S</option>
                                            <option value="32713">EPSG:32713 UTM Z13S</option>
                                            <option value="32714">EPSG:32714 UTM Z14S</option>
                                            <option value="32715">EPSG:32715 UTM Z15S</option>
                                            <option value="32716">EPSG:32716 UTM Z16S</option>
                                            <option value="32717">EPSG:32717 UTM Z17S</option>
                                            <option value="32718">EPSG:32718 UTM Z18S</option>
                                            <option value="32719">EPSG:32719 UTM Z19S</option>
                                            <option value="32720">EPSG:32720 UTM Z20S</option>
                                            <option value="32721">EPSG:32721 UTM Z21S</option>
                                            <option value="32722">EPSG:32722 UTM Z22S</option>
                                            <option value="32723">EPSG:32723 UTM Z23S</option>
                                            <option value="32724">EPSG:32724 UTM Z24S</option>
                                            <option value="32725">EPSG:32725 UTM Z25S</option>
                                            <option value="32726">EPSG:32726 UTM Z26S</option>
                                            <option value="32727">EPSG:32727 UTM Z27S</option>
                                            <option value="32728">EPSG:32728 UTM Z28S</option>
                                            <option value="32729">EPSG:32729 UTM Z29S</option>
                                            <option value="32730">EPSG:32730 UTM Z30S</option>
                                            <option value="32731">EPSG:32731 UTM Z31S</option>
                                            <option value="32732">EPSG:32732 UTM Z32S</option>
                                            <option value="32732">EPSG:32732 UTM Z33S</option>
                                            <option value="32734">EPSG:32734 UTM Z34S</option>
                                            <option value="32735">EPSG:32735 UTM Z35S</option>
                                            <option value="32736">EPSG:32736 UTM Z36S</option>
                                            <option value="32737">EPSG:32737 UTM Z37S</option>
                                            <option value="32738">EPSG:32738 UTM Z38S</option>
                                            <option value="32739">EPSG:32739 UTM Z39S</option>
                                            <option value="32740">EPSG:32740 UTM Z40S</option>
                                            <option value="32741">EPSG:32741 UTM Z41S</option>
                                            <option value="32742">EPSG:32742 UTM Z42S</option>
                                            <option value="32743">EPSG:32743 UTM Z43S</option>
                                            <option value="32744">EPSG:32744 UTM Z44S</option>
                                            <option value="32745">EPSG:32745 UTM Z45S</option>
                                            <option value="32746">EPSG:32746 UTM Z46S</option>
                                            <option value="32747">EPSG:32747 UTM Z47S</option>
                                            <option value="32748">EPSG:32748 UTM Z48S</option>
                                            <option value="32749">EPSG:32749 UTM Z49S</option>
                                            <option value="32750">EPSG:32750 UTM Z50S</option>
                                            <option value="32751">EPSG:32751 UTM Z51S</option>
                                            <option value="32752">EPSG:32752 UTM Z52S</option>
                                            <option value="32753">EPSG:32753 UTM Z53S</option>
                                            <option value="32754">EPSG:32754 UTM Z54S</option>
                                            <option value="32755">EPSG:32755 UTM Z55S</option>
                                            <option value="32756">EPSG:32756 UTM Z56S</option>
                                            <option value="32757">EPSG:32757 UTM Z57S</option>
                                            <option value="32758">EPSG:32758 UTM Z58S</option>
                                            <option value="32759">EPSG:32759 UTM Z59S</option>
                                            <option value="32760">EPSG:32760 UTM Z60S</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div [ngClass]="{
        'framework-option': true,
        'selected-option': frameworkForm.get('framework').value == 'angular'
      }">
                    <input [disabled]="frameworkForm.get('framework').value === 'rectangular'" type="radio" id="angular"
                        formControlName="framework" name="framework" value="angular"
                        (change)="radioButtonState = !radioButtonState" (change)="
        namechangeEvent(
          $event.target.id
        )" />
                    <label for="angular">
                        <div class="framework-option-data-container">
                            <div class="framework-option-name">
                                <p class="primary-name">Angular</p>
                                <p class="secondary-name">(Longitude & Latitude)</p>
                            </div>
                            <div class="angimgdropdown" style="
              border: 1px solid;
              width: 96px;
              height: 17px;
              top: 7px;
              position: relative;
            ">
                                <div class="epsgname">
                                    {{ angularepsg }}
                                </div>
                                <div style="
                top: 1px;
                left: 79px;
                width: 12px;
                height: 12px;
                position: absolute;
              " class="angulardropdown" [disabled]="
              frameworkForm.get('framework').value != 'Angular'
            " (click)="openangulardropdown()" [ngClass]="{
                angulardropdownactive: radioButtonState,
                angulardropdownnotActive: !radioButtonState,
                'angulardropdown-checked':
                  frameworkForm.get('framework').value == 'angular',
                'angulardropdown-unchecked':
                  frameworkForm.get('framework').value != 'angular'
              }">
                                    <div>
                                        <select id="angular" size="2" class="angdropdown" *ngIf="showangulardropdown"
                                            (change)="
                    projectionChangeEvent(
                      $event.target.value,
                      $event.target.id
                    )
                  ">
                                            <option value="4326">EPSG:4326</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </form>
        </div>
    </div>

    <app-notification-bar [emitId]="wholeID"></app-notification-bar>

    <!-- Geosol all bottom options -->
    <div class="geosol-options-container">
        <!-- Geosol left options container -->
        <div class="geosol-left-options-container" [ngStyle]="{'width': leftWingContainerWidth}">
            <div class="geosol-left-options" [ngStyle]="{ width: innerWidth > 930 ? ( isLeftWingExpanded ? ( (iconWidth*5)+45 )+'px' : '0px' ) : (iconWidth+10)+'px',
                                                    height: innerWidth > 930 ? 'inherit' : ( isLeftWingExpanded ? ( (iconWidth*5)+20 )+'px' : '0px' ),
                                                    padding: !isLeftWingExpanded ? '0px' :'' }">

                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Frameworks! Click to change coordinate systems" matTooltipClass="mat-custom-tooltip"
                    [width]="iconWidth" [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #006dad 65%)' }"
                    cssClass="option-c" [isActive]="selectedOption=='frameworks'"
                    (click)="showgeosolframeworkmap ? selectGeosolOption('frameworks'): showalert()"
                    [ngStyle]="{ 'cursor' : showgeosolframeworkmap ? '' : 'not-allowed' }">
                    <!-- <img class="icon-img" src="assets/svgs/geosol/top-right-icon-3.svg"/> -->
                    <img class="icon-img" src="assets/svgs/new-logos/framework_svg.svg" />
                </app-geosol-option>
                <!-- <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}" matTooltip="Concepts"
                (click)="openMeasureUnitsChangePopup($event, measureChangeContent); selectGeosolOption('concepts')" -->
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Concepts! Click to learn more about fuse™" matTooltipClass="mat-custom-tooltip"
                    (click)="showBlog()" [isActive]="selectedOption=='concepts'" cssClass="option-d" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #39943c 65%)' }">
                    <!-- <img class="icon-img" src="assets/svgs/geosol/top-right-icon-4.svg"/> -->
                    <img class="icon-img" src="assets/svgs/new-logos/concept_svg.svg" />
                    <!-- <img class="icon-img" src="assets/svgs/new-logos/concept_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolconcepts ? '' : 'not-allowed' }" /> -->
                </app-geosol-option>
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Observations! Click to open precompiled data services"
                    matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #f48e38 65%)' }"
                    [isActive]="selectedOption=='observations'" cssClass="option-s"
                    (click)="showgeosolobservations ? selectGeosolOption('observations'): showalert()">
                    <img class="icon-img" src="assets/svgs/new-logos/observation.svg"
                        [ngStyle]="{ 'cursor' : showgeosolobservations ? '' : 'not-allowed' }" />
                </app-geosol-option>
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Applications! Click to change the application mode" matTooltipClass="mat-custom-tooltip"
                    [width]="iconWidth" [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #5f6066 65%)' }"
                    [isActive]="selectedOption=='applications'" cssClass="option-f"
                    (click)="showgeosolapplications ? selectGeosolOption('applications'): showalert()">
                    <img class="icon-img" src="assets/svgs/new-logos/application.svg"
                        [ngStyle]="{ 'cursor' : showgeosolawarness ? '' : 'not-allowed' }" />
                </app-geosol-option>

                <!-- <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}" matTooltip="Awareness"
                    (click)="showAppInfo($event, appInfoContent); selectGeosolOption('awareness')" 
                    [isActive]="selectedOption=='awareness'"
                    cssClass="awareness"
                    [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #6b2f87 65%)' }">
                <img class="icon-img" src="assets/svgs/geosol/awareness.svg"/>
            </app-geosol-option> -->
                <!-- <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Awareness! Click to browse public or shared data" matTooltipClass="mat-custom-tooltip"
                    (click)="showgeosolapplications ? selectGeosolOption('awareness'): showalert()"
                    [isActive]="selectedOption=='awareness'" cssClass="option-i" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #6b2f87 65%)' }">
                    <img class="icon-img" src="assets/svgs/new-logos/awareness.svg"
                        [ngStyle]="{ 'cursor' : showgeosolapplications ? '' : 'not-allowed' }" />
                </app-geosol-option> -->
                <app-geosol-option
                    matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'right'}}"
                    matTooltip="Assistance! Click to browse public or shared data"
                    matTooltipClass="mat-custom-tooltip"
                    (click)="showgeosolapplications ? openFormModal() : showalert()"
                    [isActive]="selectedOption=='awareness'"
                    cssClass="option-i"
                    [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #6b2f87 65%)' }">
                    <img class="icon-img" src="assets/svgs/new-logos/awareness.svg"
                        [ngStyle]="{ 'cursor' : showgeosolapplications ? '' : 'not-allowed' }" />
                </app-geosol-option>
                

                <ng-template #formModal >
                    <div class="custom-form-modal">
                        <div class="modal-header custom-header">
                            <h5 class="modal-title">Assistance</h5>
                            <button
                                type="button"
                                class="close close-adjustment"
                                (click)="closeDialog()"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body no-padding">
                            <iframe
                                src="https://docs.google.com/forms/d/e/1FAIpQLSd0owcGfKjL_8D6Uulzh8EP0QTXGJR_DIoTSvV-c4apCqP7Xw/viewform?embedded=true"
                                width="100%"
                                height="100%"
                                frameborder="0"
                                style="border: none; display: block;">
                                Loading…
                            </iframe>
                        </div>
                    </div>
                </ng-template>
            </div>
            <!-- Custom Tooltip -->
            <div class="custom-tooltip-container" *ngIf="showTooltip && isLeftWingExpanded">
                <div class="msg-container">
                    <p class="msg">Click here to learn more about fuse! </p>
                    <span class="close-icon" (click)="showTooltip=false;">&#10006;</span>
                </div>
                <div class="tooltip-arrow"></div>
            </div>
        </div>

        <!-- Geosol Orientation rotation container -->
        <div class="display-units-scale-container">
            <div class="units-container">
                <form [formGroup]="form">
                    <select formControlName="unitCtrl" id="orders">
                        <option *ngFor="let unit of unitsList" [value]="unit.value">
                            {{unit.view}}
                        </option>
                    </select>
                </form>
            </div>
            <div class="scale-container" [ngStyle]="{'width': scaleLineWidth+'px'}">
                <!-- <div class="scale-container"> -->
                <span>{{mapProjectionUnits}}</span>
            </div>
        </div>
        <div [ngClass]="{'geosol-orientation-globe-container-outer':true, 'active': orientationActive}">
            <div id="rotationImgID" (dblclick)="setDefaultPosition($event)" class="geosol-orientation-globe-container"
                matTooltip="Orientation! Click and rotate, Dbl-click to reset" matTooltipClass="mat-custom-tooltip"
                matTooltipPosition="above">
                <span class="north">N</span>
                <!-- <span class="east">E</span> -->

                <!-- <span class="west">W</span> -->
            </div>
            <span class="rotation-angle-container">
                <input matTooltip="Orientation! Type in an angle of rotation" matTooltipClass="mat-custom-tooltip"
                    matTooltipPosition="above" [formControl]='rotationAngle' placeholder="Angle">
            </span>
        </div>

        <!-- Geosol center toggle button container -->
        <div class="geosol-options-controller-container">
            <!-- Toggle buttons -->
            <div class="controller-container">
                <div class="controller-btn-card-container">
                    <div class="controller-btn-inner-left-container" matTooltip="Geosol Left Options"
                        matTooltipClass="mat-custom-tooltip" matTooltipPosition="left" (click)="toggleOptions('left')">
                    </div>
                    <div class="controller-btn-inner-right-container" matTooltip="Geosol Right Options"
                        matTooltipClass="mat-custom-tooltip" matTooltipPosition="right"
                        (click)="toggleOptions('right')">
                    </div>
                </div>
                <!-- <div class="controller-btn-img-ouline-container" matTooltip="Geosol Options Toggle" matTooltipClass="mat-custom-tooltip" matTooltipPosition="above" (click)="toggleOptions('all')">
                <div class="controller-btn-img-bg">
                  <img class="icon-img" src="assets/svgs/geosol/logo.svg"/>
                </div>
            </div> -->
                <div class="geohub-outline-container">
                    <div class="geohub-options-container">
                        <div class="geohub-zoomin-container">
                            <div class="previous-extent" (click) = "previousExtent()" matTooltip="Goto Previous Extent"
                                matTooltipClass="mat-custom-tooltip">
                                <img src="assets/svgs/geosol/backward_arrow_white.svg">
                            </div>
                            <div class="zoomin" (click)="zoomIn()" matTooltip="Zoom In"
                                matTooltipClass="mat-custom-tooltip">
                                <img src="assets/svgs/geosol/plus_white.svg">
                            </div>
                        </div>
                        <div class="geohub-zoomout-container" matTooltip="Zoom Out"
                            matTooltipClass="mat-custom-tooltip">
                            <div class="zoomout" (click)="zoomOut()">
                                <img src="assets/svgs/geosol/minus_white.svg">
                            </div>
                            <div class="next-extent" (click) = "nextExtent()" matTooltip="Goto Next Extent" matTooltipClass="mat-custom-tooltip">
                                <img src="assets/svgs/geosol/forward_arrow_white.svg">
                            </div>
                        </div>
                    </div>

                    <!-- GLOBE CONTAINER-->
                    <div class="globe-compass-ouline-container"
                        [ngStyle]="{ 'cursor' : showgeohubcompass ? '' : 'not-allowed' }">
                        <div class="globe-btn-img-bg">
                            <img class="compass" (click)="showCompass()"
                                [ngStyle]="{'background': compassOpenState ? '#4d4d4d' : ''}"
                                src="assets/svgs/geosol/compass_rect.svg"
                                matTooltip="Compass! True North with Magnetic Declination"
                                matTooltipClass="mat-custom-tooltip" />
                            <img class="globe" (click)="displayFullMap()" src="assets/svgs/geosol/globe.svg"
                                matTooltip="Display Full Map" matTooltipClass="mat-custom-tooltip" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Map Projection units, ZoomIn, Zoomout -->
            <!-- <span class="geo-units">{{mapProjectionUnits}}</span> -->
            <!-- <i class="material-icons geosol-zoomin-control" [style.left.px]="25+scaleLineWidth" (click)="zoomIn()"> add </i> -->
            <!-- <i class="material-icons geosol-zoomin-control" (click)="zoomIn()"> add </i> -->
            <!-- <i class="material-icons geosol-zoomout-control" (click)="zoomOut()"> remove </i> -->
            <!-- <select class='map-types' (change)="filterMapTypes($event.target.value)">
            <option value="satellite">Satellite</option>
            <option value="terrain" selected>Terrain</option>
            <option value="openstreet">Open Street</option>
        </select> -->
            <!-- <select class='projection-types' (change)="projectionChangeEvent($event.target.value)">
            <option value="4326" selected>EPSG:4326</option>
            *** <option value="3857">EPSG:3857</option> ***
            <option value="2100">EPSG:2100</option>
            <option value="27700">EPSG:27700</option>
            <option value="23032">EPSG:23032</option>
            <option value="5479">EPSG:5479</option>
            <option value="21781">EPSG:21781</option>
            <option value="3413">EPSG:3413</option>
            <option value="2163">EPSG:2163</option>
            <option value="54009">ESRI:54009</option>
            <option value="2229">EPSG:2229</option>
        </select> -->
        </div>

        <!-- Geosol right options container -->
        <div class="geosol-right-options-container" [ngStyle]="{'width': rightWingContainerWidth}">
            <div class="geosol-right-options" [ngStyle]="{ width: innerWidth > 930 ? ( isRightWingExpanded ? ( (iconWidth*5)+45 )+'px' : '0px' ) : (iconWidth+10)+'px',
                                                        height: innerWidth > 930 ? 'inherit' : ( isRightWingExpanded ? ( (iconWidth*5)+45 )+'px' : '0px' ),
                                                        padding: !isRightWingExpanded ? '0px' :'' }">


                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'left'}}"
                    matTooltip="Presentation" matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #672b84 65%)' }"
                    [isActive]="selectedOption=='presentation'" cssClass="option-i"
                    (click)="showgeosolpresentation1 ? selectGeosolOption('presentation') : showalert()">
                    <img class="icon-img" src="assets/svgs/new-logos/presentation_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolpresentation1 ? '' : 'not-allowed' }" />
                </app-geosol-option>
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'left'}}" matTooltip="Analysis"
                    matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #595b60 65%)' }"
                    [isActive]="selectedOption=='analysis'" cssClass="option-f"
                    (click)="showgeosolanalysis ? selectGeosolOption('analysis') : showalert()">
                    <img class="icon-img" src="assets/svgs/new-logos/analysis_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolanalysis ? '' : 'not-allowed' }" />
                </app-geosol-option>
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'left'}}" matTooltip="Management"
                    matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #f08534 65%)' }"
                    [isActive]="selectedOption=='management'" cssClass="option-s"
                    (click)="showgeosolmanagement ? selectGeosolOption('management'): showalert()">
                    <img class="icon-img" src="assets/svgs/new-logos/management_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolmanagement ? '' : 'not-allowed' }" />
                </app-geosol-option>

                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'left'}}" matTooltip="Production"
                    matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #3a983e 65%)' }"
                    [isActive]="selectedOption=='production'" cssClass="option-d"
                    (click)="showgeosolproduction ? selectGeosolOption('production'): showalert() ">
                    <img class="icon-img" src="assets/svgs/new-logos/production_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolproduction ? '' : 'not-allowed' }" />
                </app-geosol-option>
                <app-geosol-option matTooltipPosition="{{ innerWidth > 930 ? 'above' : 'left'}}"
                    matTooltip="Georeferencing" matTooltipClass="mat-custom-tooltip" [width]="iconWidth"
                    [backgroundStyle]="{ gradientBorder:'radial-gradient(white 35%, #0068a6 65%)' }"
                    [isActive]="selectedOption=='referencing'" cssClass="option-c"
                    (click)="showgeosolgeoreferencing ? selectGeosolOption('referencing'): showalert() ">
                    <img class="icon-img" src="assets/svgs/new-logos/referencing_svg.svg"
                        [ngStyle]="{ 'cursor' : showgeosolgeoreferencing ? '' : 'not-allowed' }" />
                </app-geosol-option>
            </div>
        </div>

    </div>
    <app-print-tool *ngIf="selectedOption=='presentation'"></app-print-tool>
</div>
